export const moveItem = <T,>(items: T[], currentIdx: number, direction: 1 | -1) => {
  if(!items || items.length === 0 || currentIdx < 0) {
    return items;
  } else if (items.length === 1) {
    return [items[0]];
  }

  let newIdx = currentIdx + direction;
  if(newIdx < 0) {
    newIdx = items.length - 1;
  } else if (newIdx >= items.length) {
    newIdx = 0;
  }

  const copy = [...items];
  copy[currentIdx] = items[newIdx];
  copy[newIdx] = items[currentIdx];
  return copy;
}

export const updateItem = <T,>(items: T[], idx: number, changes: Partial<T>) => items.map((item, i) => i === idx ? { ...item, ...changes } : item);

export const removeItem = <T,>(items: T[], idx: number) => items.filter((_,i) => i !== idx);
