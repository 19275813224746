import SwissConfApi from "../../api/ApiHelper";
import { useEditData } from "../../common/useEditData";
import { useEditEmailTemplate } from "../Emails/useEditEmailTemplate";
import { useEventContext } from "../EventContext";
import { useEditDates } from "./components/useEditDates";
import { useEditRooms } from "./components/useEditRooms";
import { useEditSponsors } from "./components/useEditSponsors";
import { useScheduledMeetings } from "./components/useScheduledMeetings";
import { MeetingsBookingConfig } from "./types";

const DefaultConfig: MeetingsBookingConfig = {
  event_id: -1,
  rooms: [],
  sponsors: [],
  dates_available: [],
  meeting_slot_size: 30,
  slack_time: 15,
  timezone: "UTC",
}

const getBookingsConfig = (event_id: number) => {
  return SwissConfApi.request(`/events/${event_id}/meetings-booking/config`, undefined, "get", true)
    .then(x => x)
    .catch(error => {
      if(error?.response?.status === 404) {
        return SwissConfApi.request(`/events/${event_id}/meetings-booking/config`, DefaultConfig, "post");
      } else {
        throw error;
      }
    })
}

const saveBookingsConfig = (event_id: number, changes: Partial<MeetingsBookingConfig>) => {
  const changesReal = { ...changes };
  if(changesReal.dates_available) {
    changesReal.dates_available = changesReal.dates_available.sort((a,b) => a.date < b.date ? -1 : 1);
  }
  return SwissConfApi.request(`/events/${event_id}/meetings-booking/config`, changesReal, "patch")
}

export const useEditMeetingBookingConfig = () => {
  const {currentEvent} = useEventContext();
  const data = useEditData<MeetingsBookingConfig, { event_id: number }>({
    dflt: { ...DefaultConfig, event_id: -1, },
    keys: { event_id: currentEvent?.id || -1 },
    noLoad: !currentEvent,
    load: ({ event_id }) => getBookingsConfig(event_id),
    saveChanges: ({ event_id }, changes) => saveBookingsConfig(event_id, changes),
  });

  const dates = useEditDates({ dates: data.data.dates_available || [], update: v => data.update({ dates_available: v })});
  const rooms = useEditRooms({ rooms: data.data.rooms || [], update: v => data.update({ rooms: v })});
  const sponsors = useEditSponsors({ sponsors: data.data.sponsors, update: v => data.update({ sponsors: v })});

  const meetings = useScheduledMeetings(data.data.event_id);

  const confirmationEmail = useEditEmailTemplate(data.data.confirmation_template);
  const cancellationEmail = useEditEmailTemplate(data.data.cancellation_template);
  const reminderEmail = useEditEmailTemplate(data.data.reminder_template);

  return {
    ...data,

    dates,
    rooms,
    sponsors,

    confirmationEmail,
    cancellationEmail,
    reminderEmail,

    meetings,
  }
}