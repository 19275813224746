import SwissConfApi from "../../api/ApiHelper";
import { ContactForm } from "../../api/types";
import { useEventContext } from "../EventContext";
import { ListData, useLoadedList } from "../Pages/useLoadedList";

export interface ContactFormsListData extends ListData<ContactForm> {
  create: (form: { name: string }) => void;
}

export const useContactFormsList = () => {
  const { currentEvent } = useEventContext();
  const data = useLoadedList<ContactForm, number>({
    keys: currentEvent?.id || -1,
    noLoad: !currentEvent,
    load: eventId => SwissConfApi.request(`/events/${eventId}/contact-forms`),
  });

  const create = (form_name: string) => {
    if(!currentEvent) {
      throw new Error("no event");
    }

    const form = {
      form_name,
      fields: [
        { field: "email", label: "Email", required: true, field_type: "email" },
        { field: "firstname", label: "First name", required: true, field_type: "text" },
        { field: "lastname", label: "Last name", required: true, field_type: "text" },
        { field: "message", label: "Message", required: true, field_type: "textlong" },
      ],
    }

    SwissConfApi.request(`/events/${currentEvent.id}/contact-forms`, form, "post")
      .then(() => data.reload());
  }

  return {
    ...data,
    create,
  };
}