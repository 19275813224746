import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FreeSpace } from "../../common/Forms";
import { SearchField } from "../../common/SearchField";
import { MediaLibraryItems } from "./MediaLibraryItems";
import { FileInfo, MediaLibraryData } from "./useMediaLibraryData";

interface Props {
  isOpen: boolean;
  close: () => void;
  data: MediaLibraryData;
  onItemSelect?: (fi: FileInfo) => void;
}

export const MediaLibraryDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onClose={props.close} maxWidth="md" fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", flexFlow: "row"}}>
          Media library
          <FreeSpace />
          <SearchField value={props.data.filter} setValue={props.data.setFilter} autoFocus />
        </div>
      </DialogTitle>

      <DialogContent>
        {props.data.isLoading && props.data.items.length === 0 && <CircularProgress />}
        <MediaLibraryItems
          data={props.data}
          onItemClick={props.onItemSelect}
          disableActions
          disableUpload
          />
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={props.close}>cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
