import { ReactNode } from "react";
import styled from "@emotion/styled";
import { DiamondOutlined } from "@mui/icons-material";

interface Props {
  children?: ReactNode;
  icon?: ReactNode;
  onClick?: () => void;  
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;

  background: #eeeeee80;
`;

export const EmptyState = (props: Props) => {
  return (
    <Wrapper onClick={props.onClick}>
      {props.icon || <DiamondOutlined />}
      {props.children}
    </Wrapper>
  );
}
