import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useContactFormsList } from '../../contactforms/useContactFormsList';
import { useLabelsEditors } from './Labels';


export const ContactFormComponent = (props: PageComponentProps) => {
  const { component, containerIdx, componentIdx, dataRaw: data } = props;

  const contactForms = useContactFormsList();

  const { labelControl } = useLabelsEditors(props);

  return (
    <>
      <FormGrid>
        <FormControl>
          <InputLabel id={`form_name_${containerIdx}_${componentIdx}`}>Form name</InputLabel>
          <Select
            key="form_name"
            value={component.properties?.form_name || ""}
            onChange={e => data.updateComponentProperties(containerIdx, componentIdx, { form_name: e.target.value })}
            >
              <MenuItem key="-" value="">-</MenuItem>
              {contactForms.items.map(({ form_name }) => (
                  <MenuItem key={form_name} value={form_name}>{form_name}</MenuItem>
                ))}
          </Select>
        </FormControl>

        <div />
        
        {labelControl("send", "Button label", "Send")}
      </FormGrid>

      <FormGrid columns="1fr">
        {labelControl(
          "success",
          "Message after submission",
          "Thank you for your message! We will get back to you soon.",
          { multiline: true })}
        {labelControl(
          "error",
          "Message on error",
          "Something went wrong. Please check the values you entered and try again.",
          { multiline: true })}
        {labelControl(
          "title",
          "Form title",
          "")}
        {labelControl(
          "message",
          "Message before the form",
          "",
          { multiline: true })}
      </FormGrid>
    </>
  );
}
