import React from 'react';
import LoginForm from '../auth/LoginForm';
import SignUpForm from '../auth/SignUpForm';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Events } from '../events/Events';
import { PageList } from '../events/Pages/pageList';
import { EditPage } from '../events/Pages/editPage';
import { EventEdit } from '../events/EventEdit';


import { Users } from '../users/Users';
import { StylesEditForm } from '../events/styles/StylesEditForm';
import { QuestionsList } from '../events/questions/QuestionsList';
import { UserEditForm } from '../users/UserEditForm';
import { ContactFormsList } from '../events/contactforms/ContactFormsList';
import { ContactFormForm } from '../events/contactforms/ContactFormForm';
import { OnlyAuthorized } from './PrivateRoutes';
import { AttendeeList } from '../events/attendees/AttendeeList';
import { MediaLibraryForm } from '../events/media';
import { MeetingBookingsPage } from '../events/MeetingBooking/MeetingBookingsPage';
import { ProgramsList, ProgramForm } from '../events/programs';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/signup" element={<SignUpForm handleSignUp={() => Promise.resolve("")} />} />

      <Route path="/login" element={<LoginForm />} />

      <Route element={<OnlyAuthorized/>}>
        <Route path="/events" element={<Events />} />
        <Route path="/events/:id" element={<EventEdit />} />
        <Route path="/styles" element={<StylesEditForm />} />


        <Route path="/users" element={<Users />} />
        <Route path="/users/:username" element={<UserEditForm />} />

        <Route path="/pages" element={<PageList />} />
        <Route path="/pages/:id" element={<EditPage />} />

        <Route path="/programs" element={<ProgramsList />} />
        <Route path="/programs/:id" element={<ProgramForm />} />


        <Route path="/attendees" element={<AttendeeList />} />

        <Route path="/questions" element={<QuestionsList />} />
        <Route path="/contact-forms" element={<ContactFormsList />} />
        <Route path="/contact-forms/:name" element={<ContactFormForm />} />
        <Route path="/meetings" element={<MeetingBookingsPage />} />

        <Route path="/media" element={<MediaLibraryForm />} />
      </Route>

      <Route path="*" element={<Navigate to="/pages" />} />
    </Routes>
  );
}


