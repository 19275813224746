import { TextField } from '@mui/material';
import { Checkbox } from '../../common/Checkbox';
import { FormGrid } from '../../common/Forms';
import { EditContactFormData } from './useEditContactForm';

interface Props {
  data: EditContactFormData;
}

export const ContactFormEmailSettings = (props: Props) => {
  const { data, update } = props.data;

  return (
    <>
      <FormGrid key="settings">
        <Checkbox
          label="Send email on submission"
          checked={data.send_email}
          onChange={v => update({ send_email: v })}
          />

        <TextField
          key="email_cc"
          label="CC to"
          variant="standard"
          value={data.email_cc || ""}
          onChange={e => update({ email_cc: e.target.value })}
          />

        <TextField
          key="email_bcc"
          label="BCC to"
          variant="standard"
          value={data.email_bcc || ""}
          onChange={e => update({ email_bcc: e.target.value })}
          />
      </FormGrid>

      <FormGrid key="email" columns="1fr">
        <TextField
          key="email_subject"
          label="Subject *"
          variant="standard"
          value={data.email_subject || ""}
          onChange={e => update({ email_subject: e.target.value })}
          />

        <TextField
          key="email_body_text"
          label="Body (text) *"
          variant="standard"
          multiline
          minRows={5}
          value={data.email_body_text || ""}
          onChange={e => update({ email_body_text: e.target.value })}
          />

        <TextField
          key="email_body_html"
          label="Body (html)"
          variant="standard"
          multiline
          minRows={5}
          value={data.email_body_html || ""}
          onChange={e => update({ email_body_html: e.target.value })}
          />
      </FormGrid>
    </>
  );
}
