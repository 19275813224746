import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { NewEvent, EventsListData } from './useEventsList';
import {  Link as LinkRouter } from 'react-router-dom';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Link
} from '@mui/material';

interface Props {
  data: EventsListData;
}

export const EventsList = (props: Props) => {
  const { events, create } = props.data;
  
  const [newEvent, setNewEvent] = useState<NewEvent | null>(null);

  const startCreateEvent = () => setNewEvent({ name: "", title: "" });

  const newEventHandleChange = (field: keyof NewEvent) => {
    return (e: { target: { value?: string } }) => {
      const v = e.target.value || "";
      setNewEvent(evt => evt ? ({ ...evt, [field]: v }) : evt)
    }
  }

  const doCreateEvent = () => {
    if(newEvent) {
      create(newEvent);
      setNewEvent(null);
    }
  }

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-end">
      <IconButton onClick={() => startCreateEvent()}><Add /></IconButton>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name">Name</TableCell>
              <TableCell key="title">Title</TableCell>
              <TableCell key="admin">Admins</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {events.map(event => (
              <TableRow key={event.id}>
                <TableCell key="name">
                  <Link to={`/events/${event.id}`} component={LinkRouter}>
                    {event.name}
                  </Link>
                </TableCell>
                <TableCell key="title">{event.title}</TableCell>
                <TableCell key="admin">{event.admins.join(", ")}</TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      <Dialog open={!!newEvent} onClose={() => setNewEvent(null)}>
        <DialogTitle>New event</DialogTitle>
        <DialogContent>
          <TextField
            label="Domain name"
            value={newEvent?.name}
            onChange={newEventHandleChange("name")}
            />
          <TextField
            label="Title"
            value={newEvent?.title}
            onChange={newEventHandleChange("title")}
            />
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setNewEvent(null)}>cancel</Button>
          <Button variant="contained" onClick={() => doCreateEvent()}>create</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
