import { useNavigate } from "react-router-dom";
import SwissConfApi from "../api/ApiHelper";
import { User } from "../api/types";
import { useUserContext } from "../auth/UserContext";
import { EditData, useEditData } from "../common/useEditData";

export interface EditUserData extends EditData<User> {
  remove: () => Promise<void>;
  loginAs: () => void;
}

export const useEditUser = (username: string): EditUserData => {
  const userContext = useUserContext();
  const navigate = useNavigate();

  const data = useEditData<User, string>({
    keys: username,
    noLoad: !username,
    dflt: { } as User,
    load: username => SwissConfApi.getUser(username),
    saveChanges: (username, changes) => SwissConfApi.updateUser(username, changes),
  });

  const remove = () => {
    return SwissConfApi.deleteUser(username);
  }

  const loginAs = () => {
    SwissConfApi.loginAs(username)
      .then(token => {
        userContext.login(token);
        setTimeout(() => navigate("/events"), 1500);
      });
  }

  return {
    ...data,
    remove,
    loginAs,
  };
}