import { MailOutlined, Save } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import { ReactNode, useState } from 'react';
import { FormGrid, FreeSpace, Header } from '../../common/Forms';
import { EmailTemplateData } from './useEditEmailTemplate';

interface Props {
  data: EmailTemplateData;
  header?: ReactNode;
}

export const EmailTemplateEditor = (props: Props) => {
  const { data, update, isLoading, hasChanges, save, test } = props.data;
  const [emailForTest, setEmailForTest] = useState<string>("");
  const [isTestDialogOpen, setIsTestDialogOpen] = useState<boolean>(false);

  return (
    <FormGrid columns="1fr">
      <Header>
        {props.header}

        <FreeSpace />
        {isLoading && <CircularProgress size="1rem" />}
        {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
        <IconButton size="small" onClick={() => setIsTestDialogOpen(true)}><MailOutlined /></IconButton>
      </Header>

      <TextField
        key="subject"
        label="Subject *"
        variant="standard"
        value={data.subject || ""}
        onChange={e => update({ subject: e.target.value })}
        />

      <TextField
        key="body_text"
        label="Body (text) *"
        variant="standard"
        multiline
        minRows={5}
        value={data.body_text || ""}
        onChange={e => update({ body_text: e.target.value })}
        />

      <TextField
        key="body_html"
        label="Body (html)"
        variant="standard"
        multiline
        minRows={5}
        value={data.body_html || ""}
        onChange={e => update({ body_html: e.target.value })}
        />

      <Dialog open={isTestDialogOpen} onClose={() => setIsTestDialogOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Send a test email</DialogTitle>

        <DialogContent>
          <FormGrid columns="1fr">
            <TextField label="Email" value={emailForTest} onChange={(e)=> setEmailForTest(e.target.value)} />
          </FormGrid>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            onClick={() => {
              if(emailForTest) {
                test(emailForTest);
                setIsTestDialogOpen(false);
              }
            }}>
              test
          </Button>
        </DialogActions>
      </Dialog>
    </FormGrid>
  );
}
