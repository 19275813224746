import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import {
    Tab,
    Tabs,
    Theme,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const TabsHeaderContainer = styled(Tabs)`
    margin-top: ${props => (props as any).noMargin ? 0 : '2em'};
    margin-bottom: 0.5em;
    border-bottom: 1px solid ${props => (props.theme as Theme).palette.grey[400]};
    background: ${props => (props.theme as Theme).palette.grey[100]};
    border-top: 1px solid ${props => (props.theme as Theme).palette.grey[400]};

    & button {
        background: ${props => (props.theme as Theme).palette.grey[100]};
    }
    & button:last-child{
        border-right: 1px solid ${props => (props.theme as Theme).palette.grey[400]};
    }
    & button:first-child{
        border-left: 1px solid ${props => (props.theme as Theme).palette.grey[400]};
    }

    & button[aria-selected="true"] {
        background: ${props => (props.theme as Theme).palette.background.paper};
    }
`;

interface TabConfig {
    hidden?: boolean;
}

type TabDefinition = [string, ReactNode] | [string, ReactNode, TabConfig];

export interface TabsStateForTab {
    key: string;
    isActive: boolean;
}

export interface TabsState {
    tabs: TabDefinition[];
    labels: ReactNode[];
    keys: string[];
    current: string;
    setCurrent: (key: string) => void;
    onChange: (evt: any, key: string) => void;
    forTab: (key: string) => TabsStateForTab;
}

export const useTabsState = (tabs: TabDefinition[], defaultTab?: string, queryParam?: string): TabsState => {
    const tabsAvailable = tabs.filter(([k,l,cfg]) => !cfg || !cfg.hidden)
    const keys = tabsAvailable.map(([k,l]) => k);
    const labels = tabsAvailable.map(([k,l]) => l);

    const [currentTabKey, setCurrentTabKey] = useState<string>(defaultTab || keys[0]);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(queryParam) {
            const keyFromUrl = searchParams.get(queryParam);
            if(keyFromUrl) {
                setCurrentTabKey(keyFromUrl);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    


    const forTab = (key: string) => ({ key, isActive: currentTabKey === key})

    const setCurrent = (key: string) => {
        setCurrentTabKey(key);
        if(queryParam) {
          setSearchParams({ [queryParam]: key});
        }
    }

    return {
        tabs: tabsAvailable,
        keys,
        labels,
        current: currentTabKey,
        setCurrent,
        onChange: (e: any, k: string) => setCurrent(k),
        forTab,
    }

}


interface TabsHeaderProps {
    noMargin?: boolean;
    state: TabsState;
    scrollable?: boolean;
}


export const TabsHeader = (props: TabsHeaderProps) => {
    const { state, scrollable } = props;

    const scrollProps = scrollable? {
        variant: "scrollable" as "scrollable",
        scrollButtons: "auto" as "auto"
    } : {}

    return <TabsHeaderContainer
        value={state.current}
        onChange={state.onChange}
        indicatorColor="primary"
        {...props}
        {...scrollProps}
        textColor="primary">
            {state.tabs.map(([k, l]) => (<Tab key={k} label={l} value={k} />))}
    </TabsHeaderContainer>;
}

interface PanelProps {
  state: TabsStateForTab;
  children: ReactNode;
}

export const TabPanel = (props: PanelProps) => {
  const { state, children } = props;
  return (
    <div key={state.key} role="tabpanel" hidden={!state.isActive}>
      {children}
    </div>
  );
}