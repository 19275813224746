import { createTheme } from "@mui/material";

export const Theme = createTheme({
    palette: {
        primary: { main: "#6c1e56" },
        info: {main:"#ffffff"}
        // mode: 'dark',
    },

   

    

    components: {
      MuiFormControl: {
        defaultProps: {
          variant: "standard",
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
        }
      },
      
      MuiSelect: {
        defaultProps: {
          variant: "standard",
        }
      },

      MuiIcon:{
        defaultProps:{
          color: "info"
        }
      }
    }

});
