import React, { useState } from 'react';
import { PageComponentProps } from './types';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


export const RichTextComponent = (props: PageComponentProps) => {
  const { component, containerIdx, componentIdx, dataRaw: data } = props;
  const [state, setState] = useState<EditorState>(component.properties?.rich_content
    ? EditorState.createWithContent(convertFromRaw(component.properties?.rich_content))
    : EditorState.createEmpty());
  
  const updateState = (s: EditorState) => {
    data.updateComponentProperties(containerIdx, componentIdx, { rich_content: convertToRaw(s.getCurrentContent()) });
    setState(s);
  }

  return (
    <Editor
      editorState={state}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={updateState}
      />
  );
}
