import React from 'react';
import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useLabelsEditors } from './Labels';


export const QuestionsComponent = (props: PageComponentProps) => {
  const { labelControl } = useLabelsEditors(props);

  return (
    <FormGrid columns="repeat(4, 1fr)">
      {labelControl("email", "Email label", "Email")}
      {labelControl("fullname", "Name label", "Full name")}
      {labelControl("question", "Question label", "Your question")}
      {labelControl("send", "Button label", "Send")}
      {labelControl(
        "success",
        "Message after submission",
        "Thank you for your message! We will get back to you soon.",
        { style: { gridColumn: "span 4"}, multiline: true })}
      {labelControl(
        "error",
        "Message on error",
        "Something went wrong. Please check the values you entered and try again.",
        { style: { gridColumn: "span 4"}, multiline: true})}
      {labelControl(
        "title",
        "Form title",
        "",
        { style: { gridColumn: "span 4"} })}
      {labelControl(
        "message",
        "Message before the form",
        "",
        { style: { gridColumn: "span 4"}, multiline: true})}
    </FormGrid>
  );
}
