import { SvgIcon } from '@mui/material';
import { ReactComponent as One } from './column_icons/view_column_one.svg';
import { ReactComponent as Half } from './column_icons/view_column_half_half.svg';
import { ReactComponent as ThreeOne } from './column_icons/view_column_three_one.svg';
import { ReactComponent as OneThree } from './column_icons/view_column_one_three.svg';
import { ReactComponent as Three } from './column_icons/view_column_three.svg';
import { ReactComponent as OneTwo } from './column_icons/view_column_one_two.svg';
import { ReactComponent as TwoOne } from './column_icons/view_column_two_one.svg';
import { ReactComponent as Four } from './column_icons/view_column_four.svg';
import { ReactNode } from "react";

export const LayoutOptions: [string, string, ReactNode][] = [
  ["1fr", "1/1", <SvgIcon><One/></SvgIcon>],
  ["1fr 1fr", "1/2 1/2", <SvgIcon><Half/></SvgIcon>],
  ["1fr 1fr 1fr", "1/3 1/3 1/3", <SvgIcon><Three/></SvgIcon>],
  ["2fr 1fr", "2/3 1/3", <SvgIcon><OneTwo/></SvgIcon>],
  ["1fr 2fr", "1/3 2/3", <SvgIcon><TwoOne/></SvgIcon>],
  ["1fr 1fr 1fr 1fr", "1/4 1/4 1/4 1/4", <SvgIcon><Four/></SvgIcon>],
  ["3fr 1fr", "1/4 3/4", <SvgIcon><ThreeOne/></SvgIcon>],
  ["1fr 3fr", "3/4 1/4", <SvgIcon><OneThree/></SvgIcon>],


];