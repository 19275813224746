import { ReactNode } from "react";
import {
  CodeOutlined,
  PhotoOutlined,
  QuestionAnswerOutlined,
  ContactMailOutlined,
  TextSnippetOutlined,
  DiamondOutlined,
  EventNoteOutlined,
  CheckBoxOutlineBlank,
  SmartButtonOutlined,
  EventOutlined,
} from "@mui/icons-material";
import { Component } from "../../../api/types";
import { IEventContext } from "../../EventContext";

type AvailabilityCheck = ((ctx: IEventContext) => boolean);

export const Options: ([Component["component_type"], string, ReactNode] | [Component["component_type"], string, ReactNode, AvailabilityCheck])[] = [
  ["richtext", "Rich text", <TextSnippetOutlined />],
  ["image", "Image", <PhotoOutlined />],
  ["html", "HTML", <CodeOutlined />],
  ["questions", "Questions", <QuestionAnswerOutlined />],
  ["contactform", "Contact form", <ContactMailOutlined />],
  ["sponsors", "Sponsors", <DiamondOutlined />],
  ["agenda", "Agenda", <EventNoteOutlined />],
  ["program", "Agenda (full)", <EventNoteOutlined />],
  ["actions", "Actions", <SmartButtonOutlined />],
  ["login", "Login form", <EventNoteOutlined />, ctx => ctx.currentEvent?.is_registration_required || false],
  ["registration", "Registration form", <EventNoteOutlined />, ctx => ctx.currentEvent?.is_registration_required || false],
  ["meeting_booking", "Meeting booking", <EventOutlined />],
  ["empty", "Empty block", <CheckBoxOutlineBlank />],
]

export const OptionsMap = Options.reduce(
  (r,[type, label, icon]) => ({ ...r, [type]: { type, label, icon} }),
  {} as Record<Component["component_type"], { type: string, label: string, icon: ReactNode}>
  );
