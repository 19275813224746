import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';

interface Props {
  value: any;
  update: (v: any) => void;
  toolbarHidden?: boolean;
  placeholder?: string;
}

export const RichTextEditor = (props: Props) => {
  const [state, setState] = useState<EditorState>(props.value
    ? EditorState.createWithContent(convertFromRaw(props.value))
    : EditorState.createEmpty());
    
  const updateState = (s: EditorState) => {
    props.update(convertToRaw(s.getCurrentContent()));
    setState(s);
  }

  return (
    <Editor
      editorState={state}
      wrapperClassName="demo-wrapper"
      editorClassName="demo-editor"
      onEditorStateChange={updateState}
      toolbarHidden={props.toolbarHidden}
      placeholder={props.placeholder}
      />
  );
}
