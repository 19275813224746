import React from "react";

import { Button, CircularProgress, Paper, TextField, Typography } from "@mui/material";
import Alert from "../common/Alert";
import { useLoginForm } from "./useLoginForm";
import styled from "@emotion/styled";

const Form = styled.form`
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 1rem;

    & > * {
        max-width: 400px;
    }
`;

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;

  & > * {
    height: 100%;
  }
`;

const LoginForm = () => {
    const { credentials, update, isLoading, errors, submit } = useLoginForm();

    return(
      <Wrapper>
        <Paper>
            <div>
                {errors && <Alert errors={errors} />}
                <div>
                    {/* <h2> Login </h2> */}
                </div>
        

                <Form onSubmit={e => { e.preventDefault(); submit(); }}>
                    <Typography variant="h2">Login</Typography>
                    <TextField label="Username" value={credentials.username} onChange={e => update({ username: e.target.value })} required autoComplete="current-username" placeholder="Username" />
                    <TextField label="Password" value={credentials.password} onChange={e => update({ password: e.target.value })} required autoComplete="current-password" placeholder="Password" type="password" />


                    <Button variant="contained" type="submit" disabled={isLoading}>{isLoading ? <CircularProgress size="1rem" /> : "login" }</Button>

                </Form>
            </div>
        </Paper>
        </Wrapper>
);

}

export default LoginForm;