import { useState } from "react";
import SwissConfApi from "../../../api/ApiHelper"
import { formatDateInternal } from "../../../common/dateUtil";
import { useLoadedList } from "../../Pages/useLoadedList"
import { Block, MeetingRaw, MeetingsBookingConfig } from "../types"
import ExcelJS from "exceljs";
import { saveFile } from "../../../api/files";

export const getSponsorDescription = (config: MeetingsBookingConfig, sponsorId?: string | null) => {
  if(!sponsorId) {
    return "";
  }

  const sponsor = config.sponsors.find(s => s._id === sponsorId);

  return `${sponsor?.title || sponsorId}`;
}

export const getRepPersonDescription = (config: MeetingsBookingConfig, sponsorId?: string | null, repId?: string | null) => {
  if(!sponsorId || !repId) {
    return "";
  }

  const sponsor = config.sponsors.find(s => s._id === sponsorId);
  const rep = (sponsor?.representatives || []).find(r => r._id === repId);

  return `${rep?.name || ""} (${rep?.email || repId})`;
}

export const getRepDescription = (config: MeetingsBookingConfig, sponsorId?: string | null, repId?: string | null) => {
  if(!sponsorId || !repId) {
    return "";
  }
  return `${getRepPersonDescription(config, sponsorId, repId)}, ${getSponsorDescription(config, sponsorId)}`;
}

export const getRoomDescription = (config: MeetingsBookingConfig, roomId?: string | null) => {
  const room = config.rooms.find(r => r._id === roomId);
  return room?.title || roomId;
}

const exportMeetings = (config: MeetingsBookingConfig, meetings: MeetingRaw[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Bookings");
  sheet.columns = [
    ...[
      ["date", "Date"],
      ["start_time", "Start"],
      ["end_time", "End"],
      ["customer_email", "E-mail"],
      ["customer_name", "Name"],
      ["customer_company", "Customer company"],
      ["customer_language", "Language"],
      ["sponsor", "Sponsor"],
      ["representative", "Representative"],
      ["room", "Room"],
    ].map(([key, header]) => ({ header, key })),
    // { header: "Created at", key: "created_datetime" },
  ];

  meetings.forEach(m => sheet.addRow({
    ...m,
    room: getRoomDescription(config, m.room_id),
    representative: getRepPersonDescription(config, m.sponsor_id, m.representative_id),
    sponsor: getSponsorDescription(config, m.sponsor_id),
  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `bookings.xlsx`));

}

const meetingsByDateAndTimeComparer = (a: MeetingRaw, b: MeetingRaw) => {
  return a.date === b.date
    ? a.start_time < b.start_time ? -1 : 1
    : a.date < b.date
      ? -1
      : 1;
}

export const useScheduledMeetings = (event_id: number) => {
  const meetings = useLoadedList<MeetingRaw, { event_id: number }>({
    keys: { event_id },
    load: keys => SwissConfApi.request2("get", `/events/${keys.event_id}/meetings-booking/meeting`),
    noLoad: event_id < 0,
  });

  const getBlocksForRoom = (roomId: string) => {
    return meetings.items.filter(m => m.room_id === roomId && m.kind === "block");
  }

  const getBlocksForRep = (sponsorId: string, repId: string) => {
    return meetings.items.filter(m => m.sponsor_id === sponsorId && m.representative_id === repId && m.kind === "block");
  }

  const [isSavingBlock, setIsSavingBlock] = useState<boolean>(false);
  const addBlock = (block: Block) => {
    setIsSavingBlock(true);
    return SwissConfApi.request2("post", `/events/${event_id}/meetings-booking/block`, { data: block })
      .then(() => {
        setIsSavingBlock(false);
        return meetings.reload()
      })
      .catch(e => {
        setIsSavingBlock(false);
        throw e;
      });
  }

  const removeBlock = (block: MeetingRaw) => {
    return SwissConfApi.request2("delete", `/events/${event_id}/meetings-booking/meeting/${block.meeting_id}`)
      .then(() => meetings.reload());
  }

  

  const [addedBlock,setAddedBlock] = useState<Block | null>(null);

  const startAddBlock = (b: Partial<Block>) => {
    setAddedBlock({
      date: formatDateInternal(new Date()),
      start_time: "09:00",
      end_time: "10:00",
      ...b
    } as Block)
  };
  const cancelAddBlock = () => setAddedBlock(null);
  const updateAddedBlock = (changes: Partial<Block>) => setAddedBlock(x => x ? ({ ...x, ...changes }) : x);

  const getMeetingsForDate = (d: string) => meetings.items.filter(m => m.date === d).sort(meetingsByDateAndTimeComparer);

  return {
    meetings: meetings.items,
    isLoading: meetings.isLoading,
    addBlock,
    removeBlock,

    getBlocksForRoom,
    getBlocksForRep,

    addedBlock,
    startAddBlock,
    cancelAddBlock,
    updateAddedBlock,
    isSavingBlock,

    getMeetingsForDate,
    exportMeetings: (config: MeetingsBookingConfig) => exportMeetings(config, meetings.items.filter(m => m.kind !== "block").sort(meetingsByDateAndTimeComparer)),
  }
}

export type ScheduledMeetingsData = ReturnType<typeof useScheduledMeetings>;
