
import { useState } from 'react'
import { Link as LinkRouter, useNavigate } from 'react-router-dom';
import { usePageList } from './usePageList'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Tooltip
} from '@mui/material';
import { FormGrid, FreeSpace, Header, Sheet, Title } from '../../common/Forms';
import { Add, CheckBox, CheckBoxOutlineBlank, DeleteOutlined, FileCopyOutlined, StarOutline } from '@mui/icons-material';
import { NewPage, Page } from '../../api/types';
import { useEventContext } from '../EventContext';

interface CreateDialogProps {
  title: string;
  newPage: NewPage | null;
  update: (changes: Partial<NewPage>) => void;
  cancel: () => void;
  create: () => void;
}

const CreatePageDialog = ({ newPage, update, cancel, create, title }: CreateDialogProps) => {
  return (
    <Dialog open={!!newPage} onClose={cancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {newPage &&
          <FormGrid columns="1fr 1fr">
            <TextField
              key="slug"
              label="Slug"
              value={newPage.slug}
              onChange={e => update({ slug: e.target.value })}
              />
            <TextField
              key="title"
              label="Title"
              value={newPage.title}
              onChange={e => update({ title: e.target.value })}
              />
          </FormGrid>}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={cancel}>cancel</Button>
        <Button color="primary" variant="contained" onClick={create}>create</Button>
      </DialogActions>
    </Dialog>
  );
}

export const PageList =  () => {
  const pages = usePageList();
  const navigate = useNavigate();

  const { currentEvent } = useEventContext();

  const [newPage, setNewPage] = useState<NewPage | null>(null);

  const startCreatePage = () => {
    setNewPage({ slug: "", title: "" });
  }

  const doCreatePage = () => {
    if(newPage) {
      pages.create(newPage)
        .then(page => {
          setNewPage(null);
          navigate(`/pages/${page.id}`);
        })
    }
  }

  const [clonePage, setClonePage] = useState<NewPage & { id: number} | null>(null);

  const startClonePage = (page: Page) => {
    setClonePage({ slug: `${page.slug}-copy`, title: `${page.title} (copy)`, id: page.id });
  }

  const doClonePage = () => {
    if(clonePage) {
      pages.clone(clonePage.id, clonePage)
        .then(page => {
          setClonePage(null);
          navigate(`/pages/${page.id}`);
        })
    }
  }

  return (
    <Sheet>
      <Header>
        <Title>Pages</Title>
        <FreeSpace />
        {pages.isLoading && <CircularProgress />}
        <IconButton onClick={startCreatePage}><Add /></IconButton>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="slug"> Slug </TableCell>
              <TableCell key="title"> Title </TableCell>
              {currentEvent?.is_registration_required && <TableCell key="is_protected"> Protected </TableCell>}
              <TableCell key="actions" width={100}> Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pages.items.map(p => (
                <TableRow key={p.id}>
                  <TableCell key="slug">
                    <Link to={`/pages/${p.id}`} component={LinkRouter}>
                      {p.slug}
                      {p.slug === "home" && <StarOutline style={{ transform: "scale(0.8) translate(0, 8px)" }} />}
                    </Link>
                  </TableCell>
                  <TableCell key="title">
                    {p.title}
                  </TableCell>

                  {currentEvent?.is_registration_required && (
                    <TableCell key="title">
                      {p.is_protected ? <CheckBox color="primary" /> : <CheckBoxOutlineBlank color="disabled" />}
                    </TableCell>)}

                  <TableCell key="actions">
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center"}}>
                      <Tooltip key="clone" title="Clone page">
                        <IconButton
                          onClick={() => startClonePage(p)}
                          size="small">
                          <FileCopyOutlined />
                        </IconButton>
                      </Tooltip>

                      <Tooltip key="remove" title="Remove page">
                        <IconButton
                          onClick={() => {
                            pages.remove(p.id);
                          }}
                          size="small"
                          key="remove">
                          <DeleteOutlined />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <CreatePageDialog
        key="new"
        title="New page"
        newPage={newPage}
        cancel={() => setNewPage(null)}
        update={c => newPage && setNewPage({ ...newPage, ...c })}
        create={doCreatePage}
        />

      <CreatePageDialog
        key="clone"
        title="Clone page"
        newPage={clonePage}
        cancel={() => setClonePage(null)}
        update={c => clonePage && setClonePage({ ...clonePage, ...c })}
        create={doClonePage}
        />
    </Sheet>
  );
}
