import { useState, useEffect } from "react";

const useLocalStorage = (key:string, firstValue= null) : [string | null, (T : string|null)=>void] =>{
    const initialValue = localStorage.getItem(key) || firstValue;
    const [item, setItem] = useState(initialValue);

    useEffect(function setKeyInLocalStorage(){

        if(item === null){
            localStorage.removeItem(key)
        } else{
            localStorage.setItem(key, item)
        }
    },[key, item])

    return [item, setItem]
}

export default useLocalStorage;