import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Checkbox,
} from '@mui/material';
import { Add, GetAppOutlined } from '@mui/icons-material';
import { UsersListData } from './useUsersList';
import { Link as LinkRouter } from 'react-router-dom';
import { FormGrid, FreeSpace, Header, Sheet, Title } from '../common/Forms';
import { SearchField } from '../common/SearchField';
import { NewUser } from '../api/types';

interface Props {
  data: UsersListData;
}

export const UsersList = (props: Props) => {
  const { users, create, filter, setFilter, exportUsers, } = props.data;
  
  const [newUser, setNewUser] = useState<NewUser | null>(null);

  const startCreateUser = () => setNewUser({ username: "", email: "", password:"", first_name:"", last_name:"" });

  const newUserHandleChange = (field: keyof NewUser) => {
    return (e: { target: { value?: string } }) => {
      const v = e.target.value || "";
      setNewUser(user => user ? ({ ...user, [field]: v }) : user)
    }
  }

  const doCreateUser= () => {
    if(newUser) {
      create(newUser);
      setNewUser(null);
    }
  }

  return (
    <Sheet>
      <Header>
        <Title>Users</Title>
        <FreeSpace />
        
        <SearchField value={filter} setValue={setFilter} autoFocus />
        <IconButton onClick={startCreateUser}><Add /></IconButton>
        <IconButton onClick={exportUsers}><GetAppOutlined /></IconButton>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="username">Username</TableCell>
              <TableCell key="email">Email</TableCell>
              <TableCell key="first_name">First name</TableCell>
              <TableCell key="last_name">Last name</TableCell>
              <TableCell key="is_approved_event_admin">Admin</TableCell>
              <TableCell key="is_super_admin">Super Admin</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map(user => (
              <TableRow key={user.username}>
                <TableCell key="username">
                  <Link to={`/users/${user.username}`} component={LinkRouter}>
                    {user.username}
                  </Link>
                </TableCell>
                <TableCell key="email">{user.email}</TableCell>
                <TableCell key="first_name">{user.first_name}</TableCell>
                <TableCell key="last_name">{user.last_name}</TableCell>
                <TableCell key="is_approved_event_admin">
                  <Checkbox
                    checked={user.is_approved_event_admin}
                    />
                </TableCell>
                <TableCell key="is_super_admin">
                  <Checkbox
                    checked={user.is_super_admin}
                    />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      <Dialog open={!!newUser} onClose={() => setNewUser(null)}>
        <DialogTitle>Add new user</DialogTitle>
        <DialogContent>
          <FormGrid>
            <TextField
              label="Username"
              value={newUser?.username}
              onChange={newUserHandleChange("username")}
              />
            <TextField
              label="Email"
              value={newUser?.email}
              onChange={newUserHandleChange("email")}
              />
            <TextField
              label="Password"
              value={newUser?.password}
              onChange={newUserHandleChange("password")}
              />
            <TextField
              label="First Name"
              value={newUser?.first_name}
              onChange={newUserHandleChange("first_name")}
              />
            <TextField
              label="Last Name"
              value={newUser?.last_name}
              onChange={newUserHandleChange("last_name")}
              />
            
            </FormGrid>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setNewUser(null)}>cancel</Button>
          <Button variant="contained" onClick={() => doCreateUser()}>create</Button>
        </DialogActions>
      </Dialog>
    </Sheet>
  );
}
