import React, { DragEvent, useState } from "react";
import { Theme, Typography } from "@mui/material";
import styled from "@emotion/styled";

type FileCallback = (file: File) => void;

export const createFileDropHandler = (handleFile: FileCallback) => {
    return (e: DragEvent<any>) => {
        console.warn(e);
        e.preventDefault();
        
        if (e.dataTransfer.items) {
            for(let i = 0; i < e.dataTransfer.items.length; i++) {
                const item = e.dataTransfer.items[i];
                if(item.kind === "file") {
                    const f = item.getAsFile();
                    if(f) {
                        handleFile(f);
                    }
                    return;
                }
            }
        } else {
            if(e.dataTransfer.files.length) {
                const file = e.dataTransfer.files[0];
                handleFile(file);
                return;
            }
        }
    }
}

export const openFileUploader = (callback: (file: File) => void) => {
  const input = document.createElement('input') as HTMLInputElement;

  input.setAttribute('type', 'file');
  input.onchange = () => callback((input.files as FileList)[0]);
  input.click();
}

interface Props {
  onFileAdded: (file: File) => void;
  label?: string;
}

const FileCatcher = styled.div<{ isDragTarget: boolean }>`
  width: 100%;
  box-sizing: border-box;
  border: 2px ${props => props.isDragTarget ? "solid" : "dotted"} ${props => (props.theme as Theme).palette.primary.main}80;
  border-radius: 4px;
  text-align: center;
  padding: 1rem;

  background: ${props => props.isDragTarget ? `${(props.theme as Theme).palette.primary.main}40` : "transparent"};

  & span, & p {
    pointer-events: none;
  }
`;

export const FileUpload = (props: Props) => {
  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);
  return (
    <FileCatcher
      isDragTarget={isDragTarget}
      onDragEnter={() => setIsDragTarget(true)}
      onDragLeave={() => setIsDragTarget(false)}
      onDrop={createFileDropHandler(f => { setIsDragTarget(false); props.onFileAdded(f); })}
      onDragOver={(e: any) => { e.preventDefault(); }}
      onClick={() => openFileUploader(props.onFileAdded)}
    >
      <Typography>{props.label || "drop file here"}</Typography>
    </FileCatcher>
  );
}
