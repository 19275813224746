import SwissConfApi from "../../api/ApiHelper";
import { useEditData } from "../../common/useEditData";
import { useEventContext } from "../EventContext";

export interface EmailTemplate {
  event_id: number;
  template_id: string;
  description?: string;
  subject: string;
  body_text: string;
  body_html: string;
}

export const useEditEmailTemplate = (template_id?: string) => {
  const { currentEvent } = useEventContext();
  const data = useEditData<EmailTemplate, Pick<EmailTemplate, "event_id" | "template_id">>({
    dflt: {} as EmailTemplate,
    keys: { event_id: currentEvent?.id || -1, template_id: template_id || "" },
    
    load: keys => SwissConfApi.request2("get", `/events/${keys.event_id}/emails/${keys.template_id}`),
    saveChanges: (keys, changes) => SwissConfApi.request2("patch", `/events/${keys.event_id}/emails/${keys.template_id}`, { data: changes }),

    noLoad: !currentEvent || !template_id,
  });

  const test = (email: string) => {
    SwissConfApi.request2("post", `/events/${currentEvent?.id}/emails/${template_id}/test`, { data: { to: email }});
  }

  return {
    ...data,
    test,
  };
}

export type EmailTemplateData = ReturnType<typeof useEditEmailTemplate>;
