import { useCallback, useEffect, useState } from "react";

export interface EditData<T> {
  data: T;
  update: (changes: Partial<T>) => void;
  save: () => void;
  reload: () => void;
  isLoading: boolean;
  hasChanges: boolean;
}

interface Config<T, KeysT> {
  noLoad?: boolean;
  keys: KeysT,
  dflt: T;
  load: (ks: KeysT) => Promise<T>;
  saveChanges: (ks: KeysT, changes: Partial<T>) => Promise<T>;
}

export const useEditData = <T, KeysT>({ noLoad, keys, dflt, load, saveChanges }: Config<T, KeysT>): EditData<T> => {
  const [data, setData] = useState<T>(dflt);
  const [changes, setChanges] = useState<Partial<T>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasChanges = Object.keys(changes).length > 0;

  const update = (c: Partial<T>) => {
    setData(d => ({ ...d, ...c }));
    setChanges(d => ({ ...d, ...c }));
  }

  const reload = useCallback(() => {
    if(noLoad) {
      return;
    }

    setIsLoading(true);
    load(keys)
      .then(r => { setData(r); setIsLoading(false); setChanges({}); })
      .catch(e => { setIsLoading(false); throw e; });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(keys)]);

  const save = () => {
    if(hasChanges) {
      setIsLoading(true);
      saveChanges(keys, changes)
        .then(r => {
          setData(r);
          setChanges({});
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
          throw e;
        })
    }
  }

  useEffect(() => {
    reload();
  }, [reload]);

  return {
    data,
    isLoading,
    hasChanges,
    update,
    reload,
    save,
  };
}