import { useState } from 'react';
import styled from '@emotion/styled';
import { EditEventData } from './useEditEvent';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { FormGrid, FreeSpace, Header } from '../common/Forms';
import { useAttendanceTracking } from './useAttendanceTracking';
import { Close, FilterAltOutlined, GetAppOutlined, Settings } from '@mui/icons-material';
import { formatDate } from '../common/dateUtil';
import { EmptyState } from '../common/EmptyState';

const TrackingStatusWrapper = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
`;

const DataRow = styled(TableRow)`
  &:hover {
    background: #cccccc20;
  }
`;

const CellWithFilterButton = styled(TableCell)`
  & .MuiButtonBase-root {
    margin-left: 0.25rem;
    visibility: hidden;
  }
  &:hover {
    & .MuiButtonBase-root {
      visibility: visible;
    } 
  }
`;


interface Props {
  eventData: EditEventData;
}

export const AttendanceTrackingForm = ({ eventData }: Props) => {
  const {
    isEnabled,
    enable,
    disable,
    settings,
    updateSettings,
    stats,
    statsDates,
    exportStats,
    filter,
    setFilter,
  } = useAttendanceTracking(eventData);

  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);

  return (
    <>
      <TrackingStatusWrapper>
        <Typography>Attendance tracking is <strong>{isEnabled ? "enabled" : "disabled"}</strong></Typography>

        <FreeSpace />
        {!isEnabled && <Button color="primary" variant="contained" onClick={enable}>enable</Button>}
        {isEnabled && <Button color="primary" variant="text" onClick={disable}>disable</Button>}
        {isEnabled && <IconButton size="small" onClick={() => exportStats()}><GetAppOutlined /></IconButton>}
        {isEnabled && <IconButton size="small" onClick={() => setIsSettingsOpen(true)}><Settings /></IconButton>}
      </TrackingStatusWrapper>

      <Dialog open={isSettingsOpen} onClose={() => setIsSettingsOpen(false)}>
        <DialogTitle>Attendance tracking settings</DialogTitle>
        <DialogContent>
          <FormGrid columns="1fr">
            <TextField
              key="email_request_description"
              label="Email request description"
              helperText="Main text shown in the email request popup, markdown allowed"
              value={settings.email_request_description || ""}
              placeholder="Please enter your email"
              onChange={e => updateSettings({ email_request_description: e.target.value })}
              multiline
              />

            <TextField
              key="email_request_disclaimer"
              label="Disclaimer"
              helperText="Disclaimer text shown at the bottom of the email request popup"
              value={settings.email_request_disclaimer || ""}
              onChange={e => updateSettings({ email_request_disclaimer: e.target.value })}
              multiline
              />

            <TextField
              key="email_request_button_label"
              label="Button label"
              placeholder="Submit"
              value={settings.email_request_button_label || ""}
              onChange={e => updateSettings({ email_request_button_label: e.target.value })}
              />
            </FormGrid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSettingsOpen(false)}>close</Button>
        </DialogActions>
      </Dialog>


      <Header>
        {!!filter.email && (
          <Chip
            key="filter_email"
            label={`Email: ${filter.email}`}
            onClick={() => setFilter(f => ({ ...f, email: undefined }))}
            icon={<Close />}
            />
        )}
        {!!filter.page_slug && (
          <Chip
            key="filter_page_slug"
            label={`Page: ${filter.page_slug}`}
            onClick={() => setFilter(f => ({ ...f, page_slug: undefined }))}
            icon={<Close />}
            />
        )}

        <FreeSpace />

        {isEnabled && stats.items.length > 0 && (
          <Typography variant="caption" style={{ display: "block", width: "100%", textAlign: "right" }}>Attendance data is given in minutes spent by the user on the page</Typography>
        )}
      </Header>
      {isEnabled && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell key="email"> User </TableCell>
                <TableCell key="page_slug"> Page </TableCell>
                {statsDates.map(d => (
                  <TableCell key={d} align="right">{formatDate(d)}</TableCell>
                ))}
                
              </TableRow>
            </TableHead>
            <TableBody>
              {stats.items.map(attendance => (
                  <DataRow key={`${attendance.email}_${attendance.page_slug}`}>
                    <CellWithFilterButton key="email">
                      {attendance.email}
                      <IconButton size="small" onClick={() => setFilter(f => ({ ...f, email: f.email === attendance.email ? undefined : attendance.email }))}>
                        <FilterAltOutlined />
                      </IconButton>
                    </CellWithFilterButton>
                    <CellWithFilterButton key="page_slug">
                      {attendance.page_slug}
                      <IconButton size="small" onClick={() => setFilter(f => ({ ...f, page_slug: f.page_slug === attendance.page_slug ? undefined : attendance.page_slug }))}>
                        <FilterAltOutlined />
                      </IconButton>
                    </CellWithFilterButton>
                    {statsDates.map(d => (
                      <TableCell key={d} align="right">{attendance[d] || ""}</TableCell>
                    ))}
                  </DataRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {isEnabled && !stats.isLoading && stats.items.length === 0 && (
        <EmptyState>
          <Typography>No attendance data collected yet</Typography>
        </EmptyState>
      )}
    </>
  );
}
