import React from 'react';
import { Button, CircularProgress, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import { useEventContext } from '../EventContext';
import { useStylesEdit } from './useStylesEdit';
import { DeleteOutlined, Save } from '@mui/icons-material';
import { Sheet, Header, FreeSpace, FormGrid, Title, SectionTitle } from '../../common/Forms';
import { FontSizes, Styles } from '../../api/types';
import { Select } from '../../common/Select';
import { removeItem, updateItem } from '../../common/arrayUtil';
import { MediaItemPicker, MediaLibraryProvider } from '../media';
import { ColorPicker } from '../../common/ColorPicker';


export const StylesEditForm = () => {
  const { currentEvent } = useEventContext();
  const { data, isLoading, update, save, hasChanges } = useStylesEdit();

  if(!currentEvent) {
    return <Typography>Please select the event first</Typography>;
  }

  const footer_settings = data?.footer_settings || {};

  const theme_settings = data?.theme_settings || {};
  const font_sizes = theme_settings.font_sizes || {};
  

  return (
    <MediaLibraryProvider>
      <Sheet>
        <Header>
          <Title>Appearence</Title>

          <FreeSpace />
          {isLoading && <CircularProgress />}
          {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
        </Header>

        <FormGrid key="settings-base">
          <TextField
            label="Font"
            variant="standard"
            value={data.font || ""}
            onChange={e => update({ font: e.target.value })}
            />

          <div key="logo" style={{ justifySelf: "end"}}>
            <Typography variant="caption">Logo</Typography>
            <MediaItemPicker
              selectedUrl={data.logo || ""}
              setSelectedUrl={logo => update({ logo })}
              />
          </div>

          <div key="logo" style={{ justifySelf: "end"}}>
            <Typography variant="caption">Favicon</Typography>
            <MediaItemPicker
              selectedUrl={data.favicon || ""}
              setSelectedUrl={favicon => update({ favicon })}
              />
          </div>
        </FormGrid>

        <SectionTitle key="title-colors">Colors</SectionTitle>

        <FormGrid key="settings-colors" columns="repeat(4, 1fr)">
          {([
            ["primary_color", "Primary", "I have no idea"],
            ["highlight_color", "Highlight", "???"],
            ["text_color", "Text", "Main text color"],
            ["background_color", "Background", ""],
          ] as [keyof Styles, string, string][])
            .map(([f, label, hint]) => (
            <ColorPicker
              key={f}
              label={label}
              hint={hint}
              value={data[f] as string || ""}
              update={c => update({ [f]: c })}
              disableClear
              />
          ))}

          {([
            ["header_color", "Header"],
            ["footer_color", "Footer"],
          ] as [keyof Styles, string][])
          .map(([f, label]) => (
            <Select
              value={data[f] || "background"}
              onChange={e => update({ [f]: e.target.value })}
              values={[["background", "background"], ["primary", "primary"], ["highlight", "highlight"]]}
              label={label}
              />
          ))}
        </FormGrid>

        <SectionTitle key="title-spacing">Spacing</SectionTitle>
        <FormGrid key="settings-spacing" columns="repeat(4,1fr)">
          {([
            ["site_horizontal_padding", "Site horizontal", "Left and right padding around site content"],
            ["block_horizontal_padding", "Block horizontal", "Left and right padding around blocks"],
            ["block_vertical_padding", "Block vertical", "Vertical spacing between blocks"],
          ] as [keyof Styles, string, string][])
          .map(([f, label, hint]) => (
            <TextField
              key={f}
              label={label}
              helperText={hint}
              value={data[f] || ""}
              onChange={e => update({ [f]: e.target.value })}
              />
          ))}

          <TextField
            label="Border radius"
            helperText="for buttons and input elements"
            value={theme_settings.border_radius || ""}
            onChange={e => update({ theme_settings: { ...theme_settings, border_radius: e.target.value } })}
            />
        </FormGrid>

        <SectionTitle key="title-fonts">Font sizes</SectionTitle>
        <FormGrid key="settings-fonts" columns="repeat(4,1fr)">
          <Typography key="guide-base" variant="caption" style={{ gridColumn: "span 4"}}>
            You can change the <i>base</i> size - it is specified only in px (just enter a number) and affects every other font size.
          </Typography>

          <Typography key="guide-other" variant="caption" style={{ gridColumn: "span 4"}}>
            You can alse change sizes for specific types of typography, like headers and element captions. You can specify these in any allowed css unit, including exact px size or rem, which will be relative to the base size.
          </Typography>

          {([
            ["base", "Base", "number in px only"],
            ["h1", "H1"],
            ["h2", "H2"],
            ["h3", "H3"],
            ["h4", "H4"],
            ["h5", "H5"],
            ["h6", "H6"],
          ] as [keyof FontSizes, string, string][])
          .map(([f, label, hint]) => (
            <TextField
              key={f}
              label={label}
              helperText={hint}
              value={font_sizes[f] || ""}
              onChange={e => update({ theme_settings: { ...theme_settings, font_sizes: { ...font_sizes, [f]: e.target.value }} })}
              />
          ))}
        </FormGrid>

        <SectionTitle key="title-header">Header</SectionTitle>
        <FormGrid key="settings-header" columns="repeat(4,1fr)">
          {([
            ["title_font_size", "Title font size", ""],
            ["logo_max_height", "Logo height", ""],
          ] as [keyof Styles["header_settings"], string, string][])
          .map(([f, label, hint]) => (
            <TextField
              label={label}
              helperText={hint}
              value={(data.header_settings || {})[f] || ""}
              onChange={e => update({ header_settings: { ...(data.header_settings || {}), [f]: e.target.value || "" } })}
              />
          ))}
        </FormGrid>

        
        <FormGrid key="settings-footer" columns="repeat(2,1fr)">
          <SectionTitle key="title-footer">Footer</SectionTitle>
          <Typography style={{ alignSelf: "end"}}>Links</Typography>

          <TextField
            label="Text"
            helperText="displayed in the left part of the footer"
            value={footer_settings?.text || ""}
            multiline
            onChange={e => update({ footer_settings: { ...footer_settings, text: e.target.value || "" } })}
            />
          
          <div>
            <FormGrid columns="1fr 1fr max-content">
              {(footer_settings.links || []).map(({ url, title }, idx) => (
                <React.Fragment key={`${idx}`}>
                  <TextField
                    key="url"
                    label="URL"
                    value={url || ""}
                    onChange={e => update({
                      footer_settings: {
                        ...footer_settings,
                        links: updateItem(footer_settings?.links || [], idx, { url: e.target.value })
                      } })}
                    />
                  <TextField
                    key="title"
                    label="Title"
                    value={title || ""}
                    onChange={e => update({
                      footer_settings: {
                        ...footer_settings,
                        links: updateItem(footer_settings?.links || [], idx, { title: e.target.value })
                      } })}
                    />

                  <Tooltip title="remove link">
                    <IconButton size="small" onClick={() => update({
                      footer_settings: {
                        ...footer_settings,
                        links: removeItem(footer_settings?.links || [], idx)
                      } })}>
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </React.Fragment>
              ))}
            </FormGrid>

            <Button size="small" onClick={() => update({ footer_settings: { ...footer_settings, links: [...(footer_settings.links || []), { url: "", title: "" }]}})}>add link</Button>
          </div>
        </FormGrid>

        <SectionTitle key="title-css">Global CSS</SectionTitle>
        <TextField
          style={{ width: "100%" }}
          InputProps={{
            style: { fontFamily: "monospace", background: "#f7f7f7" },
          }}
          value={data.global_css || ""}
          multiline
          onChange={e => update({ global_css: e.target.value })}
          />

      </Sheet>
    </MediaLibraryProvider>
  );
}
