import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { EditProgramData, useEditProgram } from './useEditProgram';
import { Add, AddLinkOutlined, DeleteOutlined, EditOutlined, LinkOffOutlined, Save, UploadFileOutlined } from '@mui/icons-material';
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Paper, Snackbar, TextField, Typography } from '@mui/material';
import { Sheet, Header, FreeSpace, Title, FormGrid } from '../../common/Forms';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import { ProgramItemDialog } from './ProgramItemDialog';
import { MediaLibraryProvider } from '../media';
import { Checkbox } from '../../common/Checkbox';
import { usePrompt } from '../../common/useBlocker';
import { FloatingBottomButton } from '../Pages/editPage';
import { FileUpload } from '../../common/FileUpload';
import { ProgramItem } from '../../api/types';
import React from 'react';

const HintText = styled(Typography)`
  margin: 1rem 0 0;

  & a {
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    font-weight: 500;
  }
`;

const ChangingParentPanel = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 0.5rem 1rem;
`;

const ItemWrapper = styled.div<{ highlight?: boolean }>`
  display: grid;
  grid-template-columns: 4fr 2fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;

  position: relative;
  
  background: ${props => props.highlight ? "#6c1e5620" : "transparent"};
  &:nth-child(2n) {
    background: ${props => props.highlight ? "#6c1e5620" : "#f0f0f0"};
  }

  & .actions {
    position: absolute;
    padding: 0 2px;
    top: 0;
    left: 0;
    transform: translate(-100%, 5px) scale(0.75);
    transform-origin: top right;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &:hover {
      opacity: 1;
    }

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
  }
`;

const ItemWrapperChild = styled(ItemWrapper)`
  margin-left: 2rem;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  
  margin-top: 2rem;
  gap: 2rem;
`;


const useChangeParent = (data: EditProgramData) => {
  const [forId, setForId] = useState<string | null>(null);

  const start = (id: string) => {
    setForId(id);
  }

  const canChangeParentTo = (newParent: ProgramItem): boolean => {
    return !!forId && !newParent.parent_id && newParent.id !== forId;
  }

  const complete = (newParent: ProgramItem) => {
    if(forId && canChangeParentTo(newParent)) {
      setForId(null);
      data.updateItemParent(forId, newParent.id || null)
    }
  }

  return {
    isChanging: !!forId,
    forId,
    start,
    canChangeParentTo,
    complete,
    cancel: () => { setForId(null); }
  }
}

interface ProgramItemControlsProps {
  item: ProgramItem;
  changeParent: ReturnType<typeof useChangeParent>;
  data: EditProgramData;
  startEdit: (id: string | null) => void;
}

const ProgramItemControls = (props: ProgramItemControlsProps) => {
  const { item, changeParent, data: programData, startEdit } = props;

  return <>
    <div className="actions">
      <IconButton size="small" onClick={() => startEdit(item.id)}><EditOutlined /></IconButton>
      {!item.parent_id && !item.children?.length && !changeParent.isChanging &&
        <IconButton size="small" onClick={() => changeParent.start(item.id)}><AddLinkOutlined /></IconButton>}
      {!!item.parent_id && !changeParent.isChanging &&
        <IconButton size="small" onClick={() => programData.updateItemParent(item.id, null)}><LinkOffOutlined /></IconButton>}
      <IconButton size="small" onClick={() => programData.removeItem(item.id)}><DeleteOutlined /></IconButton>
    </div>

    <TextField
      label="Title"
      variant="standard"
      value={item.title || ""}
      onChange={e => programData.updateItem(item.id, { title: e.target.value })}
      error={!item.title}
      />

    <DesktopDatePicker
      label="Date"
      inputFormat="dd.MM.yyyy"
      value={item.date || null}
      onChange={v => programData.updateItem(item.id, { date: v })}
      renderInput={(params) => <TextField {...params} error={params.error || !item.date} />}
      />

    <DesktopTimePicker
      label="Time"
      inputFormat="HH:mm"
      ampm={false}
      value={item.time || null}
      onChange={v => programData.updateItem(item.id, { time: v })}
      renderInput={(params) => <TextField {...params} error={params.error || !item.time} />}
      
      />

    <DesktopTimePicker
      label="End Time"
      inputFormat="HH:mm"
      ampm={false}
      value={item.end_time || null}
      onChange={v => programData.updateItem(item.id, { end_time: v })}
      renderInput={(params) => <TextField {...params} />}
      />
    
    <TextField
      label="Location"
      variant="standard"
      value={item.location || ""}
      onChange={e => programData.updateItem(item.id, { location: e.target.value })}
      />

    <TextField
      label="Category"
      variant="standard"
      value={item.category || ""}
      onChange={e => programData.updateItem(item.id, { category: e.target.value })}
      />

    
    <Checkbox
      label="Hidden"
      checked={item.is_hidden || false}
      onChange={v => programData.updateItem(item.id, { is_hidden: v})}
      style={{ alignSelf: "end" }}
      />
    
    {!item.parent_id
      ? <Button
        size="small"
        onClick={() => programData.addItem({ parent_id: item.id })}
        startIcon={<Add />}
        >
        add child
      </Button>
      : <div />}
  </>
}


export const ProgramForm = () => {
  const { id } = useParams<{ id: string }>();
  const programData = useEditProgram(+(id || -1), { onSave: () => setIsSaveSuccessSnackbarOpen(true) });
  const {
    data: program,
    update,
    save,
    hasChanges,
    isLoading,
    itemsTree,
  } = programData;

  const [editItemId, setEditItemId] = useState<string | null>(null);
  const editItem = useMemo(() => {
    return editItemId ? programData.items.find(x => x.id === editItemId) || null : null;
  }, [editItemId, programData.items]);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [isSaveSuccessSnackbarOpen, setIsSaveSuccessSnackbarOpen] = useState<boolean>(false);

  const changeParent = useChangeParent(programData);
  
  usePrompt("There are unsaved changes to the page. Are you sure you want to leave?", hasChanges);

  return (
    <MediaLibraryProvider>
      <Sheet>
        <Header>
          <Title>Program</Title>
          <FreeSpace />
          
          <Button size="small" onClick={() => setIsUploadDialogOpen(true)} startIcon={<UploadFileOutlined />}>upload</Button>
          {(isLoading || programData.isUploadingProgram) && <CircularProgress />}
          {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
          {hasChanges && <FloatingBottomButton size="medium" onClick={() => save()}>
            <Save color="primary" />
          </FloatingBottomButton>}
        </Header>
        
        <FormGrid columns="1fr">
          <TextField
            label="Title"
            variant="standard"
            value={program.title || ""}
            onChange={e => update({ title: e.target.value })}
            />

        </FormGrid>

        <ItemsWrapper>
          {itemsTree.map(item => (
            <React.Fragment key={item.id}>
              <ItemWrapper
                highlight={changeParent.canChangeParentTo(item)}
                onClick={() => {
                  if(changeParent.canChangeParentTo(item)) {
                    changeParent.complete(item);
                  }
                }}>
                  <ProgramItemControls
                    item={item}
                    changeParent={changeParent}
                    data={programData}
                    startEdit={setEditItemId}
                    />
              </ItemWrapper>

              {(item.children || []).map(child => (
                <ItemWrapperChild
                  key={child.id}>
                    <ProgramItemControls
                      item={child}
                      changeParent={changeParent}
                      data={programData}
                      startEdit={setEditItemId}
                      />
                </ItemWrapperChild>
              ))}
            </React.Fragment>
          ))}

          <Button onClick={() => programData.addItem()} startIcon={<Add />}>add item</Button>
        </ItemsWrapper>


        {changeParent.isChanging && <ChangingParentPanel>
          <Typography>Click the group agenda item to include selected item in it</Typography>
          <Button size="small" color="primary" onClick={() => changeParent.cancel()}>cancel</Button>
        </ChangingParentPanel>}


        <ProgramItemDialog
          item={editItem}
          close={() => setEditItemId(null)}
          update={programData.updateItem}
          />
        
        <Dialog open={isUploadDialogOpen} onClose={() => setIsUploadDialogOpen(false)}>
          <DialogTitle>Upload program</DialogTitle>
          <DialogContent>

            <FileUpload
              onFileAdded={f => { programData.uploadProgram(f); setIsUploadDialogOpen(false); }}
              label={`Drop an Excel file here`}
              />

            <HintText>
              You may start with the <a href="/extra/dconf_program_template.xlsx" target="_blank" rel="noreferrer noopener">template file</a>.
            </HintText>

          </DialogContent>
        </Dialog>

        <Snackbar
          open={isSaveSuccessSnackbarOpen}
          autoHideDuration={5000}
          onClose={() => setIsSaveSuccessSnackbarOpen(false)}
          message="Program gets sorted by date and time when saving"
        />
      </Sheet>
    </MediaLibraryProvider>
  );
}
