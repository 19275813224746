import { useEffect, useState } from "react";
import SwissConfApi from "../api/ApiHelper";
import { NewUser, User } from "../api/types";
import { useUserContext } from "../auth/UserContext";
import ExcelJS from "exceljs";
import { saveFile } from "../api/files";

export interface UsersListData {
  users: User[];
  isLoading: boolean;
  reload: () => void;
  create: (data: NewUser) => Promise<User>;
  filter: string;
  setFilter: (v: string) => void;
  exportUsers: () => void;
}

const exportUsers = (users: User[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Users');
  sheet.columns = [
    { header: "Username", key: "username", width: 16 },
    { header: "Email", key: "email", width: 24 },
    { header: "First name", key: "first_name", width: 16 },
    { header: "Last name", key: "last_name", width: 16 },
    { header: "Event admin",  key: "is_approved_event_admin", width: 12 },
    { header: "Super admin",  key: "is_super_admin", width: 12 },
  ];

  users.forEach(q => sheet.addRow(q));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "users.xlsx"));
}

export const useUsersList = (): UsersListData => {
  const [users, setUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useUserContext();

  const [filter, setFilter] = useState<string>("");

  const reload = async () => {
    setIsLoading(true);
    const data = await SwissConfApi.getAllusers();
    setUsers(data);
    setIsLoading(false);   
  }

  const create = async (data: NewUser) => {
    const user = await SwissConfApi.addUser(data);
    reload();
    return user as User;
  }

  useEffect(() => {
    if(currentUser?.username) {
      reload();
    }
  }, [currentUser?.username]);

  const userStr = (u: User) => `${u.username} ${u.email} ${u.first_name} ${u.last_name}`;

  const filteredUsers = filter.length ? users.filter(u => userStr(u).toLowerCase().includes(filter.toLowerCase())) : users

  return {
    users: filteredUsers,
    isLoading,
    reload,
    create,
    filter,
    setFilter,
    exportUsers: () => exportUsers(filteredUsers),
  };
}