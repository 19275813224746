import { useState } from "react";
import SwissConfApi from "../../api/ApiHelper";
import { useEventContext } from "../EventContext";
import { ListData, useLoadedList } from "../Pages/useLoadedList";

export interface FileInfo {
  event_id: number;
  id: number;
  filename: string;
  content_type: string;
  uploaded_at: string;
}

export interface MediaLibraryData extends ListData<FileInfo> {
  uploadFile: (file: any) => Promise<FileInfo>;
  removeFile: (fi: FileInfo) => Promise<FileInfo>;
  isUploading: boolean;
  
  getFilePath: (fi: FileInfo) => string;
  getFileType: (fi: FileInfo) => string;
  getFileUrl: (fi: FileInfo) => string;

  filter: string;
  setFilter: (v: string) => void;
}

interface Config {
  noLoad?: boolean;
}

export const useMediaLibraryData = (cfg?: Config): MediaLibraryData => {
  const { currentEvent } = useEventContext();
  const [filter, setFilter] = useState<string>("");

  const files = useLoadedList<FileInfo, number>({
    keys: currentEvent?.id || -1,
    noLoad: !currentEvent || cfg?.noLoad,
    load: (eventId) => SwissConfApi.request(`/events/${eventId}/media`),
  });

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadFile = (file: any) => {
    if(!currentEvent) {
      throw Error("No event");
    }
    setIsUploading(true);
    const data = new FormData();
    data.append("file", file);
    return SwissConfApi.request2("post", `/events/${currentEvent.id}/media`, { data })
      .then(fi => {
        setIsUploading(false);
        files.reload();
        return fi as FileInfo;
      })
      .catch(e => {
        setIsUploading(false);
        throw e;
      });
  }

  const removeFile = (fi: FileInfo) => {
    if(!currentEvent) {
      throw Error("No event");
    }

    return SwissConfApi.request2("delete", `/events/${currentEvent.id}/media/${fi.id}`)
      .then(() => { files.reload(); return fi; });
  }

  const getFilePath = (fi: FileInfo) => `/uploads/${currentEvent?.id}/${fi.filename}`;

  const items = filter.length ? files.items.filter(fi => fi.filename.toLowerCase().includes(filter.toLowerCase())) : files.items;

  return {
    ...files,
    items,
    filter,
    setFilter,

    uploadFile,
    isUploading,
    removeFile,

    getFilePath,
    getFileType: fi => { const extIdx = fi.filename.lastIndexOf("."); return extIdx >= 0 ? fi.filename.substring(extIdx+1) : fi.content_type; },
    getFileUrl: fi => `https://${currentEvent?.name}${getFilePath(fi)}`,
  }
}