import { useState } from 'react';
import { Add, Check, GetAppOutlined, UploadFile } from '@mui/icons-material';
import { CircularProgress, Link, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Button, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, TextFieldProps, TextField, Checkbox } from '@mui/material';
import { formatDateTime } from '../../common/dateUtil';
import { Sheet, Header, FreeSpace, Title, FormGrid } from '../../common/Forms';
import { useAttendeeList, AttendeeListData } from './useAttendeeList';
import { AttendeeUser } from '../../api/types';
import { useUploadAttendees } from './useUploadAttendees';
import { FileUpload } from '../../common/FileUpload';
import { SearchField } from '../../common/SearchField';

interface Props {
  
}

const useCreateAttendee = (data: Pick<AttendeeListData, "create">) => {
  const [attendee, setAttendee] = useState<Partial<AttendeeUser> | null>(null);

  return {
    attendee,
    isCreating: !!attendee,
    startCreating: () => setAttendee({}),
    cancel: () => setAttendee(null),
    update: (changes: Partial<AttendeeUser>) => setAttendee(a => ({ ...a, ...changes})),
    doCreate: () => {
      if(attendee) {
        data.create([attendee]).then(() => setAttendee(null));
      }
    }
  }
}

export const AttendeeList = (props: Props) => {
  const data = useAttendeeList();
  const { items, update, isLoading, exportAttendees, filter, setFilter, extraFields } = data;

  const createAttendee = useCreateAttendee(data);
  const uploadAttendees = useUploadAttendees(data);

  const createAttendeeControl = (field: keyof AttendeeUser, label: string, extraProps?: Partial<TextFieldProps>) => (
    <TextField
      value={(createAttendee.attendee || {})[field] || ""}
      onChange={e => createAttendee.update({ [field]: e.target.value })}
      label={label}
      name={field}
      {...(extraProps || {})}
      />
  );

  const extraFieldsLabels = extraFields.length ? extraFields.map(f => f.label).join(", ") : "";

  return (
    <Sheet>
      <Header>
        <Title>Attendees</Title>
        <FreeSpace />
        {isLoading && <CircularProgress />}
        <SearchField value={filter} setValue={setFilter} autoFocus />
        <Tooltip key="create" title="Create attendee">
          <IconButton size="small" onClick={createAttendee.startCreating}><Add /></IconButton>
        </Tooltip>
        <Tooltip key="upload" title="Upload attendees">
          <IconButton size="small" onClick={uploadAttendees.startUploading}><UploadFile /></IconButton>
        </Tooltip>
        <Tooltip key="export" title="Export attendees to Excel">
          <IconButton onClick={() => exportAttendees()}><GetAppOutlined /></IconButton>
        </Tooltip>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="email"> Email </TableCell>
              <TableCell key="name"> Name </TableCell>
              <TableCell key="is_approved"> Approved? </TableCell>
              <TableCell key="created_datetime"> Registered at </TableCell>
              {extraFields.map(f => (
                  <TableCell key={f.field}>{f.label}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(attendee => (
                <TableRow key={attendee.email}>
                  <TableCell key="email">
                    <Link href={`mailto:${attendee.email}`}>
                      {attendee.email}
                    </Link>
                  </TableCell>
                  <TableCell key="name">
                    {attendee.first_name} {attendee.last_name}
                  </TableCell>
                  <TableCell key="is_approved">
                    <Checkbox checked={attendee.is_approved || false} onChange={e => update(attendee.email, { is_approved: e.target.checked })} />
                  </TableCell>
                  <TableCell key="created_datetime">
                    {formatDateTime(attendee.created_datetime)}
                  </TableCell>
                  {extraFields.map(f => {
                    const value = (attendee?.fields || {})[f.field];
                    return (<TableCell key={f.field}>
                      {value === true ? <Check /> : value}
                    </TableCell>
                  )})}
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Dialog key="create-attendee" open={createAttendee.isCreating} onClose={createAttendee.cancel} maxWidth="sm" fullWidth>
        <DialogTitle>Create attendee</DialogTitle>
        {createAttendee.attendee && <DialogContent>
          <FormGrid columns="1fr 1fr">
            {createAttendeeControl("email", "Email")}
            {createAttendeeControl("password", "Password", { type: "password" })}
            {createAttendeeControl("first_name", "First name")}
            {createAttendeeControl("last_name", "Last name")}
          </FormGrid>
        </DialogContent>}
        <DialogActions>
          <Button color="inherit" onClick={createAttendee.cancel}>cancel</Button>
          <Button color="primary" onClick={createAttendee.doCreate}>create</Button>
        </DialogActions>
      </Dialog>

      <Dialog key="upload-attendees" open={uploadAttendees.isActive} onClose={uploadAttendees.cancel} maxWidth="sm" fullWidth>
        <DialogTitle>Upload attendees</DialogTitle>
        <DialogContent>
          {uploadAttendees.isPlacingFile && (
            <FileUpload
              onFileAdded={f => uploadAttendees.processXLSX(f)}
              label={`Drop an Excel file here. The columns should be: Email, Password, First name, Last name${extraFieldsLabels ? ", " : ""}${extraFieldsLabels}`}
              />)}
          {!uploadAttendees.isPlacingFile && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell key="email"> Email </TableCell>
                    <TableCell key="password"> Password </TableCell>
                    <TableCell key="first_name"> First name </TableCell>
                    <TableCell key="last_name"> Last name </TableCell>
                    {extraFields.map(f => (
                      <TableCell key={f.field}>{f.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadAttendees.uploaded.map(attendee => (
                      <TableRow key={attendee.email}>
                        <TableCell key="email">
                          {attendee.email}
                        </TableCell>
                        <TableCell key="password">
                          {attendee.password}
                        </TableCell>
                        <TableCell key="first_name">
                          {attendee.first_name}
                        </TableCell>
                        <TableCell key="last_name">
                          {attendee.last_name}
                        </TableCell>
                        {extraFields.map(f => (
                          <TableCell key={f.field}>{(attendee as any)[f.field]}</TableCell>
                        ))}
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={uploadAttendees.cancel}>cancel</Button>
          <Button color="primary" onClick={uploadAttendees.save}>upload</Button>
        </DialogActions>
      </Dialog>
    </Sheet>
  );
}
