import { TextField, TextFieldProps } from "@mui/material";
import { PageComponentProps } from "./types";

export const useLabelsEditors = (props: PageComponentProps) => {
  const { component, data } = props;
  const labels = component.properties?.labels || {};

  const labelControl = (field: string, label: string, placeholder: string, extraProps?: TextFieldProps) => (
    <TextField
      {...extraProps}
      label={label}
      value={labels[field] || ""}
      placeholder={placeholder}
      onChange={e => {
        data.updateComponentProperties({ labels: { ...labels, [field]: e.target.value }});
      }}
      />
  );

  return {
    labels,
    labelControl,
  }
}
