import React from 'react';
import { DeleteOutlined } from '@mui/icons-material';
import { Button, IconButton, TextField } from '@mui/material';
import { FormGrid } from '../../../common/Forms';
import { Select } from '../../../common/Select';
import { PageComponentProps } from './types';

interface Action {
  title: string;
  color: string;
  url: string;
}

const useEditActions = (props: PageComponentProps) => {
  const { data, component } = props;

  const actions = (component.properties?.actions || []) as Action[];

  const addAction = () => {
    data.updateComponentProperties({ actions: [...actions, { title: "", color: "primary", url: "" }]});
  };

  const removeAction = (idx: number) => {
    data.updateComponentProperties(({ actions: actions.filter((a,i) => idx !== i)}));
  };

  const updateAction = (idx: number, changes: Partial<Action>) => {
    data.updateComponentProperties(({ actions: actions.map((a,i) => i === idx ? { ...a, ...changes } : a)}));
  }

  return {
    actions,
    addAction,
    updateAction,
    removeAction,
  }
}

export const ActionsComponent = (props: PageComponentProps) => {
  const { actions, addAction, removeAction, updateAction } = useEditActions(props);

  return (
    <>
      <FormGrid columns="repeat(3,1fr) max-content">
        {actions.map((a,idx) => (
          <React.Fragment key={idx}>
            <TextField
              key="title"
              label="Title"
              value={a.title}
              onChange={e => updateAction(idx, { title: e.target.value })}
              />

            <Select
              key="color"
              label="Color"
              value={a.color}
              values={[["primary", "primary"], ["highlight", "highlight"], ["text", "text"], ["background", "background"]]}
              onChange={e => updateAction(idx, { color: e.target.value as string })}
              />

            <TextField
              key="url"
              label="URL"
              value={a.url}
              onChange={e => updateAction(idx, { url: e.target.value })}
              />

            <IconButton size="small" onClick={() => removeAction(idx)}><DeleteOutlined /></IconButton>
          </React.Fragment>
        ))}
      </FormGrid>

      <Button size="small" onClick={addAction}>add action</Button>
    </>
  );
}
