import { useCallback, useEffect, useState } from "react";



export interface ListData <T> {
  items: T[];
  isLoading: boolean;
  reload: () => void;
  setData: (newValue: T[]) => void;
}

interface Config<T, TKeys> {
  load: (keys: TKeys) => Promise<T[]>;
  keys: TKeys;
  noLoad?: boolean;
}

export const useLoadedList = <T, TKeys>({ keys, load, noLoad }: Config<T, TKeys>): ListData <T> => {
  const [items, setItems] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const reload = useCallback(() => {
    if(!noLoad) {
      setIsLoading(true);
      load(keys)
        .then(data => {
          setItems(data); 
          setIsLoading(false);
        })
        .catch(e => {
          setIsLoading(false);
          throw e;
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noLoad, JSON.stringify(keys)]);

 
  useEffect(() => {
    reload();
  }, [reload]);

  return {
    items,
    isLoading,
    reload,
    setData: (newValue) => setItems(newValue),
  };
}