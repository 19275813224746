import React, { useState } from 'react';
import { useEditPage } from './useEditPage'
import { useParams } from 'react-router-dom'
import { TextField, CircularProgress, IconButton, Button, Fab } from '@mui/material'
import { FormGrid, FreeSpace, Header, SectionTitle, Sheet, Title } from '../../common/Forms'
import { Save } from '@mui/icons-material'
import styled from '@emotion/styled'
import { ComponentHeader, ComponentPaper, getPageComponentType } from './components'
import { usePrompt } from '../../common/useBlocker'
import { ComponentContainer } from './ComponentContainer'
import { Component } from '../../api/types';
import { PlaceComponentDialog } from './PlaceComponentDialog';
import { Checkbox } from '../../common/Checkbox';
import { MediaLibraryProvider } from '../media';
import { EditContainerDialog } from './EditContainerDialog';
import { useEventContext } from '../EventContext';


export const FloatingBottomButton = styled(Fab)`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 500;
`;

export const EditPage = () => {
  const {id} = useParams <{id : string}>()
  const data = useEditPage(Number(id));
  const { currentEvent } = useEventContext();
  const { page, isLoading, hasChanges, save, update, updateContainer, updateContainerProperties } = data;

  const [movedComponent, setMovedComponent] = useState<{ component: Component, containerIdx: number, componentIdx: number } | null>(null);
  const [editedContainerIdx, setEditedContainerIdx] = useState<number | null>(null);

  usePrompt("There are unsaved changes to the page. Are you sure you want to leave?", hasChanges);

  return (
    <MediaLibraryProvider>
      <Sheet>
        <Header>
          <Title>Edit Page</Title>

          <FreeSpace />
          {isLoading && <CircularProgress />}
          {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
          {hasChanges && <FloatingBottomButton size="medium" onClick={() => save()}>
            <Save color="primary" />
          </FloatingBottomButton>}
        </Header>

        <FormGrid>
          <TextField key="slug" label="Slug" value={page.slug || ""} onChange={(e)=> update({slug:e.target.value})} />
          <TextField key="title" label="Title" value={page.title || ""} onChange={(e)=> update({title:e.target.value})} />
          <FormGrid columns="1fr">
            <Checkbox key="is_protected" label="Protected page" checked={page.is_protected} onChange={v => update({ is_protected: v })} />
            {currentEvent && currentEvent.is_attendance_tracking_enabled &&
              <Checkbox key="is_attendance_tracking_disabled" label="Disable tracking" checked={page.is_attendance_tracking_disabled} onChange={v => update({ is_attendance_tracking_disabled: v })} />}
          </FormGrid>
        </FormGrid>
      
        <SectionTitle>Content</SectionTitle>

        {page.content.map((container,containerIdx) => (
          <ComponentContainer
            key={container._id || containerIdx}
            container={container}
            containerIdx={containerIdx}
            data={data}
            editContainer={() => setEditedContainerIdx(containerIdx)}
            >
            {container.components.map((component, componentIdx) => {
              const ComponentEditor = getPageComponentType(component.component_type);
              const componentData = {
                updateComponentContent: (content: string) => data.updateComponentContent(containerIdx, componentIdx, content),
                updateComponentProperties: (changes: Record<string, any>) => data.updateComponentProperties(containerIdx, componentIdx, changes),
                removeComponent: () => data.removeComponent(containerIdx, componentIdx),
              }
              return (
                <ComponentPaper key={component._id || `${componentIdx}_${component.component_type}`} is_hidden={component.properties?.is_hidden}>
                  <ComponentHeader
                    component={component}
                    componentIdx={componentIdx}
                    containerIdx={containerIdx}
                    dataRaw={data}
                    data={componentData}
                    startPlaceComponent={() => setMovedComponent({ component, componentIdx, containerIdx })}
                    />
                  <ComponentEditor
                    component={component}
                    componentIdx={componentIdx}
                    containerIdx={containerIdx}
                    dataRaw={data}
                    data={componentData}
                    />
                </ComponentPaper>);
            })}
          </ComponentContainer>
        ))}

        <Button onClick={() => data.addContainer()}>add container</Button>

        <PlaceComponentDialog
          source={movedComponent}
          cancel={() => setMovedComponent(null)}
          placeComponent={data.placeComponent}
          page={data.data}
          />

        <EditContainerDialog
          isOpen={editedContainerIdx !== null}
          close={() => setEditedContainerIdx(null)}
          container={editedContainerIdx !== null ? page.content[editedContainerIdx] : undefined}
          updateContainer={c => editedContainerIdx !== null && updateContainer(editedContainerIdx, c)}
          updateProperties={c => editedContainerIdx !== null && updateContainerProperties(editedContainerIdx, c)}
          />
      </Sheet>
    </MediaLibraryProvider>
  )
}

