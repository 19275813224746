import { Add } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { openFileUploader } from '../../common/FileUpload';
import { FreeSpace, Header, Sheet, Title } from '../../common/Forms';
import { useMediaLibraryData } from './useMediaLibraryData';
import { SearchField } from '../../common/SearchField';
import { MediaLibraryItems } from './MediaLibraryItems';

export const MediaLibraryForm = () => {
  const data = useMediaLibraryData();

  const addFile = () => {
    openFileUploader(f => data.uploadFile(f));
  }

  return (
    <Sheet>
      <Header>
        <Title>Media library</Title>
        <FreeSpace />
        {data.isLoading && <CircularProgress />}
        <SearchField
          value={data.filter}
          setValue={data.setFilter}
          autoFocus
          />
        <IconButton size="small" onClick={addFile}><Add /></IconButton>
      </Header>

      <MediaLibraryItems data={data} />
    </Sheet>
  );
}
