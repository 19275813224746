import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useLabelsEditors } from './Labels';
import { useProgramsList } from '../../programs/useProgramsList';
import { Select } from '../../../common/Select';
import { Checkbox } from '../../../common/Checkbox';
import { TextField } from '@mui/material';


export const ProgramComponent = (props: PageComponentProps) => {
  const { labelControl } = useLabelsEditors(props);

  const availablePrograms = useProgramsList();

  return (
    <FormGrid columns="repeat(2, 1fr)">
      <Select
        label="Program"
        values={availablePrograms.items.map(p => ([p.program_id, p.title]))}
        value={props.component.properties?.program_id}
        onChange={e => props.data.updateComponentProperties({ program_id: e.target.value })}
        />
      <div />

      {labelControl(
        "date_format",
        "Date format",
        "dd MMM")}
      {labelControl(
        "time_format",
        "Time format",
        "HH:mm")}
      {labelControl(
        "show_more",
        "Show more label",
        "show more")}

      {labelControl(
        "tag_color",
        "Tag color (allowed: accent, default)",
        "default")}

      <TextField
        label="Item border width"
        value={props.component?.properties?.items_border_width || ""}
        onChange={e => props.data.updateComponentProperties({ items_border_width: e.target.value })}
        />
      <TextField
        label="Item left border width"
        value={props.component?.properties?.items_left_border_width || ""}
        onChange={e => props.data.updateComponentProperties({ items_left_border_width: e.target.value })}
        />
      <Checkbox
        label="Item shadow"
        checked={props.component?.properties?.items_shadow || false}
        onChange={v => props.data.updateComponentProperties({ items_shadow: v })}
        />

      <Checkbox
        label="Expand subitems by default"
        checked={props.component?.properties?.expand_by_default || false}
        onChange={v => props.data.updateComponentProperties({ expand_by_default: v })}
        />
      
    </FormGrid>
  );
}
