import React, { ReactNode } from 'react';
import { SelectProps, Select as SelectMUI, FormControl, InputLabel, MenuItem } from '@mui/material';

interface Props extends SelectProps {
  values: [any, ReactNode][];
}

export const Select = (props: Props) => {
  return (
    <FormControl id={props.id}>
      {props.label && <InputLabel>{props.label}</InputLabel>}
      <SelectMUI {...props}>
          {props.values.map(([value, label]) => <MenuItem key={value} value={value}>{label}</MenuItem>)}
      </SelectMUI>
    </FormControl>
  );
}
