import { AppBar, Box, Toolbar, Button, ListItem, ListItemIcon, List, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useUserContext } from '../auth/UserContext';
import { EventPicker, useEventContext } from "../events/EventContext";
import LoginIcon from '@mui/icons-material/Login';
import { Launch, Logout } from '@mui/icons-material';



interface LogoutT {
  logout: () => void;
}




 const TopNavBar =  ({logout}: LogoutT) => {
  const { currentUser } = useUserContext();
  const { currentEvent } = useEventContext();

  return (
        <AppBar 
                style={{
                position: "fixed",
                backgroundColor: "black",
                width: '100%',
                zIndex:1400
                }}>
            <Toolbar style={{
                  display: "flex",
                  justifyContent: "space-between"
                  }}>
                <Link to={'/'}>
                  <Box 
                    style={{
                    width: 150,
                    padding: 20
                    }}
                    component= "img"
                    src="https://images.squarespace-cdn.com/content/v1/5e709e7c2debae041edb1a30/1585149305921-CH2OBIBBBBBK7FATBO43/PREACT-Luzern-Logo.png?format=1500w"
                    >
                    </Box>
                </Link>

                {currentUser ?
                  <List style={{display: 'flex', justifyContent: "flex-end"}}>
                    <ListItem key="event-picker">
                      <EventPicker />
                      {currentEvent && <Tooltip title="Open the site">
                        <Button sx={{ color: "#fff" }} target="_blank" href={currentEvent? `https://${currentEvent.name}` : ""}>
                          <Launch />
                        </Button>
                      </Tooltip>}
                    </ListItem>
                    
                    <ListItem button component={Link} to="/login" key="Logout">
                      <ListItemIcon>
                        <Tooltip title="Logout">
                      <Button sx={{ color: "#fff" }} onClick={e => logout()}>
                        <Logout />
                      </Button>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItem>
                  </List>
            
                  :
                  <List>
          
                      <ListItem button component={Link} to="/login" key="Login" >
                          <ListItemIcon >
                            <LoginIcon style={{
                            fill: "#fff"
                            }}/>
                          </ListItemIcon>
                        </ListItem>
                    </List>
          
                }
          
          </Toolbar>
        </AppBar>
      
  
  );
};

export default TopNavBar

