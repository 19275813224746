import SwissConfApi from "../../api/ApiHelper"
import { NewPage, Page } from "../../api/types";
import { useEventContext } from "../EventContext"
import {ListData, useLoadedList} from "./useLoadedList"

export interface PageListData extends ListData<Page> {
  create: (data: NewPage) => Promise<Page>;
  remove: (id: number) => void;
  clone: (sourceId: number, data: NewPage) => Promise<Page>;
}

export const usePageList = (): PageListData => {
  const {currentEvent} = useEventContext();
  const data = useLoadedList <Page, number> ({
    keys: currentEvent?.id || -1,
    load: eventId => SwissConfApi.getAllPages(eventId),
    noLoad: !currentEvent,
  });

  const create = (data: NewPage) => {
    if(!currentEvent?.id) {
      throw new Error("no event");
    }

    return SwissConfApi.createPage(currentEvent.id, data);
  }

  const remove = (id: number) => {
    if(!currentEvent?.id) {
      throw new Error("no event");
    }

    SwissConfApi.deletePage(currentEvent.id, id)
      .then(() => data.reload());
  }

  const clone = async (sourceId: number, { slug, title }: NewPage) => {
    if(!currentEvent?.id) {
      throw new Error("no event");
    }

    const pageWithContent = await SwissConfApi.getPage(currentEvent.id, sourceId);

    return SwissConfApi.createPage(
      currentEvent.id, {
        slug,
        title,
        content: pageWithContent.content
    });

  }

  return {
    ...data,
    create,
    remove,
    clone,
  }
}
