import { useState } from 'react';
import styled from "@emotion/styled";
import { DeleteOutlined, FileDownloadOutlined, LinkOutlined } from '@mui/icons-material';
import { IconButton, Typography, Theme, Snackbar, Alert } from '@mui/material';
import { createFileDropHandler } from '../../common/FileUpload';
import { FileInfo, MediaLibraryData } from './useMediaLibraryData';
import { format } from 'date-fns';
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import { EmptyState } from '../../common/EmptyState';

const FileList = styled.ul<{ isDragTarget?: boolean, disableActions?: boolean }>`
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  gap: 12px;

  border-radius: 4px;
  border: ${props => props.isDragTarget ? `1px dotted ${(props.theme as Theme).palette.primary.main}` : "none"};
  background: ${props => props.isDragTarget ? `${(props.theme as Theme).palette.primary.main}40` : "transparent"};
  min-height: 60vh;

  & > li {
    width: 150px;
    height: 150px;
    position: relative;
    max-height: 150px;
    box-shadow: 0 0 10px -3px #cccccc;
    overflow: hidden;

    & .img-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      
      & img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    }

    & .placeholder {
      width: 100%;
      height: 100%;
      text-align: center;
      background: #eeeeee;
      display: flex;
      flex-flow: column;
      justify-content: center;

      & p {
        text-transform: uppercase;
      }
    }

    & .info, & .actions {
      padding: 0.25rem;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: #ffffffe0;

    }

    & .info {
      bottom: 0;
      
      & span {
        display: block;
        line-height: 1.1;
  
        &.uploaded {
          color: ${props => (props.theme as Theme).palette.grey[500]};
        }
      }
    }

    & .actions {
      display: none;
      justify-content: flex-end;

      & button {
      }
    }

    &:hover {
      background: ${props => (props.theme as Theme).palette.primary.main}60;
      box-shadow: 0 0 10px -4px ${props => (props.theme as Theme).palette.primary.main};
      & .actions {
        display: ${props => props.disableActions ? "none" : "flex"};
      }
      & .info {
        display: ${props => props.disableActions ? "block" : "none"};
      }
    }
  }
`;

const formatTime = (dt: string) => format(new Date(dt), "dd/MM/yyyy, hh:mm");

interface Props {
  data: MediaLibraryData;
  onItemClick?: (fi: FileInfo) => void;
  disableActions?: boolean;
  disableUpload?: boolean;
}

export const MediaLibraryItems = (props: Props) => {
  const { data } = props;

  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
  const [fiToRemove, setFiToRemove] = useState<FileInfo | null>(null);

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsSnackbarOpen(true);
}

  return (
    <>
      <FileList
        disableActions={props.disableActions}
        isDragTarget={!props.disableUpload && isDragTarget}
        onDragEnter={() => setIsDragTarget(true)}
        onDragLeave={() => setIsDragTarget(false)}
        onDrop={props.disableUpload ? undefined : createFileDropHandler(f => { setIsDragTarget(false); data.uploadFile(f); })}
        onDragOver={(e: any) => { e.preventDefault(); }}>
        {data.items.map(fi => (
          <li
            key={fi.id}
            onClick={props.onItemClick ? () => props.onItemClick && props.onItemClick(fi) : undefined}
            style={{ cursor: props.onItemClick ? "pointer" : "default"}}>
            <div className="img-container">
              {fi.content_type.startsWith("image")
                ? <img src={data.getFilePath(fi)} alt={fi.filename} />
                : <div className="placeholder"><Typography>{data.getFileType(fi)}</Typography></div>}
            </div>
            <div key="actions" className="actions">
              <IconButton key="url" size="small" onClick={() => copyText(data.getFileUrl(fi))}>
                <LinkOutlined />
              </IconButton>
              <IconButton href={data.getFilePath(fi)} target="_blank" key="download" size="small">
                <FileDownloadOutlined />
              </IconButton>
              <IconButton key="delete" size="small" onClick={() => setFiToRemove(fi)}>
                <DeleteOutlined />
              </IconButton>
            </div>
            <div key="info" className="info">
              <Typography variant="caption" key="filename" className="filename">{fi.filename}</Typography>
              <Typography variant="caption" key="uploaded" className="uploaded">{formatTime(fi.uploaded_at)}</Typography>
            </div>
          </li>))}

        {data.items.length === 0 && (
          <EmptyState>
            {data.filter.length > 0 && <Typography key="1">Couldn't find any matching media files</Typography>}
            {!data.filter && props.disableUpload && <Typography key="2">There are no files in the media library</Typography>}
            {!data.filter && !props.disableUpload && <Typography key="2">There are no files in the media library yet. Drop one here to upload.</Typography>}
          </EmptyState>
        )}
      </FileList>


      <Snackbar
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        
        open={isSnackbarOpen}
        onClose={() => setIsSnackbarOpen(false)}
        autoHideDuration={5000}>
          <Alert severity="success">Link copied to clipboard</Alert>
      </Snackbar>

      <ConfirmationDialog
        title="Delete file"
        isOpen={!!fiToRemove}
        close={() => setFiToRemove(null)}
        action={() => {
          if(fiToRemove) {
            return data.removeFile(fiToRemove);
          } else {
            return Promise.resolve({});
          }
        }}
        confirmLabel="delete">
        {fiToRemove && <Typography>Delete {fiToRemove.filename}?</Typography>}
      </ConfirmationDialog>
    </>
  );
}
