import React from 'react';
import { PageComponentProps } from './types';
import { MediaItemPicker } from '../../media/MediaItemPicker';


export const ImageComponent = (props: PageComponentProps) => {
  const { component, containerIdx, componentIdx, dataRaw: data } = props;
  const image_url = component.properties?.image_url;
  return (
    <MediaItemPicker
      selectedUrl={image_url}
      setSelectedUrl={v => data.updateComponentProperties(containerIdx, componentIdx, { image_url: v })}
      />
  );
}
