import { Component } from "../../../api/types";
import { ActionsComponent } from "./ActionsComponent";
import { AgendaComponent } from "./AgendaComponent";
import { ContactFormComponent } from "./ContactFormComponent";
import { HtmlComponent } from "./HtmlComponent";
import { ImageComponent } from "./ImageComponent";
import { LoginComponent } from "./LoginComponent";
import { MeetingBookingComponent } from "./MeetingBookingComponent";
import { ProgramComponent } from "./ProgramComponent";
import { QuestionsComponent } from "./QuestionsComponent";
import { RegistrationComponent } from "./RegistrationComponent";
import { RichTextComponent } from "./RichTextComponent";
import { SponsorsComponent } from "./SponsorsComponent";
import { PageComponentType } from "./types";
import { Unknown } from "./Unknown";
export * from "./Common";

export const PageComponents: Record<Component["component_type"] | "unknown", PageComponentType> = {
  html: HtmlComponent,
  image: ImageComponent,
  richtext: RichTextComponent,
  questions: QuestionsComponent,
  contactform: ContactFormComponent,
  sponsors: SponsorsComponent,
  agenda: AgendaComponent,
  actions: ActionsComponent,
  login: LoginComponent,
  registration: RegistrationComponent,
  meeting_booking: MeetingBookingComponent,
  program: ProgramComponent,
  empty: Unknown,
  unknown: Unknown,
}

export const getPageComponentType = (type: string) => PageComponents[type as Component["component_type"]] || PageComponents.unknown;
