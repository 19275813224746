import styled from '@emotion/styled';
import { AddOutlined } from '@mui/icons-material';
import { IconButton, Typography, Theme } from '@mui/material';
import React, { useState } from 'react';
import { Component } from '../../../api/types';
import { useEventContext } from '../../EventContext';
import { Options } from './Options';


const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  align-items: center;
`;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  gap: 0.5rem;

  & > div {
    cursor: pointer;
    width: 90px;
    height: 90px;
    background: ${props => (props.theme as Theme).palette.background.paper};
    box-shadow: 0 0 5px -3px ${props => (props.theme as Theme).palette.grey[600]};

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & p {
      line-height: 1.1;
    }

    &:hover {
      color: ${props => (props.theme as Theme).palette.primary.main};
      box-shadow: 0 0 5px -3px ${props => (props.theme as Theme).palette.primary.main};
    }
  }
`;


interface Props {
  addComponent: (componentType: Component["component_type"]) => void;
}

export const AddComponentButton = ({ addComponent }: Props) => {
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const doAddComponent = (type: Component["component_type"]) => {
    addComponent(type);
    setIsSelecting(false);
  }

  const eventContext = useEventContext();

  return !isSelecting ?
    <ButtonWrapper>
      <IconButton onClick={() => setIsSelecting(true)}><AddOutlined /></IconButton>
    </ButtonWrapper>
    :
    <OptionsWrapper>
      {Options.filter(([_,__,___, availabilityCheck]) => !availabilityCheck || availabilityCheck(eventContext)).map(([type, label, icon]) => (
        <div onClick={() => doAddComponent(type)}>
          {icon}
          <Typography>{label}</Typography>
        </div>
      ))}
    </OptionsWrapper>;
}
