import { useState } from "react";
import SwissConfApi from "../../api/ApiHelper";
import { ContactForm, Message } from "../../api/types";
import { ListData, useLoadedList } from "../Pages/useLoadedList";
import ExcelJS from "exceljs";
import { saveFile } from "../../api/files";
import { toLocal } from "../../common/dateUtil";

export type Views = "all" | "processed" | "unprocessed";

export interface ContactFormMessagesData extends ListData<Message> {
  update: (id: number, changes: Pick<Message, "is_processed">) => void;
  view: Views;
  setView: (v: Views) => void;
  exportMessages: (formConfig: ContactForm) => void;
}

const exportMessages = (formConfig: ContactForm, messages: Message[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet(formConfig.form_name);
  sheet.columns = [
    ...formConfig.fields.map(({ field, label }) => ({ header: label, key: field })),
    { header: "Created at", key: "created_datetime" },
  ];

  messages.forEach(m => sheet.addRow({ ...m.fields, created_datetime: toLocal(m.created_datetime) }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `${formConfig.form_name}.xlsx`));

}

export const useContactFormMessages = (eventId: number, formName: string): ContactFormMessagesData => {
  const [view, setView] = useState<Views>("unprocessed");

  const data = useLoadedList<Message, [number, string, Views]>({
    keys: [eventId, formName, view],
    noLoad: eventId < 0 || !formName,
    load: ([eventId, formName, view]) => SwissConfApi.request(`/events/${eventId}/contact-forms/${formName}/message?view=${view}`),
  });

  const update = (id: number, changes: Pick<Message, "is_processed">) => {
    if(eventId < 0 || !formName) {
      throw new Error("no event or no form");
    }

    SwissConfApi.request(`/events/${eventId}/contact-forms/${formName}/message/${id}`, changes, "patch")
      .then(() => data.reload());
  }

  return {
    ...data,
    update,
    view,
    setView,
    exportMessages: config => exportMessages(config, data.items),
  };
}