import SwissConfApi from "../../api/ApiHelper";
import { ContactForm, Field } from "../../api/types";
import { EditData, useEditData } from "../../common/useEditData";
import { useEventContext } from "../EventContext";
import { ContactFormMessagesData, useContactFormMessages } from "./useContactFormMessages";

export interface EditContactFormData extends EditData<ContactForm> {
  updateField: (fieldIdx: number, changes: Partial<Field>) => void;
  removeField: (fieldIdx: number) => void;
  addField: () => void;

  messages: ContactFormMessagesData;
}


export interface EditFieldsData {
  fields: Field[];
  addField: () => void;
  removeField: (idx: number) => void;
  updateField: (idx: number, changes: Partial<Field>) => void; 
}

export const useEditFields = (fields: Field[], update: (updated: Field[]) => void): EditFieldsData => {
  const addField = () => {
    const existing = (fields || []);
    update([
      ...existing,
      { field: `field_${existing.length + 1}`, label: `Field ${existing.length + 1}`, field_type: "text", required: false }
    ]);
  }

  const updateField = (fieldIdx: number, changes: Partial<Field>) => {
    const existing = (fields || []);
    update(existing.map((f,i) => i === fieldIdx ? { ...f, ...changes} : f));

  }

  const removeField = (fieldIdx: number) => {
    const existing = (fields || []);
    update(existing.filter((_,i) => i !== fieldIdx));
  }

  return {
    fields,
    addField,
    updateField,
    removeField,
  }
}


export const useEditContactForm = (name: string): EditContactFormData => {
  const { currentEvent } = useEventContext();
  const data = useEditData<ContactForm, [number, string]>({
    keys: [currentEvent?.id || -1, name],
    noLoad: !currentEvent,
    dflt: { } as ContactForm,
    load: ([eventId, name]) => SwissConfApi.request(`/events/${eventId}/contact-forms/${name}`, undefined, "get"),
    saveChanges: ([eventId, name], changes) => SwissConfApi.request(`/events/${eventId}/contact-forms/${name}`, changes, "patch"),
  });

  const messages = useContactFormMessages(currentEvent?.id || -1, name);

  const editFields = useEditFields(data.data.fields, updated => data.update({ fields: updated }));

  return {
    ...data,
    ...editFields,
    messages,
  }
} 