import React from 'react';
import { FormControl, InputLabel, Input, InputAdornment, IconButton } from '@mui/material';
import { Close, Search } from '@mui/icons-material';

interface Props {
  autoFocus?: boolean;
  label?: string;
  value: string;
  setValue: (v: string) => void;
}

export const SearchField = ({ value, setValue, label, autoFocus }: Props) => {
  return (
    <FormControl>
      {label && <InputLabel htmlFor={`search-${label}`}>{label}</InputLabel>}
      <Input
        autoFocus={autoFocus}
        type="text"
        value={value || ""}
        onChange={e => setValue(e.target.value)}
        startAdornment={(
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton size="small" onClick={() => setValue("")}>
              <Close />
            </IconButton>
          </InputAdornment>
        )}
        />
    </FormControl>
  );
}
