import SwissConfApi from "../api/ApiHelper";
import { AttendanceTrackingSettings } from "../api/types";
import { useLoadedList } from "./Pages/useLoadedList";
import { EditEventData } from "./useEditEvent";
import ExcelJS from "exceljs";
import { saveFile } from "../api/files";
import { formatDate } from "../common/dateUtil";
import { useState } from "react";

interface AttendanceStatK {
  page_slug: string;
  email: string;
}

type AttendanceStat = AttendanceStatK & Record<string, string>;


const exportAttendance = (stats: AttendanceStat[], dates: string[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Attendees');
  sheet.columns = [
    { header: "Email", key: "email", width: 24 },
    { header: "Page", key: "page_slug", width: 24 },
    ...(dates || []).map(d => ({ header: formatDate(d), key: d })),
  ];

  stats.forEach(a => sheet.addRow(a));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "attendance.xlsx"));
}



export const useAttendanceTracking = (eventData: EditEventData) => {
  const isEnabled = eventData.data.is_attendance_tracking_enabled;

  const [filter, setFilter] = useState<Partial<AttendanceStatK>>({});

  const stats = useLoadedList<AttendanceStat, number>({
    keys: eventData.data.id,
    load: (eventId) => SwissConfApi.request(`/events/${eventId}/attendance`),
    noLoad: !isEnabled,
  });

  const enable = () => {
    eventData.update({ is_attendance_tracking_enabled: true });
  }

  const disable = () => {
    eventData.update({ is_attendance_tracking_enabled: false });
  }

  const settings = eventData.data.attendance_tracking_settings || {};

  const updateSettings = (changes: Partial<AttendanceTrackingSettings>) => {
    eventData.update({ attendance_tracking_settings: { ...settings, ...changes }});
  }

  const statsDates = stats.items.reduce((r,attendance) => {
    Object.keys(attendance).forEach(f => {
      if(!r.includes(f)) {
        r.push(f);
      }
    });
    return r;
  },[] as string[])
  .filter(f => /\d\d\d\d-\d\d-\d\d/.test(f))
  .sort();

  return {
    isEnabled,
    enable,
    disable,
    settings,
    updateSettings,
    stats: {
      ...stats,
      items: stats.items.filter(a => (!filter.email || a.email === filter.email) && (!filter.page_slug || a.page_slug === filter.page_slug))
    },
    statsDates,
    exportStats: () => exportAttendance(stats.items, statsDates),

    filter,
    setFilter,
  }
}
