import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react';
import { FormGrid } from '../../../common/Forms';
import { Block } from '../types';

interface Props {
  addBlock: (b: Block) => Promise<any>;
  block: Block | null;
  update: (changes: Partial<Block>) => void;
  close: () => void;
  isOpen: boolean;
  isAdding: boolean;
}

export const AddBlockDialog = (props: Props) => {
  return (
    <Dialog open={props.isOpen} onClose={() => props.close()} fullWidth maxWidth="sm">
      <DialogTitle>Block</DialogTitle>

      <DialogContent>
        {props.block && <FormGrid columns="2fr 1fr 1fr" style={{ alignItems: "end", margin: "0.5rem 0" }}>
          <TextField label="Date" value={props.block.date} onChange={(e)=> props.update({ date: e.target.value })} />
          <TextField label="Start time" value={props.block.start_time} onChange={(e)=> props.update({ start_time: e.target.value })} />
          <TextField label="End time" value={props.block.end_time} onChange={(e)=> props.update({ end_time: e.target.value })} />
        </FormGrid>}
      </DialogContent>

      <DialogActions>
        <Button onClick={() => props.close()}>cancel</Button>
        <Button color="primary" disabled={props.isAdding} onClick={() => props.block && props.addBlock(props.block).then(() => props.close())}>save</Button>
      </DialogActions>
    </Dialog>
  );
}
