import SwissConfApi from "../../api/ApiHelper";
import { useEventContext } from "../EventContext";
import { ListData, useLoadedList } from "../Pages/useLoadedList";
import ExcelJS from "exceljs";
import { saveFile } from "../../api/files";
import { toLocal } from "../../common/dateUtil";
import { AttendeeUser } from "../../api/types/attendees";
import { useState } from "react";
import { DefaultRegistrationFields, Field } from "../../api/types";


const exportAttendees = (attendees: AttendeeUser[], extraFields: Field[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Attendees');
  sheet.columns = [
    { header: "Email", key: "email", width: 24 },
    { header: "First name", key: "first_name", width: 24 },
    { header: "Last name", key: "last_name", width: 24 },
    { header: "Approved?", key: "approved", width: 24 },
    { header: "Registered at", key: "created_datetime", width: 24 },
    ...(extraFields || []).map(f => ({ header: f.label, key: f.field })),
  ];

  attendees.forEach(a => sheet.addRow({
    ...a,
    approved: a.is_approved ? "yes" : "no",
    created_datetime: toLocal(a.created_datetime),
    ...(extraFields || []).reduce((r,f) => ({ ...r, [f.field]: (a.fields || {})[f.field] }), {})
  }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "attendees.xlsx"));
}

export interface AttendeeListData extends ListData<AttendeeUser> {
  update: (email: string, changes: Partial<AttendeeUser>) => void;
  create: (attendees: Partial<AttendeeUser>[]) => Promise<any>;
  exportAttendees: () => void;
  filter: string;
  setFilter: (v: string) => void;
  extraFields: Field[];
}

export const useAttendeeList = (): AttendeeListData => {
  const { currentEvent } = useEventContext();
  const [filter, setFilter] = useState<string>("");

  const data = useLoadedList<AttendeeUser, number>({
    keys: currentEvent?.id || -1,
    noLoad: !currentEvent,
    load: (eventId) => SwissConfApi.request(`/events/${eventId}/attendee`),
  });

  const extraFields = (currentEvent?.registration_fields || [])
    .filter(f => !DefaultRegistrationFields.includes(f.field));

  const update = (email: string, changes: Partial<AttendeeUser>) => {
    if(!currentEvent) {
      throw new Error("no event");
    }

    SwissConfApi.request(`/events/${currentEvent.id}/attendee/${email}`, changes, "patch")
      .then(() => data.reload());
  }

  const create = (attendees: Partial<AttendeeUser>[]) => {
    if(!currentEvent) {
      throw new Error("no event");
    }

    return SwissConfApi.request(`/events/${currentEvent.id}/attendee/batch`, attendees, "post")
      .then(() => data.reload());
  }

  
  const attendeeStr = (u: AttendeeUser) => `${u.email} ${u.first_name} ${u.last_name}`;
  const attendees = filter.length ? data.items.filter(a => attendeeStr(a).toLowerCase().includes(filter.toLowerCase())) : data.items

  return {
    ...data,
    items: attendees,
    update,
    create,
    exportAttendees: () => exportAttendees(data.items, extraFields),
    filter,
    setFilter,
    extraFields,
  };
}
