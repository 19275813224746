import { add, format, parse } from 'date-fns'

export type DateTimeT = Date | string | number;

export const toLocal = (dt: DateTimeT) => {
  return add(new Date(dt), { minutes: -new Date().getTimezoneOffset() });
}

const DateTimeShortFormat = "dd MMM yyyy, HH:mm"
export const formatDateTime = (dt: DateTimeT) => {
  return format(new Date(dt), DateTimeShortFormat);
}

const DateFormat = "d MMM yy";
export const formatDate = (dt: DateTimeT) => format(new Date(dt), DateFormat);

const DateInternalFormat = "yyyy-LL-dd"
export const formatDateInternal = (dt: DateTimeT) => format(new Date(dt), DateInternalFormat);
export const parseDateInternal = (dt: string) => parse(dt, DateInternalFormat, new Date());

const DateWithDowFormat = "d MMM yyyy, EEE";
export const formatDateWithDow = (dt: DateTimeT) => format(new Date(dt), DateWithDowFormat);


export const DateTimeInternalSubIsoFormat = "yyyy-MM-dd HH:mm:ss.000";
