import React, { ReactNode, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { Component, Page } from '../../api/types';
import { FormGrid } from '../../common/Forms';
import { ArrowRightAlt } from '@mui/icons-material';
import { Select } from '../../common/Select';

interface Props {
  source: { containerIdx: number, componentIdx: number, component: Component } | null;
  cancel: () => void;
  page: Page;
  placeComponent: (sourceContainer: number, sourceComponent: number, targetContainer: number, targetComponent: number) => void;
}

export const PlaceComponentDialog = (props: Props) => {
  const { source, cancel, page, placeComponent } = props;
  const [target, setTarget] = useState<{ container: number, component: number }>({ container: 0, component: 0 });

  useEffect(() => {
    if(source) {
      setTarget({ container: source.containerIdx, component: source.componentIdx})
    }
  }, [source]);

  const doCancel = () => {
    setTarget({ container: 0, component: 0 });
    cancel();
  }

  const doPlace = () => {
    if(!source) {
      return;
    }

    placeComponent(source.containerIdx, source.componentIdx, target.container, target.component);
    doCancel();
  }

  const targetComponents = page.content[target.container]?.components || [];

  return (
    <Dialog open={!!source} onClose={doCancel}>
      <DialogTitle>Move component</DialogTitle>

      <DialogContent>
        {source && (
          <FormGrid columns="1fr max-content 1fr" style={{ alignItems: "center" }}>
            <TextField
              key="source_container"
              label="From container"
              value={source.containerIdx+1}
              />
            <ArrowRightAlt color="disabled" key="container_right" />
            <Select
              key="target_container"
              label="To container"
              value={target.container}
              values={page.content.map((_,contIdx) => [contIdx, (contIdx+1).toString()])}
              onChange={e => setTarget({ ...target, container: (e.target.value as number) })}
              />
            
            <TextField
              key="source_component"
              label="From position"
              value={`${source.componentIdx+1} (${source.component.component_type})`}
              />
            <ArrowRightAlt color="disabled" key="component_right" />
            <Select
              key="target_component"
              label="To position"
              value={target.component}
              values={[...targetComponents.map((_,compIdx) => [compIdx, (compIdx+1).toString()]), [targetComponents.length, (targetComponents.length + 1).toString()]] as [any, ReactNode][]}
              onChange={e => setTarget({ ...target, component: (e.target.value as number) })}
              />

          </FormGrid>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={doCancel}>cancel</Button>
        <Button color="primary" onClick={doPlace}>move</Button>
      </DialogActions>
    </Dialog>
  );
}
