import SwissConfApi from "../../api/ApiHelper";
import { Styles } from "../../api/types";
import { useEditData } from "../../common/useEditData"
import { useEventContext } from "../EventContext"

const getOrCreateStyles = (eventId: number) => {
  const defaultStyles = {
    name: "default",
    primary_color: "#0000bb",
    highlight_color: "#2200ee",
    text_color: "#333366",
    font: "Roboto",
  };
  return SwissConfApi.getStyles(eventId)
    .then(r => {
      if(Object.keys(r).length === 0) {
        return SwissConfApi.createStyles(eventId, defaultStyles);
      }
      return r;
    })
    .catch(e => SwissConfApi.createStyles(eventId, defaultStyles));
}

export const useStylesEdit = () => {
  const { currentEvent } = useEventContext();

  return useEditData<Styles, number>({
    keys: currentEvent?.id || -1,
    noLoad: !currentEvent,
    dflt: {} as unknown as Styles,
    load: (eventId) => getOrCreateStyles(eventId),
    saveChanges: (eventId, changes) => SwissConfApi.updateStyles(eventId, changes),
  });
}
