import React from 'react';
import { FormControlLabel, Checkbox as MUICheckbox, FormControlLabelProps } from '@mui/material';

interface Props extends Omit<FormControlLabelProps, "checked" | "onChange" | "control"> {
  checked: boolean | undefined;
  onChange: (v: boolean) => void;
}

export const Checkbox = (props: Props) => {
  const { checked, onChange, ...otherProps } = props;
  return (
    <FormControlLabel
      {...otherProps}
      control={(
        <MUICheckbox
          checked={checked || false}
          onChange={e => onChange(e.target.checked || false)}
          />
        )}
      />
  );
}
