import SwissConfApi from "../../api/ApiHelper";
import { useEventContext } from "../EventContext";
import { EditData, useEditData } from "../../common/useEditData";
import { Component, Container, ContainerProperties, Layout, Page } from "../../api/types";
import { moveItem } from "../../common/arrayUtil";
import { generateCode } from "../../common/helpers";

type Direction = "up" | "down";

const ensureIds = <T extends { _id?: string },>(items: T[]): T[] => items.map(item => item?._id ? item : ({ ...item, _id: generateCode() }));

export interface PageData extends EditData<Page> {
  page : Page;
  addContainer: () => void;
  updateContainer: (containerIdx: number, changes: Partial<Container>) => void;
  updateContainerLayout: (containerIdx: number, changes: Partial<Layout>) => void;
  updateContainerProperties: (containerIdx: number, changes: Partial<ContainerProperties>) => void;
  removeContainer: (containerIdx: number) => void;
  moveContainer: (containerIdx: number, direction: Direction) => void;

  addComponent: (containerIdx: number, component_type: Component["component_type"]) => void;
  updateComponentContent: (containerIdx: number, componentIdx: number, content: string) => void;
  updateComponentProperties: (containerIdx: number, componentIdx: number, changes: Record<string, any>) => void;
  removeComponent: (containerIdx: number, componentIdx: number) => void;
  moveComponent: (containerIdx: number, componentIdx: number, direction: Direction) => void;
  placeComponent: (sourceContainerIdx: number, sourceComponentIdx: number, targetContainerIdx: number, targetComponentIdx: number) => void;
}

export const useEditPage = (id : number) : PageData => {
  const {currentEvent} = useEventContext();
  const data = useEditData<Page, { event_id: number, id: number }>({
    dflt: { id, event_id: currentEvent?.id || -1, content:[] } as unknown as Page,
    keys: { event_id: currentEvent?.id || -1, id },
    noLoad: !currentEvent,
    load: ({ event_id, id}) => SwissConfApi.getPage(event_id, id),
    saveChanges: ({ event_id, id}, changes) => SwissConfApi.updatePage(event_id, id, changes),
  });

  const addContainer = () => {
    const container = { _id: generateCode(), components: [], layout: { columns: "1fr"} };
    data.update({ content: [...ensureIds(data.data.content), container]});
  }

  const updateContainer = (containerIdx: number, changes: Partial<Container>) => {
    data.update({ content: data.data.content.map((c, cid) => cid === containerIdx
      ? { ...c, ...changes }
      : c)
    });
  }

  const updateContainerLayout = (containerIdx: number, changes: Partial<Layout>) => {
    data.update({ content: data.data.content.map((c, cid) => cid === containerIdx
      ? { ...c, layout: { ...c.layout, ...changes }}
      : c)
    });
  }

  const updateContainerProperties = (containerIdx: number, changes: Partial<ContainerProperties>) => {
    data.update({ content: data.data.content.map((c, cid) => cid === containerIdx
      ? { ...c, properties: { ...(c.properties || {}), ...changes }}
      : c)
    });
  }

  const removeContainer = (containerIdx: number) => {
    data.update({ content: data.data.content.filter((c,cidx) => cidx !== containerIdx)});
  }

  const moveContainer = (containerIdx: number, direction: Direction) => {
    data.update({ content: moveItem(data.data.content, containerIdx, direction === "down" ? 1 : -1) });
  }


  const addComponent = (containerIdx: number, component_type: Component["component_type"]) => {
    const component = { component_type, _id: generateCode() };
    data.update({
      content: data.data.content.map((container, cid) => cid === containerIdx
        ? { ...container, components: [...ensureIds(container.components), component]}
        : container)
    });
  }

  const updateComponentContent = (containerIdx: number, componentIdx: number, content: string) => {
    data.update({
      content: data.data.content.map((container, contId) => contId === containerIdx
        ? { ...container, components: container.components.map((component, compId) => compId === componentIdx
          ? { ...component, content }
          : component )}
        : container)
    });
  }

  const updateComponentProperties = (containerIdx: number, componentIdx: number, changes: Record<string, any>) => {
    data.update({
      content: data.data.content.map((container, contId) => contId === containerIdx
        ? { ...container, components: container.components.map((component, compId) => compId === componentIdx
          ? { ...component, properties: { ...(component.properties || {}), ...changes } }
          : component )}
        : container)
    });
  }

  const removeComponent = (containerIdx: number, componentIdx: number) => {
    data.update({
      content: data.data.content.map((container, contId) => contId === containerIdx
        ? { ...container, components: container.components.filter((component, compId) => compId !== componentIdx)}
        : container)
    });
  }

  const moveComponent = (containerIdx: number, componentIdx: number, direction: Direction) => {
    data.update({
      content: data.data.content.map((container, contId) => contId === containerIdx
        ? { ...container, components: moveItem(container.components, componentIdx, direction === "down" ? 1 : -1) }
        : container)
    });
  }

  const placeComponent = (srcContainerIdx: number, srcComponentIdx: number, trgContainerIdx: number, trgComponentIdx: number) => {
    if(srcContainerIdx === trgContainerIdx) {
      const sourceComponents = data.data.content[srcContainerIdx].components.slice();
      const moved = sourceComponents[srcComponentIdx];

      if(srcComponentIdx === trgComponentIdx) {
        return;
      } else if(srcComponentIdx < trgComponentIdx) {
        sourceComponents.splice(Math.min(trgComponentIdx+1, sourceComponents.length), 0, moved);
        sourceComponents.splice(srcComponentIdx, 1);
      } else {
        sourceComponents.splice(trgComponentIdx, 0, moved)
        sourceComponents.splice(srcComponentIdx+1, 1);
      }

      data.update({
        content: data.data.content.map((container, contId) => contId === srcContainerIdx
          ? { ...container, components: sourceComponents }
          : container)
      });
    } else {
      const sourceComponents = data.data.content[srcContainerIdx].components.slice();
      const targetComponents = data.data.content[trgContainerIdx].components.slice();
      const moved = sourceComponents[srcComponentIdx];
      sourceComponents.splice(srcComponentIdx, 1);
      targetComponents.splice(trgComponentIdx, 0, moved);

      data.update({
        content: data.data.content.map((container, contId) => contId === srcContainerIdx
          ? { ...container, components: sourceComponents }
          : contId === trgContainerIdx
            ? { ...container, components: targetComponents }
            : container)
      });
    }
  }

  return {
    ...data,
    page: data.data,

    addContainer,
    updateContainer,
    updateContainerLayout,
    updateContainerProperties,
    removeContainer,
    moveContainer,

    addComponent,
    updateComponentContent,
    updateComponentProperties,
    removeComponent,
    moveComponent,
    placeComponent,
  }
}