import React, { useContext }  from "react";
import { User as UserT } from "../api/types";

export type User = UserT;

export interface IUserContext {
    currentUser : null | UserT;
    logout: () => void;
    login: (token: string) => void;
}
export const UserContext = React.createContext<IUserContext>({ currentUser: null, logout: () => {}, login: () => {} });

export const useUserContext = () => useContext(UserContext);
