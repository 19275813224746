import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useLabelsEditors } from './Labels';


export const MeetingBookingComponent = (props: PageComponentProps) => {
  const { labelControl } = useLabelsEditors(props);

  return (
    <FormGrid columns="repeat(2, 1fr)">
      {labelControl("customer_email", "Email label", "Your Email")}
      {labelControl("customer_name", "Name label", "Your full name")}
      {labelControl("customer_company", "Company label", "Your Company")}
      {labelControl("customer_language", "Language label", "Preferred language")}

      {labelControl("sponsor", "Sponsor label", "Select sponsor")}
      <div />
      
      {labelControl("date", "Date label", "Select a date")}
      {labelControl("date_format", "Date format", "dd MMM")}
      {labelControl("time", "Time label", "Select time")}
      <div />
      
      {labelControl("send", "Button label", "Book")}
      {labelControl(
        "title",
        "Form title",
        "")}
      {labelControl(
        "message",
        "Message after time selection",
        "",
        { style: { gridColumn: "span 2"}, multiline: true})}

      {labelControl(
        "success",
        "Message after successfull booking",
        "Your meeting was successfully booked. You will get an email confirmation soon",
        { style: { gridColumn: "span 2"}, multiline: true })}
      {labelControl(
        "error",
        "Message on error",
        "Something went wrong. Please check the values you entered and try again.",
        { style: { gridColumn: "span 2"}, multiline: true})}
    </FormGrid>
  );
}
