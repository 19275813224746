import { Save } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Sheet, Header, FreeSpace, Title } from '../../common/Forms';
import { useEditContactForm } from './useEditContactForm';
import { TabPanel, TabsHeader, useTabsState } from '../../common/Tabs';
import { ContactFormMessagesList } from './ContactFormMessagesList';
import { FormFieldsEditor } from './FormFieldsEditor';
import { ContactFormEmailSettings } from './ContactFormEmailSettings';

interface Props {
  
}

export const ContactFormForm = (props: Props) => {
  const { name } = useParams<{ name: string }>();
  const formData = useEditContactForm(name || "");
  const {
    data: form,
    save,
    hasChanges,
    isLoading,
    addField,
    updateField,
    removeField,
    messages,
  } = formData;

  const tabs = useTabsState([
    ["config", "Fields"],
    ["notifications", "Notifications"],
    ["messages", "Messages"],
  ], "config", "tab");

  return (
    <Sheet>
      <Header>
        <Title>Contact form: {form.form_name}</Title>
        <FreeSpace />
        
        {isLoading && <CircularProgress />}
        {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
      </Header>
      
      <TabsHeader state={tabs} noMargin scrollable />

      <TabPanel state={tabs.forTab("config")}>
        <FormFieldsEditor
          fields={form.fields}
          addField={addField}
          updateField={updateField}
          removeField={removeField}
          />
      </TabPanel>

      <TabPanel state={tabs.forTab("notifications")}>
        <ContactFormEmailSettings data={formData} />
      </TabPanel>

      <TabPanel state={tabs.forTab("messages")}>
        <ContactFormMessagesList data={messages} config={form} />
      </TabPanel>
      
    </Sheet>
  );
}
