import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { CircularProgress, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link, DialogContent, DialogActions, Button, Dialog, DialogTitle, TextField } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { Sheet, Header, FreeSpace, Title } from '../../common/Forms';
import { useContactFormsList } from './useContactFormsList';
import { slugify } from '../../common/textUtil';


export const ContactFormsList = () => {
  const { items: forms, isLoading, create } = useContactFormsList();
  const [newForm, setNewForm] = useState<{ name: string } | null>(null);

  const startCreate = () => setNewForm({ name: "" });
  const cancelCreate = () => setNewForm(null);
  const doCreate = () => {
    if(newForm && newForm.name) {
      create(newForm.name);
      cancelCreate();
    }
  };

  return (
    <Sheet>
      <Header>
        <Title>Contact forms</Title>
        <FreeSpace />
        {isLoading && <CircularProgress />}
        <IconButton size="small" onClick={startCreate}><Add /></IconButton>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="name"> Name </TableCell>
              <TableCell key="fields"> Fields </TableCell>
              <TableCell key="responses"> Responses </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {forms.map(form => (
                <TableRow key={form.form_name}>
                  <TableCell key="name">
                    <Link to={`/contact-forms/${form.form_name}`} component={LinkRouter}>
                      {form.form_name}
                    </Link>
                  </TableCell>
                  <TableCell key="fields">
                    {(form.fields || []).map(f => f.label || f.field).join(", ")}
                  </TableCell>
                  <TableCell key="responses">

                  </TableCell>

                  <TableCell key="actions">
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center"}}>
                      
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Dialog open={!!newForm} onClose={cancelCreate}>
        <DialogTitle>Create new contact form</DialogTitle>

        <DialogContent>
          <TextField
            label="Form name"
            value={newForm?.name || ""}
            onChange={e => setNewForm({ ...(newForm || {}), name: slugify(e.target.value) })}
            helperText="Internal name of the form - you will use it when adding the form to a page. Must be unique"
            />
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={cancelCreate}>cancel</Button>
          <Button variant="contained" onClick={doCreate}>create</Button>
        </DialogActions>
      </Dialog>
    </Sheet>
  );
}
