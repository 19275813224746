import styled from "@emotion/styled";
import { Paper, Typography } from "@mui/material";

export const Sheet = styled(Paper)`
  padding: 0.5rem 1rem;
`;

Sheet.defaultProps = { elevation: 0 };

export const Header = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-bottom: 0.5rem;
  gap: 0.25rem;
`;

export const FreeSpace = styled.div`
  flex: 1 0 1px;
`;

export const FormGrid = styled.div<{ columns?: string }>`
  display: grid;
  gap: 0.25rem 0.5rem;
  grid-template-columns: ${props => props.columns || "1fr 1fr 1fr"};
`;

export const Title = styled(Typography)`
`;

Title.defaultProps = { variant: "h4" };

export const SectionTitle = styled(Typography)`
  padding-top: 1.5rem;
`;

SectionTitle.defaultProps = { variant: "h5" };