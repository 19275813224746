import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { CircularProgress, IconButton, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Link, DialogContent, DialogActions, Button, Dialog, DialogTitle, TextField } from '@mui/material';
import { Link as LinkRouter } from 'react-router-dom';
import { Sheet, Header, FreeSpace, Title } from '../../common/Forms';
import { useProgramsList } from './useProgramsList';
import { NewProgram } from '../../api/types';


export const ProgramsList = () => {
  const { items, isLoading, create } = useProgramsList();
  const [newProgram, setNewProgram] = useState<NewProgram | null>(null);

  const startCreate = () => setNewProgram({ title: "" });
  const cancelCreate = () => setNewProgram(null);
  const doCreate = () => {
    if(newProgram && newProgram.title) {
      create(newProgram);
      cancelCreate();
    }
  };

  return (
    <Sheet>
      <Header>
        <Title>Programs</Title>
        <FreeSpace />
        {isLoading && <CircularProgress />}
        <IconButton size="small" onClick={startCreate}><Add /></IconButton>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell> ID </TableCell>
              <TableCell> Title </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(program => (
                <TableRow key={program.program_id}>
                  <TableCell>
                    <Link to={`/programs/${program.program_id}`} component={LinkRouter}>
                      {program.program_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link to={`/programs/${program.program_id}`} component={LinkRouter}>
                      {program.title}
                    </Link>
                  </TableCell>
                  
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      <Dialog open={!!newProgram} onClose={cancelCreate}>
        <DialogTitle>Create new program</DialogTitle>

        <DialogContent>
          <TextField
            label="Title"
            value={newProgram?.title || ""}
            onChange={e => setNewProgram({ ...(newProgram || {}), title: e.target.value })}
            />
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={cancelCreate}>cancel</Button>
          <Button variant="contained" onClick={doCreate}>create</Button>
        </DialogActions>
      </Dialog>
    </Sheet>
  );
}
