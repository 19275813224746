import { add } from "date-fns";
import { formatDateInternal, parseDateInternal } from "../../../common/dateUtil";
import { generateCode } from "../../../common/helpers";
import { BookingDate } from "../types";

interface EditDataProps {
  dates: BookingDate[];
  update: (v: BookingDate[]) => void;
}
export const useEditDates = (config: EditDataProps) => {
  const { dates, update} = config;

  const addDate = () => {
    let updatedDates: BookingDate[] = [];
    if(dates.length === 0) {
      updatedDates = [{ date: formatDateInternal(new Date()), start_time: "09:00", end_time: "17:00", _id: generateCode() }];
    } else {
      const last = dates[dates.length - 1];
      updatedDates = [...dates, { ...last, date: formatDateInternal(add(parseDateInternal(last.date), { days: 1 })), _id: generateCode() }];
    }
    update(updatedDates);
  }

  const removeDate = (id: string) => {
    update(dates.filter(d => d._id !== id));
  }

  const updateDate = (id: string, changes: Partial<BookingDate>) => {
    update(dates.map(d => d._id === id ? { ...d, ...changes } : d));
  }

  return {
    dates,
    addDate,
    removeDate,
    updateDate,
  }
}
