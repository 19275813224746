import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { Drawer, List, ListItem, ListItemIcon, ListItemText, ThemeProvider } from '@mui/material';
import { useEventContext } from "../events/EventContext";
import {
  Event,
  ArticleOutlined,
  ColorLensOutlined,
  ContactMailOutlined,
  HelpOutlineOutlined,
  Person,
  PersonOutlined,
  PermMediaOutlined,
  EventOutlined,
  EventAvailable,
} from "@mui/icons-material";
import { useUserContext } from "../auth/UserContext";
import { Theme } from '../Theme';



export const AuthNavBar = () => {
  const { currentEvent } = useEventContext();
  const { currentUser } = useUserContext();

    return(
      <ThemeProvider theme={Theme}>
        <Drawer
        variant="permanent"
        anchor="left"
        >
 
        <List  style={{
          marginTop: 100
          
          }}>
          {([
            ["/events", "Events", <Event />],
            currentEvent && ["/pages", "Pages", <ArticleOutlined />],
            currentEvent && ["/styles", "Appearence", <ColorLensOutlined />],
            currentEvent && currentEvent.is_registration_required && ["/attendees", "Attendees", <PersonOutlined />],
            currentEvent && ["/programs", "Programs", <EventAvailable />],
            currentEvent && ["/questions", "Questions", <HelpOutlineOutlined />],
            currentEvent && ["/contact-forms", "Contact forms", <ContactMailOutlined />],
            currentEvent && ["/meetings", "Meetings", <EventOutlined />],
            currentEvent && ["/media", "Media library", <PermMediaOutlined />],
            currentUser?.is_super_admin && ["/users", "Users", <Person />],
            ].filter(x => x) as [string, string, ReactNode][]).map(([path, label, icon]) => (

              <ListItem button component={Link} to={path} key={label} >
                <ListItemIcon >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItem>

            ))}
        </List>
      

       
      </Drawer>
      </ThemeProvider>
    );
};
