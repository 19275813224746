import React from 'react';
import { DeleteOutlined, LockOpenOutlined, Save } from '@mui/icons-material';
import { Checkbox, CircularProgress, FormControlLabel, IconButton, TextField, Tooltip } from '@mui/material';
import { User } from '../api/types';
import { FormGrid, FreeSpace, Header, Sheet, Title } from '../common/Forms';
import { useEditUser } from './useEditUser';
import { useNavigate, useParams } from 'react-router-dom';
import { useActionWithConfirmation } from '../common/ConfirmationDialog';


export const UserEditForm = () => {
  const { username } = useParams<{ username: string }>();
  const { data: user, isLoading, hasChanges, update, save, remove, loginAs  } = useEditUser(username || "");
  const removeUser = useActionWithConfirmation({
    title: "Delete user?",
    confirmLabel: "delete forever",
    action: () => remove().then(() => navigate("/users")),
  });

  const navigate = useNavigate();

  const userTextField = (field: keyof User, label: string) => (
    <TextField
      label={label}
      value={user[field] || ""}
      onChange={e => update({ [field]: e.target.value })}
      />
  )

  return (
    <Sheet>
      <Header>
        <Title>User</Title>
        <FreeSpace />
        
        {isLoading && <CircularProgress />}
        {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
        
        <Tooltip title="log in as this user">
          <IconButton onClick={loginAs}><LockOpenOutlined /></IconButton>
        </Tooltip>

        <IconButton onClick={removeUser.start}><DeleteOutlined /></IconButton>
        {removeUser.dialog}
      </Header>
      
      <FormGrid>
        {userTextField("username", "Username")}
        {userTextField("email", "Email")}
        <FormControlLabel
            label="Approved event admin"
            control={<Checkbox
              checked={user.is_approved_event_admin || false}
              onChange={e => update({ is_approved_event_admin: e.target.checked })}
              />}
            />

        {userTextField("last_name", "First name")}
        {userTextField("first_name", "Last name")}
        <FormControlLabel
            label="Super admin"
            control={<Checkbox
              checked={user.is_super_admin || false}
              onChange={e => update({ is_super_admin: e.target.checked })}
              />}
            />
      </FormGrid>
    </Sheet>
  );
}
