export const saveFile = (buffer: ArrayBuffer, mimetype: string, filename: string) => {
  const a = document.createElement("a");
  const data = new Blob([buffer], { type: mimetype });

  const url = URL.createObjectURL(data);
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
}
