import React from 'react';
import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useLabelsEditors } from './Labels';


export const LoginComponent = (props: PageComponentProps) => {
  const { labelControl } = useLabelsEditors(props);

  return (
    <>
      <FormGrid>        
        {labelControl("title", "Form title", "Sign in")}
        {labelControl("send", "Button label", "sign in")}
      </FormGrid>

      <FormGrid columns="1fr">
        {labelControl(
          "message",
          "Message before the form",
          "Don't have an account yet? [Register here](/registration).",
          { multiline: true })}
        {labelControl(
          "success",
          "Message after submission",
          "You have signed in successfully, we will now redirect you",
          { multiline: true })}
        {labelControl(
          "error",
          "Message on error",
          "Something went wrong. Please check the values you entered and try again.",
          { multiline: true })}
      </FormGrid>
    </>
  );
}
