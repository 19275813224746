import { Check, GetAppOutlined, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import { Link, TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Button, ButtonGroup, CircularProgress } from '@mui/material';
import React from 'react';
import { ContactForm } from '../../api/types';
import { formatDateTime } from '../../common/dateUtil';
import { Header, FreeSpace } from '../../common/Forms';
import { ContactFormMessagesData, Views } from './useContactFormMessages';

interface Props {
  data: ContactFormMessagesData;
  config: ContactForm;
}

export const ContactFormMessagesList = (props: Props) => {
  if(!props.config.fields) {
    return <></>;
  }

  const fields = props.config.fields;

  const { items, isLoading, update, view, setView, exportMessages } = props.data;

  return (
    <>
      <Header>
        <FreeSpace />
        {isLoading && <CircularProgress />}
        <ButtonGroup variant="outlined" size="small">
          {(["unprocessed", "processed", "all"] as Views[]).map(v => (
            <Button key={v} variant={view === v ? "contained" : "outlined"} onClick={() => setView(v)}>{v}</Button>
          ))}
        </ButtonGroup>

        <Tooltip title="Export messages to Excel">
          <IconButton onClick={() => exportMessages(props.config)}><GetAppOutlined /></IconButton>
        </Tooltip>
      </Header>
    
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {fields.map(field => (
                <TableCell key={field.field}> {field.label} </TableCell>
              ))}
              
              <TableCell key="created_datetime"> Created at </TableCell>
              <TableCell key="actions" width={100}> Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(message => (
                <TableRow key={message.id}>
                  {fields.map(({ field, field_type }) => (
                    <TableCell key={field}>
                      {field_type === "email" && message.fields[field]
                        ? (
                          <Link href={`mailto:${message.fields[field]}`}>
                            {message.fields[field]}
                          </Link>
                        )
                      : message.fields[field] || ""}
                    </TableCell>  
                  ))}

                  <TableCell key="created_datetime">
                    {formatDateTime(message.created_datetime)}
                  </TableCell>

                  <TableCell key="actions">
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center"}}>
                      <Tooltip key="process" title={message.is_processed ? "Mark as new" : "Mark as processed"}>
                        <IconButton
                          onClick={() => update(message.id, { is_processed: !message.is_processed })}
                          color={message.is_processed ? "primary" : "inherit"}
                          size="small">
                          {message.is_processed ? <Check /> : <RadioButtonUncheckedOutlined />}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
