import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useEventsList } from '../useEventsList';
import { Event } from "../../api/types";

export interface IEventContext {
  currentEvent: Event | null;
  available: {
    events: Event[];
    isLoading: boolean;
    reload: () => void;
  }

  selectEvent: (e: Event) => void;
  selectEventById: (id: number) => void;
}

const EventContext = React.createContext<IEventContext>({
  currentEvent: null,
  available: { events: [], isLoading: false, reload: () => { } },
  selectEvent: () => { },
  selectEventById: () => { },
});

const CurrentEvent_LS_key = "sweconf_current_event_id";

export const EventContextProvider = (props: { children: ReactNode }) => {
  const available = useEventsList();
  const [currentEvent, setCurrentEvent] = useState<Event | null>(null);

  const selectEvent = (e: Event) => {
    setCurrentEvent(e);
    localStorage.setItem(CurrentEvent_LS_key, e.id.toString());
  }

  const selectEventById = (id: number) => {
    const event = available.events.find(e => e.id === id);
    if(event) {
      selectEvent(event);
    }
  }

  useEffect(() => {
    if(!currentEvent && available.events.length) {
      const preferred = localStorage.getItem(CurrentEvent_LS_key);

      let event: Event | undefined = undefined;
      if(preferred) {
        event = available.events.find(e => e.id === +preferred);
      }
      event = event || available.events[0];

      if(event) {
        selectEvent(event);
      }
    }
  }, [available.events, currentEvent]);

  return (
    <EventContext.Provider value={{
      currentEvent,
      available,
      selectEvent,
      selectEventById,
    }}>
      {props.children}
    </EventContext.Provider>
  );
}


export const useEventContext = () => useContext(EventContext);
