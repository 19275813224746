import React, { ReactNode, useContext, useState } from 'react';
import { MediaLibraryData } from '.';
import { MediaLibraryDialog } from './MediaLibraryDialog';
import { FileInfo, useMediaLibraryData } from './useMediaLibraryData';

interface DialogConfig {
  selectItem: (fi: FileInfo) => void;
}

interface IMediaLibraryContext {
  media: MediaLibraryData;
  openMediaDialog: (cfg: DialogConfig) => void;
  forceLoad: () => void;
}

const MediaLibraryContext = React.createContext<IMediaLibraryContext>({
  media: {} as MediaLibraryData,
  openMediaDialog: () => { },
  forceLoad: () => { },
});

export const MediaLibraryProvider = (props: { children?: ReactNode }) => {
  const [dialogConfig, setDialogConfig] = useState<DialogConfig | null>(null);
  const [doLoad, setDoLoad] = useState<boolean>(false);
  const media = useMediaLibraryData({ noLoad: !doLoad });

  const openMediaDialog = (cfg: DialogConfig) => {
    setDoLoad(true);
    setDialogConfig(cfg);
  }

  const data: IMediaLibraryContext = {
    media,
    openMediaDialog,
    forceLoad: () => setDoLoad(true),
  };
  
  return (
    <MediaLibraryContext.Provider value={data}>
      {props.children}

      <MediaLibraryDialog
        isOpen={!!dialogConfig}
        close={() => setDialogConfig(null)}
        data={media}
        onItemSelect={fi => {
          if(dialogConfig) {
            dialogConfig.selectItem(fi);
            setDialogConfig(null);
            media.setFilter("");
          }
        }}
        />
    </MediaLibraryContext.Provider>
  );
}


export const useMediaLibrary = () => useContext(MediaLibraryContext);
