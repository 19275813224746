import React from "react";
import { useUserContext } from "../auth/UserContext";
import { AuthNavBar } from "./AuthNavBar";


const NavBar = () => {
    const {currentUser} = useUserContext();

    return currentUser
      ? <AuthNavBar/>
        
      : null
}

export default NavBar