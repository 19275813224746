import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { API_TOKEN_KEY } from "../api/ApiHelper";


export const OnlyAuthorized = () => {
  const token = localStorage.getItem(API_TOKEN_KEY);

  if(token) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />
  }
}
