import React from 'react';
import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { useLabelsEditors } from './Labels';


export const RegistrationComponent = (props: PageComponentProps) => {
  const { labelControl } = useLabelsEditors(props);

  return (
    <>
      <FormGrid>        
        {labelControl("title", "Form title", "Registration")}
        {labelControl("send", "Button label", "register")}
      </FormGrid>

      <FormGrid columns="1fr">
        {labelControl(
          "message",
          "Message before the form",
          "Already have an account? [Sign in here](/login).",
          { multiline: true })}
        {labelControl(
          "success",
          "Message after submission",
          "You have registered successfully, we will now redirect you",
          { multiline: true })}
        {labelControl(
          "success_pending_approval",
          "Message for user pending approval",
          "We received your registration request.\n\nOnce it is approved, you will be able to [sign in](/login).",
          { multiline: true })}
        {labelControl(
          "error",
          "Message on error",
          "Something went wrong. Please check the values you entered and try again.",
          { multiline: true })}
      </FormGrid>
    </>
  );
}
