import styled from '@emotion/styled';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useState } from 'react';
import { formatDateWithDow } from '../../../common/dateUtil';
import { MeetingRaw, MeetingsBookingConfig } from '../types';
import { ScheduledMeetingsData, getRepDescription, getRoomDescription } from './useScheduledMeetings';

interface Props {
  meetings: ScheduledMeetingsData;
  config: MeetingsBookingConfig;
}

const MeetingsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  justify-content: flex-start;

  & .MuiChip-root {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    height: auto;
    justify-content: flex-start;
  }
  
  & .MuiChip-label {
    white-space: pre-line;
  }
`;

export const ScheduledMeetings = ({ meetings, config }: Props) => {

  const getRepDescriptionL = (sponsorId?: string | null, repId?: string | null) => getRepDescription(config, sponsorId, repId);

  const getRoomDescriptionL = (roomId?: string | null) => getRoomDescription(config, roomId);

  const getMeetingDescription = (m: MeetingRaw) => {
    const timeAndCustomer = `${m.start_time}-${m.end_time}: ${m.customer_name} (${m.customer_email}${m.customer_company ? ", " : ""}${m.customer_company || ""})`
    return `${timeAndCustomer}\nLanguage: ${m.customer_language || ""}\n${getRepDescriptionL(m.sponsor_id, m.representative_id)}\nRoom: ${getRoomDescriptionL(m.room_id)}`
  }

  const getBlockDescription = (m: MeetingRaw) => {
    // will be either one or the other
    const room = m.room_id ? "room " + getRoomDescriptionL(m.room_id) : "";
    const rep = m.representative_id ? getRepDescriptionL(m.sponsor_id, m.representative_id) : "";

    return `${m.start_time}-${m.end_time}: ${room}${rep}`;
  }

  const [meetingToRemove, setMeetingToRemove] = useState<MeetingRaw | null>(null);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          {config.dates_available.map(d => <TableCell key={d.date} style={{ whiteSpace: "nowrap", minWidth: "200px" }}>{formatDateWithDow(d.date)}</TableCell>)}
        </TableHead>

        <TableRow>
          {config.dates_available.map(d => (
            <TableCell key={d.date}>
              <MeetingsWrapper>
                {meetings.getMeetingsForDate(d.date).map(m => (
                  m.kind === "block"
                    ? <Chip label={getBlockDescription(m)} onDelete={() => meetings.removeBlock(m)} />
                    : <Chip label={getMeetingDescription(m)} color="primary" variant="outlined" onDelete={() => setMeetingToRemove(m)} />
                ))}
              </MeetingsWrapper>
            </TableCell>
            ))}
        </TableRow>
      </Table>
      {meetings.meetings.length === 0 && <Typography style={{ textAlign: "center" }}>No meetings were scheduled so far</Typography>}


      <Dialog open={!!meetingToRemove} onClose={() => setMeetingToRemove(null)}>
        <DialogTitle>Cancel meeting?</DialogTitle>
        {meetingToRemove && <DialogContent>
          <Typography>You are about to remove the following meeting:</Typography>
          <Typography style={{ paddingLeft: "1rem", marginTop: "1rem" }}>{meetingToRemove.date} {meetingToRemove.start_time}-{meetingToRemove.end_time}</Typography>
          <Typography style={{ paddingLeft: "1rem" }}>Customer: {meetingToRemove.customer_name} ({meetingToRemove.customer_email}{meetingToRemove.customer_company ? ", " : ""}{meetingToRemove.customer_company || ""})</Typography>
          <Typography style={{ paddingLeft: "1rem" }}>Representative: {getRepDescriptionL(meetingToRemove.sponsor_id, meetingToRemove.representative_id)}</Typography>
          <Typography style={{ paddingLeft: "1rem", marginBottom: "1rem" }}>Room: {getRoomDescriptionL(meetingToRemove.room_id)}</Typography>

          <Typography>If you proceed, a cancellation email will be sent to the customer and the representative and the meeting will be removed.</Typography>
          <Typography color="error">This action is irrevocable.</Typography>
        </DialogContent>}

        <DialogActions>
          <Button color="inherit" onClick={() => setMeetingToRemove(null)}>cancel</Button>
          <Button
            color="error"
            variant="contained"
            onClick={() => {
              if(meetingToRemove) {
                meetings.removeBlock(meetingToRemove);
              }
              setMeetingToRemove(null);
            }}>
              remove meeting
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
