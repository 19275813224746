import React, { ReactNode, useState } from 'react';
import styled from "@emotion/styled";
import { CircularProgress, IconButton, Theme, Typography } from '@mui/material';
import { Close, PhotoCameraOutlined } from '@mui/icons-material';
import { createFileDropHandler } from '../../common/FileUpload';
import { useMediaLibrary } from './MediaLibraryContext';

interface Props {
  selectedUrl: string | null | undefined;
  setSelectedUrl: (v: string | null) => void;
  label?: ReactNode;
  style?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  noHint?: boolean;
}

const Wrapper = styled.div<{ isDragTarget?: boolean, hasLabel?: boolean }>`
  position: relative;
  width: 300px;
  max-height: 300px;
  cursor: pointer;
  box-sizing: border-box;

  border-radius: 4px;
  border: ${props => props.isDragTarget ? `1px dotted ${(props.theme as Theme).palette.primary.main}` : "none"};
  background: ${props => props.isDragTarget ? `${(props.theme as Theme).palette.primary.main}40` : "transparent"};

  & .uploading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    background: #333333a0;
    color: #ffffff;
  }

  & > img {
    &:hover {
      background-color: #eeeeee80;
    }
  }

  & > .remove {
    display: none;
  }

  &:hover {
    & > .remove {
      display: block;
      position: absolute;
      top: ${props => props.hasLabel ? 1.25 : 0.25}rem;
      right: 0.25rem;
      background: #eeeeee80;
      height: 34px;
    }
  }
`;

const EmptyPlaceholder = styled.div`
  background: #eeeeee80;
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  padding: 1rem;
  box-sizing: border-box;
  border: 1px dashed #aaaaaa;
`;

export const MediaItemPicker = (props: Props) => {
  const { selectedUrl, setSelectedUrl } = props;
  const [isDragTarget, setIsDragTarget] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const { media: mediaLib, openMediaDialog } = useMediaLibrary();

  const uploadFile = (file: any) => {
    setIsUploading(true);
    mediaLib.uploadFile(file)
      .then(fi => {
        setIsUploading(false);
        setSelectedUrl(mediaLib.getFilePath(fi));
      })
      .catch(e => {
        setIsUploading(false);
        throw e;
      })
  }

  const openDialog = () => {
    openMediaDialog({ selectItem: fi => setSelectedUrl(mediaLib.getFilePath(fi)) });
  }

  const urlExtensionStarts = (selectedUrl || "").lastIndexOf(".");
  const urlExtension = (urlExtensionStarts >= 0 ? selectedUrl?.substring(urlExtensionStarts+1) || "" : "").toLowerCase();
  const isImage = urlExtension && ["jpg", "jpeg", "png", "svg"].includes(urlExtension);
  const filenameStarts = (selectedUrl || "").lastIndexOf("/");
  const filename = filenameStarts >= 0 ? selectedUrl?.substring(filenameStarts + 1) || "" : "";

  return (
    <Wrapper
      style={props.style}
      hasLabel={!!props.label}
      isDragTarget={isDragTarget}
      onDragEnter={() => setIsDragTarget(true)}
      onDragLeave={() => setIsDragTarget(false)}
      onDrop={createFileDropHandler(f => { setIsDragTarget(false); uploadFile(f); })}
      onDragOver={(e: any) => { e.preventDefault(); }}>
        {props.label && (
          <Typography
            variant="caption"
            onClick={openDialog}
            sx={{ marginTop: "-3px", display: "block"}}>
              {props.label}
          </Typography>)}
      {selectedUrl
        ? isImage
            ? <img src={selectedUrl} style={{ maxWidth: 300, maxHeight: 300, ...(props.imageStyle || {}) }} alt="" onClick={openDialog} />
            : <EmptyPlaceholder onClick={openDialog}>
                <Typography>{filename}</Typography>
              </EmptyPlaceholder>
        : (
          <EmptyPlaceholder onClick={openDialog}>
            <PhotoCameraOutlined fontSize="large" />
            {!props.noHint && <Typography>Drop file here or click to select from media library</Typography>}
          </EmptyPlaceholder>)
      }

      {selectedUrl && <IconButton className="remove" size="small" onClick={e => { e.stopPropagation(); setSelectedUrl(null); }}>
        <Close />
      </IconButton>}

      {isUploading && <div className="uploading"><CircularProgress color="inherit" /></div>}
    </Wrapper>
  );
}
