import React from 'react';
import { TextField } from '@mui/material';
import { PageComponentProps } from './types';


export const HtmlComponent = (props: PageComponentProps) => {
  const { component, containerIdx, componentIdx, dataRaw: data } = props;
  return (
    <TextField
        style={{ width: "100%" }}
        InputProps={{
          style: { fontFamily: "monospace" },
        }}
        value={component.content || ""}
        onChange={e => data.updateComponentContent(containerIdx, componentIdx, e.target.value)}
        multiline
        />
  );
}
