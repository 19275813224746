import { useEffect, useState } from "react";
import SwissConfApi from "../api/ApiHelper";
import { Event } from "../api/types";
import { useUserContext } from "../auth/UserContext";
import { useEventContext } from "./EventContext";

export type NewEvent = Pick<Event, 'name' | 'title'>;

export interface EventsListData {
  events: Event[];
  isLoading: boolean;
  reload: () => void;
  create: (data: NewEvent) => Promise<Event>;
}


export const useEventsList = (): EventsListData => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { currentUser } = useUserContext();
  const eventContext = useEventContext();

  const reload = async () => {
    setIsLoading(true);
    const data = await SwissConfApi.getAllEvents();
    setEvents(data);
    setIsLoading(false);   
  }

  const create = async (data: NewEvent) => {
    const event = await SwissConfApi.createEvent(data);
    reload();
    eventContext.available.reload();
    return event as Event;
  }

  useEffect(() => {
    if(currentUser?.username) {
      reload();
    }
  }, [currentUser?.username]);

  return {
    events,
    isLoading,
    reload,
    create,
  };
}