import { generateCode } from "../../../common/helpers";
import { Room } from "../types";

interface Config {
  rooms: Room[];
  update: (v: Room[]) => void;
}

export const useEditRooms = ({ rooms, update }: Config) => {
  const addRoom = () => {
    update([ ...rooms, { _id: generateCode(), title: "" }])
  }

  const removeRoom = (id: string) => {
    update(rooms.filter(r => r._id !== id));
  }

  const updateRoom = (id: string, changes: Partial<Room>) => {
    update(rooms.map(r => r._id === id ? { ...r, ...changes } : r));
  }

  return {
    rooms,
    addRoom,
    removeRoom,
    updateRoom,
  }
}