import SwissConfApi from "../api/ApiHelper";
import { Event, Menu, MenuConfigItem, MenuType } from "../api/types";
import { moveItem } from "../common/arrayUtil";
import { EditData, useEditData } from "../common/useEditData";

export interface EditEventData extends EditData<Event> {
  remove: () => Promise<void>;
  menu: EditMenu;
}

export const useEditEvent = (id: number): EditEventData => {

  const data = useEditData<Event, number>({
    keys: id,
    noLoad: !id,
    dflt: {} as Event,
    load: id => SwissConfApi.getEventbyId(id),
    saveChanges: (id, changes) => SwissConfApi.updateEvent(id, changes),
  });

  const menu = useEditEventMenu(data);

  const remove = () => {
    return SwissConfApi.deleteEvent(id);
  }


  return {
    ...data,
    remove,
    menu,
  };
}


export interface EditMenu {
  menu: Menu;
  updatePage: (menuType: MenuType, pageId: number, changes: Partial<MenuConfigItem>) => void;
  movePage: (menuType: MenuType, pageId: number, direction: "up" | "down") => void;
  updateSetting: (changes: Partial<Menu>) => void;
}

const useEditEventMenu = ({ data: event, update }: Pick<EditData<Event>, "data" | "update">): EditMenu => {
  const existing: Menu = event.menu || { primary: [], navbar: [] };
  return {
    menu: existing,
    updatePage: (menuType, pageId, changes) => update({
      menu: {
        ...existing,
        [menuType]: existing[menuType].map(item => item.page_id === pageId ? { ...item, ...changes } : item)
      }}),

    movePage: (menuType, pageId, direction) => {
      const menu = existing[menuType];
      const currentIdx = menu.findIndex(item => item.page_id === pageId);
      
      update({
        menu: {
          ...existing,
          [menuType]: moveItem(menu, currentIdx, direction === "down" ? 1 : -1),
        }
      })
    },

    updateSetting: (changes) => {
      update({
        menu: {
          ...existing,
          ...changes,
        }
      })
    },
  };
}
