import React from 'react';
import { DeleteOutlined } from '@mui/icons-material';
import { Typography, TextField, FormControl, Select, MenuItem, Checkbox, Tooltip, IconButton, Button } from '@mui/material';
import { Field } from '../../api/types';
import { FormGrid } from '../../common/Forms';
import { slugify } from '../../common/textUtil';
import { EditFieldsData } from './useEditContactForm';

interface FieldPermissions {
  noEditRequired: boolean;
  noDelete: boolean;
  noEditName: boolean;
  noEditType: boolean;
}

interface Props extends EditFieldsData {
  allowedFieldTypes?: string[];
  getFieldPermissions?: (field: string) => Partial<FieldPermissions>;
}

const FieldTypes = [
  ["text", "short text"],
  ["textlong", "multiline text"],
  ["email", "email"],
  ["password", "password"],
  ["select", "dropdown"],
  ["checkbox", "checkbox"],
];

export const FormFieldsEditor = (props: Props) => {
  const { fields, updateField, addField, removeField } = props;

  const allowedFieldTypes = props.allowedFieldTypes || ["text", "textlong", "email", "select", "checkbox"];
  const getFieldPermissions = props.getFieldPermissions || (() => ({} as Partial<FieldPermissions>));

  return (
    <>
      <FormGrid columns="repeat(3, 1fr) repeat(3, max-content)" style={{ alignItems: "start", rowGap: "1rem" }}>
          
        <React.Fragment key="header">
          {["Field name", "Label", "Type"].map(f => <Typography key={f} variant="caption">{f}</Typography>)}
          <Typography key="Mandatory" variant="caption" style={{ justifySelf: "center" }}>Mandatory</Typography>
          <Typography key="Wide" variant="caption" style={{ justifySelf: "center" }}>Wide</Typography>
          <div />
        </React.Fragment>

        {(fields || []).map((field, idx) => (
          <React.Fragment key={`field${idx}`}>
            <TextField
              key="field"
              value={field.field || ""}
              disabled={getFieldPermissions(field.field).noEditName}
              onChange={e => updateField(idx, { field: slugify(e.target.value) })}
              />
            <TextField
              key="label"
              value={field.label || ""}
              onChange={e => updateField(idx, { label: e.target.value })}
              />
            <FormControl>
              <Select
                key="field_type"
                value={field.field_type || "text"}
                disabled={getFieldPermissions(field.field).noEditType}
                onChange={e => updateField(idx, { field_type: (e.target.value as Field["field_type"]) || "text" })}
                >
                  {FieldTypes
                    .filter(([type]) => allowedFieldTypes.includes(type))
                    .map(([type, label]) => (
                      <MenuItem value={type}>{label}</MenuItem>
                    ))}
              </Select>
            </FormControl>

            <Checkbox
              key="required"
              style={{ alignSelf: "end", justifySelf: "center" }}
              checked={field.required || false}
              disabled={getFieldPermissions(field.field).noEditRequired}
              onChange={e => updateField(idx, { required: e.target.checked })}
              />

            <Checkbox
              key="wide"
              style={{ alignSelf: "end", justifySelf: "center" }}
              checked={field.field_type === "textlong" ? true : (field.wide || false)}
              disabled={field.field_type === "textlong"}
              onChange={e => updateField(idx, { wide: e.target.checked })}
              />

            <div>
              {!getFieldPermissions(field.field).noDelete && <Tooltip title="remove field">
                <IconButton onClick={() => removeField(idx)}>
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>}
            </div>


            {field.field_type === "select" && (
              <>
                <Typography variant="caption">Provide options, one per line</Typography>
                <TextField
                  key="options"
                  multiline
                  style={{ gridColumn: "span 2" }}
                  value={field.options || ""}
                  onChange={e => updateField(idx, { options: e.target.value })}
                  />
                <div style={{ gridColumn: "span 3" }} />
              </>
            )}
          </React.Fragment>
        ))}
        
      </FormGrid>

      <Button onClick={addField}>add field</Button>
      
    </>
  );
}
