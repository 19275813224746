import React from "react"

const Alert = ({errors = []} : {errors: any[]}) =>{
    if (errors.length){
        return(
            <div>
                <strong>
                    {errors.map(error=><p key={error}>{error}</p>)}
                </strong>
            </div>
        )
    } else{
        return null
    }
}

export default Alert