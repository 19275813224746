import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, InputAdornment, IconButton } from "@mui/material";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import { ProgramFile, ProgramItem, Speaker } from "../../api/types";
import { FormGrid, SectionTitle } from "../../common/Forms";
import { RichTextEditor } from "../Pages/components/RichTextEditor";
import { generateCode } from "../../common/helpers";
import React from "react";
import { MediaItemPicker } from "../media";
import { Add, DeleteOutlined } from "@mui/icons-material";
import { Checkbox } from "../../common/Checkbox";
import { SpeakerEditor } from "./SpeakerEditor";

const useEditItemDetails = (item: ProgramItem | null, update: (itemId: string, changes: Partial<ProgramItem>) => void) => {
  const speakers = (item?.speakers || []);
  const addSpeaker = () => {
    if(item?.id) {
      update(item.id, { speakers: [...speakers, { id: generateCode(), name: "" }]})
    }
  }

  const updateSpeaker = (id: string, changes: Partial<Speaker>) => {
    if(item?.id) {
      update(item.id, { speakers: speakers.map(x => x.id === id ? { ...x, ...changes } : x) });
    }
  }

  const removeSpeaker = (id: string) => {
    if(item?.id) {
      update(item.id, { speakers: speakers.filter(x => x.id !== id) });
    }
  }

  const files = (item?.files || []);
  const addFile = () => {
    if(item?.id) {
      update(item.id, { files: [...files, { id: generateCode(), title: "" }]})
    }
  }

  const updateFile = (id: string, changes: Partial<ProgramFile>) => {
    if(item?.id) {
      update(item.id, { files: files.map(x => x.id === id ? { ...x, ...changes } : x) });
    }
  }

  const removeFile = (id: string) => {
    if(item?.id) {
      update(item.id, { files: files.filter(x => x.id !== id) });
    }
  }

  return {
    speakers,
    addSpeaker,
    updateSpeaker,
    removeSpeaker,

    files,
    addFile,
    updateFile,
    removeFile,
  }
}

interface DialogProps {
  item: ProgramItem | null;
  close: () => void;
  update: (itemId: string, changes: Partial<ProgramItem>) => void;
}

export const ProgramItemDialog = ({ item, update, close }: DialogProps) => {
  const details = useEditItemDetails(item, update);

  return (
    <Dialog
      open={!!item}
      onClose={() => close()}
      maxWidth="md"
      fullWidth
      PaperProps={{ style: { height: "calc(100% - 64px - 120px)" } }}>
      <DialogTitle>Edit program item</DialogTitle>

      {item && <DialogContent>
        <FormGrid columns="4fr 2fr 1fr 1fr">
          <TextField
            label="Title"
            variant="standard"
            value={item.title || ""}
            onChange={e => update(item.id, { title: e.target.value })}
            error={!item.title}
            />

          <DesktopDatePicker
            label="Date"
            inputFormat="dd.MM.yyyy"
            value={item.date || null}
            onChange={v => update(item.id, { date: v })}
            renderInput={(params) => <TextField {...params} error={params.error || !item.date} />}
            />

          <DesktopTimePicker
            label="Time"
            inputFormat="HH:mm"
            ampm={false}
            value={item.time || null}
            onChange={v => update(item.id, { time: v })}
            renderInput={(params) => <TextField {...params} error={params.error || !item.time} />}
            />
          
          <DesktopTimePicker
            label="End Time"
            inputFormat="HH:mm"
            ampm={false}
            value={item.end_time || null}
            onChange={v => update(item.id, { end_time: v })}
            renderInput={(params) => <TextField {...params} />}
            />
          
          <TextField
            label="Location"
            variant="standard"
            value={item.location || ""}
            onChange={e => update(item.id, { location: e.target.value })}
            />

          <TextField
            label="Category"
            variant="standard"
            value={item.category || ""}
            onChange={e => update(item.id, { category: e.target.value })}
            />

          <TextField
            label="HTML Class Name"
            variant="standard"
            value={item._class || ""}
            onChange={e => update(item.id, { _class: e.target.value })}
            style={{ gridColumn: "span 2"}}
            />

          <TextField
            label="Tags"
            variant="standard"
            value={item.tags || ""}
            onChange={e => update(item.id, { tags: e.target.value })}
            />

          <TextField
            label="Language"
            variant="standard"
            value={item.language || ""}
            onChange={e => update(item.id, { language: e.target.value })}
            />

          <Checkbox
            label="Hidden"
            checked={item.is_hidden || false}
            onChange={v => update(item.id, { is_hidden: v})}
            style={{ gridColumn: "span 2", alignSelf: "end" }}
            />
        </FormGrid>

        <SectionTitle style={{ marginTop: "2rem", fontSize: "1em" }}>Files</SectionTitle>
        <FormGrid columns="2fr 1fr">
          {details.files.map((file,sidx) => (
            <React.Fragment key={file.id}>
              <TextField
                label="Title"
                variant="standard"
                value={file.title || ""}
                onChange={e => details.updateFile(file.id, { title: e.target.value })}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><IconButton size="small" onClick={() => details.removeFile(file.id)}><DeleteOutlined /></IconButton></InputAdornment>
                }}
                />
              
              <MediaItemPicker
                selectedUrl={file.file_url}
                setSelectedUrl={url => details.updateFile(file.id, { file_url: url })}
                />
            </React.Fragment>
          ))}
        </FormGrid>
        <Button onClick={() => details.addFile()} startIcon={<Add />}>add file</Button>

        <SectionTitle style={{ marginTop: "2rem", fontSize: "1em" }}>Speakers</SectionTitle>
        <FormGrid columns="1fr">
          {details.speakers.map(speaker => (
            <SpeakerEditor
              key={speaker.id}
              speaker={speaker}
              update={details.updateSpeaker}
              remove={details.removeSpeaker}
              />
          ))}
        </FormGrid>
        <Button onClick={() => details.addSpeaker()} startIcon={<Add />}>add speaker</Button>

        <SectionTitle style={{ marginTop: "2rem", fontSize: "1em" }}>Description</SectionTitle>
        <RichTextEditor value={item.description} update={v => update(item.id, { description: v })} />
      </DialogContent>}

      <DialogActions>
        <Button onClick={() => close()}>close</Button>
      </DialogActions>
    </Dialog>
  )
}
