import ExcelJS from "exceljs";
import { ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { ProgramItem, Speaker } from "../../api/types";
import { generateCode } from "../../common/helpers";

const parseDescription = (text: string | undefined) => {
  const x = convertFromHTML((text || "").split("\n").filter(line => !!line).map(line => `<p>${line}</p>`).join("\n"));
  const state = ContentState.createFromBlockArray(
    x.contentBlocks,
    x.entityMap,
  );
  return convertToRaw(state);
}

const parseSpeakers = (text: string | undefined): Speaker[] => {
  return (text || "")
    .split("\n")
    .map(line => line.trim())
    .filter(line => !!line)
    .map(line => line.split(";").map(part => part.trim()))
    .map(([name,job,speaker_url]) => ({ id: generateCode(), name, job, speaker_url }));
}

const parseTime = (text: string | undefined): Date | null => {
  if(!text) {
    return null;
  }
  try {
    const dNumber = Date.parse(text);
    const d = new Date(dNumber);
    const dUtc = dNumber+d.getTimezoneOffset()*60*1000;
    return text ? new Date(dUtc) : null;
  } catch(e) {
    return null;
  }
}

export const createProgramItemsFromXLSX = (file: File) => {
  return new Promise<ProgramItem[]>((resolve,reject) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(e.target?.result as ArrayBuffer).then(wb => {
          try {
            if(e.target) {
              const sheet = wb.worksheets[0];
              const result: ProgramItem[] = [];
              sheet.eachRow((row, idx) => {
                if(idx > 1) {
                  const item: ProgramItem = {
                    id: generateCode(),
                    date: parseTime(row.getCell(1).text) as any,
                    time: parseTime(row.getCell(2).text) as any,
                    end_time: parseTime(row.getCell(3).text) as any,
                    title: row.getCell(4).text,
                    location: row.getCell(5).text,
                    category: row.getCell(6).text,
                    language: row.getCell(7).text,
                    tags: row.getCell(8).text,
                    speakers: parseSpeakers(row.getCell(9).text),
                    description: parseDescription(row.getCell(10).text),
                  };

                  (item.speakers || []).forEach((speaker,idx) => {
                    const maybeCV = row.getCell(11+idx)?.text || "";
                    if(maybeCV) {
                      speaker.description = parseDescription(maybeCV);
                    }
                  })

                  result.push(item)
                }
              });
              resolve(result);
            }
          } catch(e) {
            reject(e);
          }});
    })
    reader.readAsArrayBuffer(file);
  });
}