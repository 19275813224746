import { useMemo, useState } from "react";
import SwissConfApi from "../../api/ApiHelper";
import { Program, ProgramItem } from "../../api/types";
import { generateCode } from "../../common/helpers";
import { EditData, useEditData } from "../../common/useEditData";
import { useEventContext } from "../EventContext";
import { createProgramItemsFromXLSX } from "./programXlsxImport";
import { programItemsSortValue, timeLocalToUtc, timeUtcToLocal } from "./programTimeHelpers";


export interface EditProgramData extends EditData<Program> {
  items: ProgramItem[];
  itemsTree: ProgramItem[];
  addItem: (item?: Partial<ProgramItem>) => void;
  updateItem: (id: string, changes: Partial<ProgramItem>) => void;
  removeItem: (id: string) => void;
  updateItemParent: (id: string, parentId: string | null) => void;

  isUploadingProgram: boolean;
  uploadProgram: (file: File) => void;
}

const processTimesFromServer = (program: Program) => {
  if(program.content?.items) {
    const itemsCopy = program.content.items
      .map((item: ProgramItem) => ({
        ...item,
        time: timeUtcToLocal(item.time),
        end_time: timeUtcToLocal(item.end_time),
        date: timeUtcToLocal(item.date),
      }));
    return {
      ...program,
      content: {
        ...program.content,
        items: itemsCopy,
      }
    }
  } else {
    return program;
  }
}

const processTimesToServer = (changes: Partial<Program>) => {
  if(changes.content?.items) {
    const itemsCopy = changes.content.items
      .map((item: ProgramItem) => ({
        ...item,
        time: timeLocalToUtc(item.time, true),
        end_time: timeLocalToUtc(item.end_time, true),
        date: timeLocalToUtc(item.date, false),
      }));
    return {
      ...changes,
      content: {
        ...changes.content,
        items: itemsCopy,
      }
    }
  } else {
    return changes;
  }
}

const clearItems = (changes: Partial<Program>) => {
  if(changes.content?.items) {
    const itemsCopy = changes.content.items
      .map((item: ProgramItem) => {
        const copy = { ...item };
        delete copy.children;
        return copy;
      });
    return {
      ...changes,
      content: {
        ...changes.content,
        items: itemsCopy,
      }
    }
  } else {
    return changes;
  }
}

const sortItems = (changes: Partial<Program>) => {
  if(changes.content?.items) {
    const itemsCopy = [...changes.content.items];
    itemsCopy.sort((a: ProgramItem, b: ProgramItem) => programItemsSortValue(a) < programItemsSortValue(b) ? -1 : 1)
    return {
      ...changes,
      content: {
        ...changes.content,
        items: itemsCopy,
      }
    }
  } else {
    return changes;
  }
}

interface Config {
  onSave?: () => void;
}

const assembleItemsTree = (items: ProgramItem[]): ProgramItem[] => {
  const firstLevel = items.filter(item => !item.parent_id);
  
  if(firstLevel.length === items.length) {
    return items;
  } else {
    const final: ProgramItem[] = firstLevel.map(item => ({ ...item, children: [] }));
    items.forEach(item => {
      if(item.parent_id) {
        const parent = final.find(p => p.id === item.parent_id);
        if(parent) {
          parent.children?.push(item);
        }
      }
    });
    return final;
  }
}

export const useEditProgram = (program_id: number, cfg?: Config): EditProgramData => {
  const { currentEvent } = useEventContext();
  const data = useEditData<Program, [number, number]>({
    keys: [currentEvent?.id || -1, program_id || -1],
    noLoad: !currentEvent,
    dflt: { } as Program,
    load: ([eventId, programId]) =>
      SwissConfApi.request(`/events/${eventId}/programs/${programId}`, undefined, "get")
        .then(x => processTimesFromServer(x)),
    saveChanges: ([eventId, programId], changes) => SwissConfApi.request(
        `/events/${eventId}/programs/${programId}`,
        sortItems(clearItems(processTimesToServer(changes))), "patch")
      .then(x => processTimesFromServer(x))
      .then(x => {
        if(cfg?.onSave) {
          cfg?.onSave();
        }
        return x;
      }),
  });

  const items: ProgramItem[] = data.data.content?.items || [];
  
  const itemsTree = useMemo(
    () => assembleItemsTree(data.data.content?.items || []),
    [data.data.content?.items]);

  const updateItems = (newItems: ProgramItem[]) => {
    data.update({ content: { ...(data.data.content || {}),  items: newItems }});
  }

  const addItem = (item?: Partial<ProgramItem>) => {
    updateItems([...items, { title: "", ...(item || {}), id: generateCode() }]);
  }

  const updateItem = (id: string, changes: Partial<ProgramItem>) => {
    updateItems([...items.map(x => x.id === id ? { ...x, ...changes } : x)]);
  }

  const removeItem = (id: string) => {
    updateItems([...items.filter(x => x.id !== id)]);
  }

  const updateItemParent = (id: string, parentId: string | null) => {
    updateItem(id, { parent_id: parentId });
  }

  const [isUploadingProgram, setIsUploading] = useState<boolean>(false);
  const uploadProgram = (file: File) => {
    setIsUploading(true);
    createProgramItemsFromXLSX(file)
      .then(newItems => {
        updateItems([...items, ...newItems]);
        setIsUploading(false);
      })
      .catch(e => {
        setIsUploading(false);
        console.warn("Import XLSX error", e);
      })
  }

  return {
    ...data,
    items,
    itemsTree,
    addItem,
    updateItem,
    removeItem,
    updateItemParent,

    isUploadingProgram,
    uploadProgram,
  }
}
