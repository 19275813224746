import React from 'react';
import { Add, DeleteOutlined } from '@mui/icons-material';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { FormGrid } from '../../../common/Forms';
import { SponsorsData } from './useEditSponsors';
import styled from '@emotion/styled';
import { TimeBlockedEditor } from './TimeBlockedEditor';
import { ScheduledMeetingsData } from './useScheduledMeetings';
import { Checkbox } from '../../../common/Checkbox';

interface Props {
  data: SponsorsData;
  meetings: ScheduledMeetingsData;
}

const SponsorWrapper = styled.div`
  margin: 1rem 0 2rem;

  & > p {
    margin-top: 0.5rem;
    color: ${props => props.theme.palette.action.active};
  }

  border-left: 2px solid ${props => props.theme.palette.primary.main};
  padding-left: 1rem;
`;

export const SponsorsEditor = ({ data, meetings }: Props) => {
  return (
    <>
      {data.sponsors.map(s => (
        <SponsorWrapper key={s._id}>
          <FormGrid columns="300px 1fr 1fr max-content">
            <TextField label="Sponsor Title" value={s.title} onChange={(e)=> data.updateSponsor(s._id, { title: e.target.value })} />
            <TextField label="Logo URL" value={s.logo_url} onChange={(e)=> data.updateSponsor(s._id, { logo_url: e.target.value })} />
            <Checkbox
              checked={!s.dont_shuffle_reps}
              label="Randomize rep selection"
              onChange={v => data.updateSponsor(s._id, { dont_shuffle_reps: !v })}
              />
            <Button size="small" color="inherit" endIcon={<DeleteOutlined />} onClick={() => data.removeSponsor(s._id)}>delete sponsor</Button>
          </FormGrid>

          <Typography variant="body1">Representatives</Typography>
          <FormGrid columns="200px 200px 1fr max-content" style={{ alignItems: "end", margin: "0.5rem" }}>
            <Typography variant="caption" color="GrayText">Name</Typography>
            <Typography variant="caption" color="GrayText">Email</Typography>
            <Typography variant="caption" color="GrayText">Blocked time</Typography>
            <div />

            {s.representatives.map(r => (
              <React.Fragment key={r._id || ""}>
                <TextField value={r.name} onChange={(e)=> data.updateRepresentative(s._id, r._id, { name: e.target.value })} />
                <TextField value={r.email} onChange={(e)=> data.updateRepresentative(s._id, r._id, { email: e.target.value })} />
                
                <TimeBlockedEditor
                  blocks={meetings.getBlocksForRep(s._id, r._id)}
                  removeBlock={b => meetings.removeBlock(b)}
                  startAddBlock={() => meetings.startAddBlock({ sponsor_id: s._id, representative_id: r._id })}
                  />
                
                <IconButton size="small" onClick={() => data.removeRepresentative(s._id, r._id)}><DeleteOutlined /></IconButton>
              </React.Fragment>
            ))}
          </FormGrid>
          <Button size="small" onClick={() => data.addRepresentative(s._id)} startIcon={<Add />}>add representative</Button>
        </SponsorWrapper>
      ))}

      <div>
        <Button size="small" onClick={() => data.addSponsor()} startIcon={<Add />}>add sponsor</Button>
      </div>
    </>
  );
}
