import { generateCode } from "../../../common/helpers";
import { Representative, Sponsor } from "../types";

interface Config {
  sponsors: Sponsor[];
  update: (v: Sponsor[]) => void;
}

export const useEditSponsors = ({ sponsors, update }: Config) => {
  const addSponsor = () => {
    update([ ...sponsors, { _id: generateCode(), title: "", representatives: [] }])
  }

  const removeSponsor = (id: string) => {
    update(sponsors.filter(s => s._id !== id));
  }

  const updateSponsor = (id: string, changes: Partial<Sponsor>) => {
    update(sponsors.map(s => s._id === id ? { ...s, ...changes } : s));
  }

  const addRepresentative = (sponsorId: string) => {
    const sponsor = sponsors.find(s => s._id === sponsorId);
    if(sponsor) {
      updateSponsor(sponsorId, { representatives: [...sponsor.representatives, { _id: generateCode(), email: "", name: "" }]});
    }
  }

  const removeRepresentative = (sponsorId: string, repId: string) => {
    const sponsor = sponsors.find(s => s._id === sponsorId);
    if(sponsor) {
      updateSponsor(sponsorId, { representatives: sponsor.representatives.filter(r => r._id !== repId)});
    }
  }

  const updateRepresentative = (sponsorId: string, repId: string, changes: Partial<Representative>) => {
    const sponsor = sponsors.find(s => s._id === sponsorId);
    if(sponsor) {
      updateSponsor(sponsorId, { representatives: sponsor.representatives.map(r => r._id === repId ? { ...r, ...changes } : r)});
    }
  }

  return {
    sponsors,
    addSponsor,
    updateSponsor,
    removeSponsor,

    addRepresentative,
    removeRepresentative,
    updateRepresentative,
  }
}

export type SponsorsData = ReturnType<typeof useEditSponsors>;
