import React from 'react';
import { Check, GetAppOutlined, RadioButtonUncheckedOutlined } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  Link,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Typography,
  Button,
  ButtonGroup,
} from '@mui/material';
import { Sheet, Header, FreeSpace, Title } from '../../common/Forms';
import { useQuestionsList, Views } from './useQuestionsList';
import { formatDateTime } from '../../common/dateUtil';


export const QuestionsList = () => {
  const { items, isLoading, update, view, setView, exportQuestions } = useQuestionsList();

  return (
    <Sheet>
      <Header>
        <Title>Questions</Title>
        <FreeSpace />
        {isLoading && <CircularProgress />}
        <ButtonGroup variant="outlined" size="small">
          {(["unprocessed", "processed", "all"] as Views[]).map(v => (
            <Button key={v} variant={view === v ? "contained" : "outlined"} onClick={() => setView(v)}>{v}</Button>
          ))}
        </ButtonGroup>

        <Tooltip title="Export questions to Excel">
          <IconButton onClick={() => exportQuestions()}><GetAppOutlined /></IconButton>
        </Tooltip>
      </Header>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key="email"> Email </TableCell>
              <TableCell key="fullname"> Name </TableCell>
              <TableCell key="question"> Question </TableCell>
              <TableCell key="created_datetime"> Created at </TableCell>
              <TableCell key="actions" width={100}> Actions </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map(q => (
                <TableRow key={q.id}>
                  <TableCell key="email">
                    <Link href={`mailto:${q.email}`}>
                      {q.email}
                    </Link>
                  </TableCell>
                  <TableCell key="fullname">
                    {q.fullname}
                  </TableCell>
                  <TableCell key="question">
                    {(q.question || "").split("\n").map((line,i) => <Typography key={i}>{line}</Typography>)}
                  </TableCell>
                  <TableCell key="created_datetime">
                    {formatDateTime(q.created_datetime)}
                  </TableCell>

                  <TableCell key="actions">
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center"}}>
                      <Tooltip key="process" title={q.is_processed ? "Mark as new" : "Mark as processed"}>
                        <IconButton
                          onClick={() => update(q.id, { is_processed: !q.is_processed })}
                          color={q.is_processed ? "primary" : "inherit"}
                          size="small">
                          {q.is_processed ? <Check /> : <RadioButtonUncheckedOutlined />}
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Sheet>
  );
}
