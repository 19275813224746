import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, List, ListItem, ListItemButton, useTheme } from '@mui/material';
import { useEventContext } from './EventContext';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export const EventPicker = () => {
  const { currentEvent, available, selectEvent } = useEventContext();
  const [isSelecting, setIsSelecting] = useState<boolean>(false);

  const theme = useTheme();
  const navigate = useNavigate();
  
  return (
    <>
      <Button
        variant="contained"
        color={currentEvent ? "primary" : undefined}
        sx={{
          "& .label": {
            textOverflow: "ellipsis",
            overflow: "hidden",
            maxWidth: "100%",
            whiteSpace: "nowrap",
          }
        }}
        onClick={() => {
          if(available.events.length) {
            setIsSelecting(true)
          } else {
            navigate("/events");
          }
        }}
        >
          {currentEvent && <span className="label">{currentEvent.name}</span>}
          {!currentEvent && available.events.length > 0 && <span className="label">select event</span>}
          {!currentEvent && available.events.length === 0 && <span className="label">create event</span>}
      </Button>

      <Dialog open={isSelecting} onClose={() => setIsSelecting(false)}>
        <DialogTitle>Select an event</DialogTitle>

        <List>
          {available.events.map(event => (
            <ListItem
              disablePadding
              key={event.id}
              onClick={() => { selectEvent(event); setIsSelecting(false); }}
              >
                <ListItemButton selected={currentEvent?.id === event.id} sx={{
                  "&.Mui-selected": {
                    color: "#ffffff",
                    background: theme.palette.primary.main,
                  }
                }}>
                  {event.name}
                  {currentEvent?.id === event.id && <Check />}
                </ListItemButton>                
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}
