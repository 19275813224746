import React from 'react';
import { Button, IconButton, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { PageComponentProps } from './types';
import styled from '@emotion/styled';
import { DeleteOutlined, Add } from '@mui/icons-material';
import { FormGrid } from '../../../common/Forms';

interface AgendaItem {
  title: string;
  time: string;
  subtitle: string
  description: string;
}

interface AgendaDay {
  title: string;
  items: AgendaItem[];
}

const useEditAgenda = (props: PageComponentProps) => {
  const agenda = (props.component.properties?.agenda || []) as AgendaDay[];
  const update = (changed: AgendaDay[]) => props.data.updateComponentProperties({ agenda: changed });

  const updateDay = (dayIdx: number, changes: Partial<AgendaDay> | ((d: AgendaDay) => AgendaDay)) => {
    if(typeof changes === "function") {
      update(agenda.map((day, idx) => idx === dayIdx ? changes(day) : day));
    } else {
      update(agenda.map((day, idx) => idx === dayIdx ? { ...day, ...changes } : day));
    }
  }

  return {
    agenda,

    addDay: () => update([...agenda, { title: "", items: [] }]),
    removeDay: (dayIdx: number) => update(agenda.filter((d,idx) => idx !== dayIdx)),
    updateDay,

    addItem: (dayIdx: number) => updateDay(
      dayIdx, d => ({ ...d, items: [...d.items, { title: "", subtitle: "", time: "", description: "" }]})),
    updateItem: (dayIdx: number, itemIdx: number, changes: Partial<AgendaItem>) => updateDay(
      dayIdx, d => ({ ...d, items: d.items.map((item, idx) => idx === itemIdx ? { ...item, ...changes } : item)})),
    removeItem: (dayIdx: number, itemIdx: number) => updateDay(
      dayIdx, d => ({ ...d, items: d.items.filter((item, idx) => idx !== itemIdx)})),
  }
}

const DayWrapper = styled.div`
  border-bottom: 1px solid ${props => (props.theme as Theme).palette.grey[200]};
  margin: 0.5rem 0;

  & .actions {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;

const ItemsWrapper = styled.div`
  & > .item {
    margin-bottom: 1rem;

    & .idx-and-actions {
      display: flex;
      flex-flow: column;
      grid-row: span 2;
    }

    & .delete {
      transform: scale(0.75);
      margin-left: -10px;
    }
  }
`;

export const AgendaComponent = (props: PageComponentProps) => {
  const data = useEditAgenda(props);

  return (
    <>
      {data.agenda.map((day,dayIdx) => (
        <DayWrapper key={dayIdx}>
          <FormGrid columns="1fr 2fr max-content">
            <TextField
              label="Day"
              value={day.title || ""}
              onChange={e => data.updateDay(dayIdx, { title: e.target.value })}
              />

            <div />

            <div key="actions">
              <Tooltip title="Delete day">
                <IconButton size="small" onClick={() => data.removeDay(dayIdx)}>
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </div>
          </FormGrid>

          <ItemsWrapper>
            <Typography variant="h6">Items</Typography>
            {day.items.map((item, itemIdx) => (
              <FormGrid key={itemIdx} className="item" columns="max-content 1fr 2fr 2fr">
                <div key="idx-and-actions" className="idx-and-actions">
                  <Typography>{itemIdx+1}.</Typography>
                  <Tooltip title="Delete item" className="delete">
                    <IconButton size="small" onClick={() => data.removeItem(dayIdx, itemIdx)}>
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </div>

                <TextField
                  key="time"
                  label="Time"
                  value={item.time || ""}
                  onChange={e => data.updateItem(dayIdx, itemIdx, { time: e.target.value })}
                  />

                <TextField
                  key="title"
                  label="Title"
                  value={item.title || ""}
                  onChange={e => data.updateItem(dayIdx, itemIdx, { title: e.target.value })}
                  />

                <TextField
                  key="subtitle"
                  label="Subtitle"
                  value={item.subtitle || ""}
                  onChange={e => data.updateItem(dayIdx, itemIdx, { subtitle: e.target.value })}
                  />
                
                <TextField
                  key="description"
                  label="Description"
                  value={item.description || ""}
                  style={{ gridColumn: "span 3" }}
                  multiline
                  onChange={e => data.updateItem(dayIdx, itemIdx, { description: e.target.value })}
                  />

                
              </FormGrid>
            ))}
          </ItemsWrapper>

          <div className="actions">
            <Tooltip title="add item">
              <IconButton onClick={() => data.addItem(dayIdx)}>
                <Add />
              </IconButton>
            </Tooltip>
          </div>
        </DayWrapper>
      ))}
      
      <Button onClick={data.addDay}>add day</Button>
    </>
  );
}
