import { useState } from "react";
import SwissConfApi from "../../api/ApiHelper";
import { Question } from "../../api/types";
import { useEventContext } from "../EventContext";
import { ListData, useLoadedList } from "../Pages/useLoadedList";
import ExcelJS from "exceljs";
import { saveFile } from "../../api/files";
import { toLocal } from "../../common/dateUtil";

export type Views = "all" | "processed" | "unprocessed";

const exportQuestions = (questions: Question[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet('Questions');
  sheet.columns = [
    { header: "Email", key: "email", width: 24 },
    { header: "Name", key: "fullname", width: 24 },
    { header: "Question",  key: "question" },
    { header: "Created at", key: "created_datetime" },
  ];

  questions.forEach(q => sheet.addRow({ ...q, created_datetime: toLocal(q.created_datetime) }));

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => saveFile(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "questions.xlsx"));
}

export interface QuestionsListData extends ListData<Question> {
  update: (id: number, changes: Pick<Question, "is_processed">) => void;
  view: Views;
  setView: (v: Views) => void;
  exportQuestions: () => void;
}

export const useQuestionsList = (): QuestionsListData => {
  const { currentEvent } = useEventContext();
  const [view, setView] = useState<Views>("unprocessed");

  const data = useLoadedList<Question, [number, Views]>({
    keys: [currentEvent?.id || -1, view],
    noLoad: !currentEvent,
    load: ([eventId, view]) => SwissConfApi.getAllQuestions(eventId, view),
  });

  const update = (id: number, changes: Pick<Question, "is_processed">) => {
    if(!currentEvent) {
      throw new Error("no event");
    }

    SwissConfApi.updateQuestion(currentEvent.id, id, changes)
      .then(() => data.reload());
  }

  return {
    ...data,
    update,
    view,
    setView,
    exportQuestions: () => exportQuestions(data.items),
  };
}