import React, { ReactNode, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface ActionWithConfirmationConfig {
  title?: ReactNode;
  content?: ReactNode;
  confirmLabel?: ReactNode;
  action: () => Promise<any>;
}

interface Props extends Omit<ActionWithConfirmationConfig, "content"> {
  children?: ReactNode;
  isOpen: boolean;
  close: () => void;
}

export const ConfirmationDialog = (props: Props) => {
  const { isOpen, close, title, children, confirmLabel, action } = props;
  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle>{title || "Confirm action"}</DialogTitle>

      <DialogContent>
        {children}
      </DialogContent>

      <DialogActions>
        <Button color="inherit" onClick={close}>cancel</Button>
        <Button variant="contained" onClick={() => action().then(close)}>{confirmLabel || "confirm"}</Button>
      </DialogActions>
    </Dialog>
  );
}

export interface ActionWithConfirmation {
  dialog: ReactNode;
  start: () => void;
}

export const useActionWithConfirmation = (config: ActionWithConfirmationConfig) => {
  const [isActive, setIsActive] = useState<boolean>(false);

  const dialog = (
    <ConfirmationDialog
      isOpen={isActive}
      close={() => setIsActive(false)}
      action={config.action}
      title={config.title}
      confirmLabel={config.confirmLabel}>
      {config.content}
    </ConfirmationDialog>
  )

  return {
    start: () => setIsActive(true),
    dialog,
  }
}
