import { ProgramItem } from "../../api/types";
import { parseISO, set as dateSet, format } from "date-fns";
import { DateTimeInternalSubIsoFormat } from "../../common/dateUtil";

export const programItemsSortValue = (item: ProgramItem) => {
  const datePart = item.date ? parseISO(item.date) : new Date(1990, 0, 1);
  const timePart = item.time ? parseISO(item.time) : new Date(1990, 0, 1, 0, 0, 0);
  return dateSet(timePart, { year: datePart.getFullYear(), month: datePart.getMonth(), date: datePart.getDate() }).getTime();
}

export const offsetDateZone = (date: Date, direction: 1 | -1) => {
  return new Date(date.getTime() + direction * date.getTimezoneOffset()*60000);
}

export const timeLocalToUtc = (time: string | Date | null | undefined, timeOnly?: boolean) => {
  if(!time) {
    return null;
  } else if(typeof time === 'string' || time instanceof String) {
    try {
      const asDateNbr = Date.parse(time as string);
      const asDate = new Date(asDateNbr);
      return format(asDate, DateTimeInternalSubIsoFormat) + "Z";
    } catch(e) {
      return null;
    }
  } else {
    return format(time, DateTimeInternalSubIsoFormat) + "Z";
  }
}

export const timeUtcToLocal = (time: string | null | undefined) => {
  if(!time) {
    return null;
  } else {
    return new Date(Date.parse(time.substring(0, 16) + ":00.000"));
  }
}