import React from 'react';
import { DeleteOutlined, Save } from '@mui/icons-material';
import { CircularProgress, MenuItem, IconButton, InputLabel, TextField, Select, ListItemText, FormControl, Typography, TextFieldProps, Checkbox } from '@mui/material';
import { Event } from '../api/types';
import { FormGrid, FreeSpace, Header, SectionTitle, Sheet, Title } from '../common/Forms';
import { useEditEvent } from './useEditEvent';
import { useNavigate, useParams } from 'react-router-dom';
import { useActionWithConfirmation } from '../common/ConfirmationDialog';
import { UsersListData } from '../users/useUsersList'
import { MenuEditor } from './MenuEditor';
import { Checkbox as CheckboxX } from '../common/Checkbox';
import { TabPanel, TabsHeader, useTabsState } from '../common/Tabs';
import { FormFieldsEditor } from './contactforms/FormFieldsEditor';
import { useEditFields } from './contactforms/useEditContactForm';
import { AttendanceTrackingForm } from './AttendanceTrackingForm';


interface Props {
  users: UsersListData
}


export const EventEditForm = (props : Props) => {
  const { users } = props.users
  const { id } = useParams<{ id: string }>();
  const event_id = Number(id)
  const eventData = useEditEvent(event_id || -1);
  const { data: event, isLoading, hasChanges, update, save, remove, menu  } = eventData;
  const removeEvent = useActionWithConfirmation({
    title: "Delete Event?",
    confirmLabel: "delete forever",
    action: () => remove().then(() => navigate("/events")),
  });

  const editFields = useEditFields(event.registration_fields || [], updated => update({ registration_fields: updated }));

  const navigate = useNavigate();

  const eventTextField = (field: keyof Event, label: string, extraProps?: Partial<TextFieldProps>) => (
    <TextField
      label={label}
      value={event[field] || ""}
      onChange={e => update({ [field]: e.target.value })}
      {...(extraProps || {})}
      />
  )

  const eventAdmins = event.admins || [];

  const tabs = useTabsState([
    ["menu", "Menu"],
    ["registration", "Registration"],
    ["attendance", "Attendance tracking"],
  ], "menu", "tab");
  

  return (
    <Sheet>
      <Header>
        <Title>Event</Title>
        <FreeSpace />
        
        {isLoading && <CircularProgress />}
        {hasChanges && <IconButton size="small" onClick={() => save()}><Save /></IconButton>}
        

        <IconButton onClick={removeEvent.start}><DeleteOutlined /></IconButton>
        {removeEvent.dialog}
      </Header>
      
      <FormGrid>
        {eventTextField("name", "Name")}
        {eventTextField("title", "Title")}
        <FormControl variant="standard">
          <InputLabel variant="standard" id="selectEventAdmin">Select event admins</InputLabel>
          <Select
            labelId="selectEventAdmin"
            multiple
            value={eventAdmins}
            onChange={e => update({ admins: (e.target.value as string[]).filter(x => !!x) })}
            renderValue={(selected) => selected.join(', ')}
            >
              {users.map(({ username }) => (
                <MenuItem key={username} value={username}>
                  <Checkbox checked={eventAdmins.includes(username)} />
                  <ListItemText primary={username} />
                </MenuItem>
            ))}
          </Select>
        </FormControl>
        {eventTextField("mail_sender_name", "E-mail sender name", { placeholder: "D-conf" })}

        {eventTextField("description", "Description", { multiline: true, style: { gridColumn: "span 3" }})}
   
      </FormGrid>

      <TabsHeader state={tabs} scrollable />

      <TabPanel state={tabs.forTab("menu")}>
        <FormGrid columns="1fr 1fr" style={{ gap: "2rem" }}>
          <div>
            <Typography variant="h6">Primary</Typography>
            <MenuEditor
              key="primary"
              data={menu}
              menuType="primary"
              />
          </div>

          <div>
            <Typography variant="h6">Navbar</Typography>
            
            {!menu.menu.navbar_same_as_primary && <MenuEditor
              key="navbar"
              data={menu}
              menuType="navbar"
              />}

            <CheckboxX
              checked={menu.menu.navbar_same_as_primary || false}
              onChange={v => menu.updateSetting({ navbar_same_as_primary: v })}
              label="Same as primary"
              />
          </div>
        </FormGrid>
      </TabPanel>

      <TabPanel state={tabs.forTab("registration")}>
        <FormGrid>
          <CheckboxX
            key="is_registration_required"
            checked={event.is_registration_required || false}
            onChange={v => update({ is_registration_required: v })}
            label="Attendee registration required"
            />

          {event.is_registration_required &&
            <CheckboxX
              key="is_require_attendee_approval"
              checked={event.is_require_attendee_approval || false}
              onChange={v => update({ is_require_attendee_approval: v })}
              label="Require manual attendee approval"
              />}
        </FormGrid>

        <SectionTitle>Fields</SectionTitle>
        <FormFieldsEditor
          allowedFieldTypes={["text", "textlong", "email", "password", "select", "checkbox"]}
          getFieldPermissions={f => {
            if(["email", "password", "password_repeat"].includes(f)) {
              return { noDelete: true, noEditName: true, noEditRequired: true, noEditType: true };
            } else {
              return {};
            }
          }}
          {...editFields}
          />
      </TabPanel>
      

      <TabPanel state={tabs.forTab("attendance")}>
        <AttendanceTrackingForm eventData={eventData} />
      </TabPanel>
    </Sheet>
  );
}
