import { AxiosError } from "axios";
import { useState } from "react"
import { useNavigate } from "react-router-dom";
import SwissConfApi from "../api/ApiHelper";
import { useUserContext } from "./UserContext";

export interface Credentials {
    username: string;
    password: string;
}

export interface LoginData {
    credentials: Credentials;
    update: (changes: Partial<Credentials>) => void;

    submit: () => Promise<void>;
    errors: string[];
    isLoading: boolean;
}

export const useLoginForm = (): LoginData => {
    const userContext = useUserContext();
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState<Credentials>({ username: "", password: "" });
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<string[]>([])

    const submit = async () => {
        setIsLoading(true);

        try {
            const token = await SwissConfApi.loginUser(credentials);
            userContext.login(token);
            navigate("/");
            setIsLoading(false);
        } catch(e) {
            setIsLoading(false);
            const x = e as AxiosError;
            setErrors([x.message]);
        }
    }

    return {
        credentials,
        update: (changes) => setCredentials(existing => ({ ...existing, ...changes })),
        submit,
        errors,

        isLoading,
    };
}
