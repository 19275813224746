import SwissConfApi from "../../api/ApiHelper";
import { NewProgram, Program } from "../../api/types";
import { useEventContext } from "../EventContext";
import { ListData, useLoadedList } from "../Pages/useLoadedList";

export interface ProgramsListData extends ListData<Program> {
  create: (program: NewProgram) => void;
}

export const useProgramsList = (): ProgramsListData => {
  const { currentEvent } = useEventContext();
  const data = useLoadedList<Program, number>({
    keys: currentEvent?.id || -1,
    noLoad: !currentEvent,
    load: eventId => SwissConfApi.request(`/events/${eventId}/programs`),
  });

  const create = (program: NewProgram) => {
    if(!currentEvent) {
      throw new Error("no event");
    }

    SwissConfApi.request(`/events/${currentEvent.id}/programs`, program, "post")
      .then(() => data.reload());
  }

  return {
    ...data,
    create,
  };
}
