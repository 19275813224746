import { Add, DeleteOutlined, Save } from "@mui/icons-material";
import { Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import React from "react";
import { FormGrid, FreeSpace, Header, SectionTitle, Sheet, Title } from "../../common/Forms";
import { TabPanel, TabsHeader, useTabsState } from "../../common/Tabs";
import { EmailTemplateEditor } from "../Emails/EmailTemplateEditor";
import { AddBlockDialog } from "./components/AddBlockDialog";
import { ScheduledMeetings } from "./components/ScheduledMeetings";
import { SponsorsEditor } from "./components/SponsorsEditor";
import { TimeBlockedEditor } from "./components/TimeBlockedEditor";
import { useEditMeetingBookingConfig } from "./useEditMeetingBookingConfig";


const MeetingFieldsForTemplates = ["meeting_id", "code", "sponsor.title", "sponsor.logo_url", "room.title", "customer_name", "customer_email", "date", "start_time", "end_time", "representative.name", "representative.email"];

const TemplateHint = () => {
  return (
    <Typography variant="caption" color="GrayText">
      Available variables: {MeetingFieldsForTemplates.map(f => `{{${f}}}`).join(", ")}
    </Typography>
  )
}

export const MeetingBookingsPage = () => {
  const data = useEditMeetingBookingConfig();

  const tabs = useTabsState([
    ["config", "Configuration"],
    ["rooms", "Rooms"],
    ["sponsors", "Sponsors"],
    ["confirmation", "Confirmation"],
    ["reminder", "Reminder"],
    ["cancellation", "Cancellation"],
    ["meetings", "Meetings"],
  ], "config", "tab");
  
  return (
    <Sheet>
      <Header>
        <Title>Meetings Booking</Title>
        <FreeSpace />
        
        {tabs.current === "meetings" && <Button size="small" onClick={() => data.meetings.exportMeetings(data.data)}>export meetings</Button>}
        {data.isLoading && <CircularProgress />}
        {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><Save /></IconButton>}
      </Header>
      
      <TabsHeader state={tabs} noMargin scrollable />

      <TabPanel state={tabs.forTab("config")}>
        <FormGrid>
          <TextField key="meeting_slot_size" label="Meeting time (minutes)" value={data.data.meeting_slot_size || 0} onChange={(e)=> data.update({ meeting_slot_size: +e.target.value })} />
          <TextField key="slack_time" label="Room cleaning time (minutes)" value={data.data.slack_time || 0} onChange={(e)=> data.update({ slack_time: +e.target.value })} />
          <TextField key="timezone" label="Timezone" value={data.data.timezone || 0} onChange={(e)=> data.update({ timezone: e.target.value })} />
        </FormGrid>
        
        <SectionTitle>Dates</SectionTitle>
        
        <FormGrid columns="150px 70px 70px max-content" style={{ alignItems: "end", margin: "0.5rem 0" }}>
          <Typography variant="caption" color="GrayText">Date</Typography>
          <Typography variant="caption" color="GrayText">Start time</Typography>
          <Typography variant="caption" color="GrayText">End time</Typography>
          <div />

          {data.data.dates_available.map(d => <React.Fragment key={d._id || ""}>
            <TextField value={d.date} onChange={(e)=> data.dates.updateDate(d._id || "", { date: e.target.value })} />
            <TextField value={d.start_time} onChange={(e)=> data.dates.updateDate(d._id || "", { start_time: e.target.value })} />
            <TextField value={d.end_time} onChange={(e)=> data.dates.updateDate(d._id || "", { end_time: e.target.value })} />
            <IconButton size="small" onClick={() => data.dates.removeDate(d._id || "")}><DeleteOutlined /></IconButton>
          </React.Fragment>)}
        </FormGrid>

        <Button size="small" onClick={() => data.dates.addDate()} startIcon={<Add />}>add date</Button>
      </TabPanel>

      <TabPanel state={tabs.forTab("rooms")}>
        <FormGrid columns="200px 1fr max-content" style={{ alignItems: "end", margin: "1rem 0 0.5rem" }}>
          <Typography variant="caption" color="GrayText">Room name</Typography>
          <Typography variant="caption" color="GrayText">Blocked time</Typography>
          <div />

          {data.rooms.rooms.map(r => <React.Fragment key={r._id || ""}>
            <TextField value={r.title} onChange={(e)=> data.rooms.updateRoom(r._id || "", { title: e.target.value })} />
            
            <TimeBlockedEditor
              blocks={data.meetings.getBlocksForRoom(r._id)}
              removeBlock={b => data.meetings.removeBlock(b)}
              startAddBlock={() => data.meetings.startAddBlock({ room_id: r._id })}
              />
            
            <IconButton size="small" onClick={() => data.rooms.removeRoom(r._id || "")}><DeleteOutlined /></IconButton>
          </React.Fragment>)}
        </FormGrid>

        <Button size="small" onClick={() => data.rooms.addRoom()} startIcon={<Add />}>add room</Button>
      </TabPanel>

      <TabPanel state={tabs.forTab("sponsors")}>
        <SponsorsEditor data={data.sponsors} meetings={data.meetings} />
      </TabPanel>


      <TabPanel state={tabs.forTab("meetings")}>
        <ScheduledMeetings config={data.data} meetings={data.meetings} />
      </TabPanel>


      <TabPanel state={tabs.forTab("confirmation")}>
        <EmailTemplateEditor
          header={
            <div>
              <Typography variant="body2">The confirmation email and calendar invite are sent to the customer and the sponsor representative, when a meeting is scheduled</Typography>
              <TemplateHint />
            </div>}
          data={data.confirmationEmail}
          />
      </TabPanel>

      <TabPanel state={tabs.forTab("cancellation")}>
        <FormGrid style={{ marginBottom: "3em" }}>
          <TextField
            label="Cancellation question"
            placeholder="Please confirm you want to cancel the meeting"
            value={data.data.cancellation_confirmation_question || ""}
            onChange={(e)=> data.update({ cancellation_confirmation_question: e.target.value })}
            />
          <TextField
            label="Cancellation button"
            placeholder="Confirm"
            value={data.data.cancellation_confirmation_button || ""}
            onChange={(e)=> data.update({ cancellation_confirmation_button: e.target.value })}
            />
          <TextField
            label="Cancellation confirmation"
            placeholder="Your meeting was cancelled. You may schedule a new one if needed."
            value={data.data.cancellation_confirmation_done || ""}
            onChange={(e)=> data.update({ cancellation_confirmation_done: e.target.value })}
            />
          
        </FormGrid>

        <EmailTemplateEditor
          header={
            <div>
              <Typography variant="body2">The cancellation email is sent to the customer and the sponsor representative, when the meeting is cancelled by the customer or the admin</Typography>
              <TemplateHint />
            </div>}
          data={data.cancellationEmail}
          />
          <TemplateHint />
      </TabPanel>

      <TabPanel state={tabs.forTab("reminder")}>
        <EmailTemplateEditor
          header={
            <div>
              <Typography variant="body2">The reminder email is sent to the customer and the sponsor representative on the day of the meeting</Typography>
              <TemplateHint />
            </div>}
          data={data.reminderEmail}
          />
          <TemplateHint />
      </TabPanel>
      

      <AddBlockDialog
        addBlock={b => data.meetings.addBlock(b)}
        block={data.meetings.addedBlock}
        isOpen={!!data.meetings.addedBlock}
        update={data.meetings.updateAddedBlock}
        close={data.meetings.cancelAddBlock}
        isAdding={data.meetings.isSavingBlock}
        />
    </Sheet>
  );
}
