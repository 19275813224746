import { useState } from "react";
import { AttendeeUser } from "../../api/types";
import { AttendeeListData } from "./useAttendeeList";
import ExcelJS from "exceljs";

export const useUploadAttendees = (data: Pick<AttendeeListData, "create" | "extraFields">) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isPlacingFile, setIsPlacingFile] = useState<boolean>(false);
  const [uploaded, setUploaded] = useState<Partial<AttendeeUser>[]>([]);

  const cancel = () => {
    setIsActive(false);
    setIsPlacingFile(false);
    setUploaded([]);
  }

  const save = () => {
    if(uploaded.length) {
      data.create(uploaded).then(() => cancel());
    }
  }

  const processXLSX = (file: File) => {
    const reader = new FileReader();
    reader.addEventListener("load", e => {
      if(e.target) {
        const workbook = new ExcelJS.Workbook();
        workbook.xlsx.load(e.target.result as ArrayBuffer).then(wb => {
          const sheet = wb.worksheets[0];
          const result: Partial<AttendeeUser>[] = [];
          sheet.eachRow((row, idx) => {
            if(idx > 1) {
              const attendee: Partial<AttendeeUser> = {
                email: row.getCell(1).text,
                password: row.getCell(2).text,
                first_name: row.getCell(3).text,
                last_name: row.getCell(4).text,
              };

              if(data.extraFields.length) {
                attendee.fields = {};
                data.extraFields.forEach((f,i) => {
                  // @ts-ignore
                  attendee[f.field] = row.getCell(5+i).text;
                });
              }

              result.push(attendee)
            }
          });
          setUploaded(result);
          setIsPlacingFile(false);
        });
      }
    })
    reader.readAsArrayBuffer(file);
  }

  return {
    isActive,
    isPlacingFile,
    uploaded,
    startUploading: () => { setIsActive(true); setIsPlacingFile(true); },
    processXLSX,
    save,
    cancel,
  }
}
