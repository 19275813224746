import styled from '@emotion/styled';
import { MeetingRaw } from '../types';
import { Button, Chip, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

interface Props {
  blocks: MeetingRaw[];
  startAddBlock: () => void;
  removeBlock: (b: MeetingRaw) => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
`;

export const TimeBlockedEditor = ({ blocks, startAddBlock, removeBlock }: Props) => {
  return (
    <Wrapper>
      {blocks.map(b => (
        <Chip
          label={`${b.date} ${b.start_time} - ${b.end_time}`}
          onDelete={() => removeBlock(b)}
          />
        ))}
      
      {blocks.length === 0
        ? <Button size="small" onClick={() => startAddBlock()} startIcon={<Add />}>block</Button>
        : <IconButton size="small" onClick={() => startAddBlock()}><Add /></IconButton>}
    </Wrapper>
  );
}
