import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { MoreHoriz, ArrowDownward, ArrowUpward, DeleteOutlined, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { Button, IconButton, Theme, Tooltip, Typography } from '@mui/material';
import { Container } from '../../api/types';
import { AddComponentButton } from './components/AddComponentButton';
import { LayoutOptions } from './LayoutOptions';
import { PageData } from './useEditPage';

const ContainerWrapper = styled.div<{ is_hidden?: boolean }>`
  background: ${props => (props.theme as Theme).palette.grey[100]};
  margin: 0.5rem 0;
  padding: 0.25rem 0.5rem;
  opacity: ${props => props.is_hidden ? 0.65 : 1};
`;

const ContainerHeader = styled.div`
  display: grid;
  grid-template-columns: 100px 40px 1fr max-content max-content max-content;
  grid-column-gap: 0.5rem;
  align-items: center;
  column-gap: 6px;
`;

const LayoutSelector = styled.div`
  & > * {
    margin-right: 0.5rem;
  }
`;

interface Props {
  data: PageData;
  containerIdx: number;
  container: Container;
  children: ReactNode;
  editContainer: (containerIdx: number) => void;
}

export const ComponentContainer = (props: Props) => {
  const { data, containerIdx, container, editContainer } = props;

  return (
    <ContainerWrapper is_hidden={container.properties?.is_hidden}>
      <ContainerHeader>
        <Typography variant="caption">Container {containerIdx+1}</Typography>

        <Tooltip title={container.properties?.is_hidden ? "show" : "hide"}>
          <IconButton size="small" onClick={() => data.updateContainerProperties(containerIdx, { is_hidden: !container.properties?.is_hidden })}>
            {container.properties?.is_hidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
          </IconButton>
        </Tooltip>

        <LayoutSelector>
          <Typography variant="caption">Layout:</Typography>
          {LayoutOptions.map(([layout, tooltip,label]) => (
            <Tooltip key={layout} title={tooltip}>
              <Button
                variant={layout === container.layout.columns ? "outlined" : undefined}
                onClick={() => data.updateContainerLayout(containerIdx, { columns: layout })}
                size="small">
                  {label}
              </Button>
            </Tooltip>
          ))}

          <Tooltip title="More options">
            <Button
              key="more"
              color="inherit"
              size="small"
              onClick={() => editContainer(containerIdx)}>
                <MoreHoriz />
            </Button>
          </Tooltip>
        </LayoutSelector>

        <Tooltip key="down" title="move container down">
          <IconButton size="small" onClick={() => data.moveContainer(containerIdx, "down")}><ArrowDownward /></IconButton>
        </Tooltip>
        <Tooltip key="up" title="move container up">
          <IconButton size="small" onClick={() => data.moveContainer(containerIdx, "up")}><ArrowUpward /></IconButton>
        </Tooltip>
        <Tooltip key="remove" title="delete container">
          <IconButton size="small" onClick={() => data.removeContainer(containerIdx)}><DeleteOutlined /></IconButton>
        </Tooltip>
      </ContainerHeader>

      {props.children}

      <AddComponentButton addComponent={ct => data.addComponent(containerIdx, ct)} />
    </ContainerWrapper>
  );
}
