import React, { useState, useEffect } from 'react';
import  { BrowserRouter } from 'react-router-dom';
import NavBar from './routes-nav/NavBar';
import { AppRoutes } from './routes-nav/Routes';
import SwissConfApi, { API_TOKEN_KEY } from './api/ApiHelper';
import {UserContext, User} from "./auth/UserContext";
import jwt_decode from "jwt-decode";
import useLocalStorage from "./hooks/useLocalStorage";
import { ThemeProvider, Grid } from '@mui/material';
import { Theme } from './Theme';
import { EventContextProvider } from './events/EventContext';
import TopNavBar  from './routes-nav/TopNavBar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';


const App = () => {

  const [currentUser, setCurrentUser] = useState < User | null> (null) ;
  const [token, setToken] = useLocalStorage(API_TOKEN_KEY);

  



  // useEffect(() =>{
  //    SwissConfApi.request("/api/events/2")
  //    .then ((result)=>{
  //     console.log(result)
  //    })
  // } ,[]) 

  useEffect(function makeApiRequestForUser() {
    async function fetchUser() {
      if (token) {
        try {
          const { username } = jwt_decode<User>(token);
          // put token on API class so it can make the request
          SwissConfApi.token = token;
          const currentUser = await SwissConfApi.getUser(username);
          setCurrentUser(currentUser);
        } catch (e) {
          console.error("App fetchUser: problem loading", e);
          setCurrentUser(null);
        }
      }
    }
    fetchUser();
  }, [token]);

  const login = (token: string) =>{
    setToken(token);
    console.log("<<<<<")
    console.log(jwt_decode<User>(token));
    console.log(">>>>>")
    setCurrentUser(jwt_decode<User>(token));
  };

  const logout = () => {
    
    setToken(null);
    setCurrentUser(null);
  

  };


  return (
    <div className="App">
      <ThemeProvider theme={Theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <UserContext.Provider value={{currentUser, login, logout }}>
              <EventContextProvider>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                  <TopNavBar logout={logout} />
                  </Grid>
                
                  <Grid item xs={2.5}>
                    <NavBar />
                  </Grid>
                  <Grid style={{marginTop: 80}}item xs={9.5}>
                    <AppRoutes />
                  </Grid>
                </Grid>
              </EventContextProvider>
            </UserContext.Provider>
          
          </BrowserRouter>
        </LocalizationProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
