import { Field } from "./contactforms";

export interface MenuConfigItem {
  page_id: number;
  menu_title?: string;
  show: boolean;
}

export interface MenuConfigItemWithPage extends MenuConfigItem {
  title: string;
  slug: string;
}

export interface Menu {
  primary: MenuConfigItemWithPage[];
  navbar: MenuConfigItemWithPage[];
  navbar_same_as_primary?: boolean;
}

export type MenuType = "primary" | "navbar";

export interface AttendanceTrackingSettings {
  email_request_button_label?: string;
  email_request_description?: string;
  email_request_disclaimer?: string;
}


export interface Event{
  id: number;
  name: string;
  title: string;
  description: string;
  admins: string[];
  menu?: Menu;
  mail_sender_name?: string;

  is_registration_required: boolean;
  is_require_attendee_approval: boolean;
  registration_fields?: Field[];
  default_unathorized_page?: number | null;
  is_attendance_tracking_enabled?: boolean;
  attendance_tracking_settings?: AttendanceTrackingSettings;
}

export const DefaultRegistrationFields = ["email", "password", "password_repeat", "first_name", "last_name"];
