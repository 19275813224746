import { useState } from 'react';
import styled from '@emotion/styled';
import { Button, Dialog, DialogActions, FormControl, FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';
import { ChromePicker, RGBColor,  } from 'react-color';
import { Close } from '@mui/icons-material';

interface ColorPickerProps {
  label: string;
  hint?: string;
  value?: string;
  update: (c: string | undefined) => void;
  disableClear?: boolean;
}

const ColorPreview = styled.div<{ color?: string }>`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 2px 0;
  background: ${props => props.color || "transparent"};
  border-radius: 2px;
`;

const toHexItem = (n?: number) => (n || 0).toString(16).padStart(2,"0");

const rgbaToString = (c: RGBColor | undefined | null): string | undefined | null => {
  if(!c) {
    return c;
  }

  const a = (c.a === undefined || c.a === 1) ? "" : toHexItem(Math.round(c.a * 255));

  return `#${toHexItem(c.r)}${toHexItem(c.g)}${toHexItem(c.b)}${a}`;
}

const stringToRgba = (c: string | undefined | null): RGBColor | undefined | null => {
  if(!c) {
    return undefined;
  }

  const [r,g,b,a] = c.substring(1).match(/.{1,2}/g) || ["ff", "ff", "ff"];
  return {
    r: parseInt(r, 16),
    g: parseInt(g, 16),
    b: parseInt(b, 16),
    a: a ? parseInt(a, 16) / 255.0 : undefined,
  }      
}

export const ColorPicker = ({ hint, label, value, update, disableClear }: ColorPickerProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentColor, setCurrentColor] = useState<RGBColor | undefined | null>(stringToRgba(value));

  const startEdit = () => {
    setCurrentColor(stringToRgba(value));
    setIsEditing(true);
  }

  const completeEdit = () => {
    setIsEditing(false);
    console.log(currentColor);
    update(rgbaToString(currentColor) || undefined);
  }

  const cancelEdit = () => {
    setIsEditing(false);
  }

  return (
    <FormControl variant="standard">
      <InputLabel htmlFor={`color-${label}`}>{label}</InputLabel>
      <Input
        type="text"
        value={value || ""}
        onClick={startEdit}
        startAdornment={(
          <InputAdornment position="start">
            <ColorPreview color={value || "transparent"} />
          </InputAdornment>
        )}
        endAdornment={!disableClear && (
          <InputAdornment position="end">
            <IconButton size="small" onClick={e => { e.stopPropagation(); update(undefined); }}>
              <Close />
            </IconButton>
          </InputAdornment>
        )}
        />
        {hint && <FormHelperText>{hint}</FormHelperText>}
        <Dialog open={isEditing} onClose={startEdit}>
          <ChromePicker
            color={currentColor || undefined}
            onChange={c => setCurrentColor(c.rgb)}
            styles={{ default: { picker: { boxShadow: "none" }} }}
            />
          <DialogActions>
            <Button onClick={cancelEdit}>cancel</Button>
            <Button color="primary" variant="contained" onClick={completeEdit}>save</Button>
          </DialogActions>
        </Dialog>
    </FormControl>)
}