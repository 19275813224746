import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { Container, ContainerProperties } from "../../api/types";
import { Checkbox } from "../../common/Checkbox";
import { ColorPicker } from "../../common/ColorPicker";
import { FormGrid } from "../../common/Forms";
import { Select } from "../../common/Select";
import { MediaItemPicker } from "../media";

interface Props {
  isOpen: boolean;
  close: () => void;
  container?: Container;
  updateContainer: (changes: Partial<Container>) => void;  
  updateProperties: (changes: Partial<ContainerProperties>) => void;  
}

export const EditContainerDialog = (props: Props) => {
  const { isOpen, close, container, updateContainer, updateProperties } = props;

  return (
    <Dialog open={isOpen} onClose={close} maxWidth="md" fullWidth>
      <DialogTitle>
        Edit container
      </DialogTitle>

      {container && <DialogContent>
        <FormGrid key="ids" columns="repeat(4,1fr)" style={{ alignItems: "end", marginBottom: "1.5rem" }}>
          <TextField
            key="id"
            value={container.properties?.id || ""}
            onChange={e => updateProperties({ id: e.target.value })}
            label="ID"
            />

          <TextField
            key="classes"
            value={container.classes || ""}
            onChange={e => updateContainer({ classes: e.target.value })}
            label="CSS classes"
            />

          <div />

          <Checkbox
            key="full_width"
            checked={container.properties?.full_width || false}
            onChange={v => updateProperties({ full_width: v})}
            label="Full width"
            />

        </FormGrid>

        <FormGrid key="basic" columns="repeat(4,1fr)" style={{ alignItems: "end", marginBottom: "1.5rem" }}>
          <TextField
            key="height"
            value={container.properties?.height || ""}
            onChange={e => updateProperties({ height: e.target.value })}
            label="Height"
            />

          <TextField
            key="vertical_padding"
            value={container.properties?.vertical_padding || ""}
            onChange={e => updateProperties({ vertical_padding: e.target.value })}
            label="Vertical padding"
            />

          <Select
            key="align_items"
            values={[["", "-"], ["start", "start"], ["center", "center"], ["end", "end"]]}
            value={container.properties?.align_items || ""}
            onChange={e => updateProperties({ align_items: e.target.value as string })}
            label="Align items"
            />

          <TextField
            key="columns_spacing"
            value={container.properties?.columns_spacing || ""}
            onChange={e => updateProperties({ columns_spacing: e.target.value })}
            label="Columns spacing"
            />

          
        </FormGrid>

        <FormGrid key="background" columns="2fr repeat(2,1fr)">
          <MediaItemPicker
            key="background_image_url"
            label="Background image"
            selectedUrl={container.properties?.background_image_url || ""}
            setSelectedUrl={url => updateProperties({ background_image_url: url })}
            />

          <ColorPicker
            key="background_image_overlay_color"
            label="Image shade color"
            value={container.properties?.background_image_overlay_color || ""}
            update={color => updateProperties({ background_image_overlay_color: color })}
            />

          <ColorPicker
            key="background_color"
            label="Background color"
            value={container.properties?.background_color || ""}
            update={color => updateProperties({ background_color: color })}
            />
          
        </FormGrid>
      </DialogContent>}

      <DialogActions>
        <Button variant="contained" onClick={close}>close</Button>
      </DialogActions>
    </Dialog>
  );
}
