import React from 'react';
import styled from '@emotion/styled';
import { FormGrid } from '../../common/Forms';
import { Speaker } from '../../api/types';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { MediaItemPicker } from '../media';
import { RichTextEditor } from '../Pages/components/RichTextEditor';

const SpeakerGrid = styled(FormGrid)`
  padding-left: 20px;
  border-left: 2px solid ${props => props.theme.palette.primary.light};
  margin: 0.5rem 0;

  & .demo-wrapper {
    grid-column: 1 / span 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);

    .public-DraftEditorPlaceholder-root {
      color: rgba(0, 0, 0, 0.68);

    }

    & .rdw-editor-main {
      height: auto;
    }
  }
`;

interface Props {
  speaker: Speaker;
  update: (id: string, changes: Partial<Speaker>) => void;
  remove: (id: string) => void;
}

export const SpeakerEditor = (props: Props) => {
  const { speaker, update, remove } = props;
  return (<>
    <SpeakerGrid columns="1fr 100px">
      <FormGrid columns="1fr 1fr">
        <TextField
          label="Name"
          variant="standard"
          value={speaker.name || ""}
          onChange={e => update(speaker.id, { name: e.target.value })}
          InputProps={{
            endAdornment: <InputAdornment position="end"><IconButton size="small" onClick={() => remove(speaker.id)}><DeleteOutlined /></IconButton></InputAdornment>
          }}
          />
        <TextField
          label="Company / position"
          variant="standard"
          value={speaker.job || ""}
          onChange={e => update(speaker.id, { job: e.target.value })}
          />
        <TextField
          label="URL"
          variant="standard"
          value={speaker.speaker_url || ""}
          onChange={e => update(speaker.id, { speaker_url: e.target.value })}
          style={{ gridColumn: "span 2"}}
          />
      </FormGrid>
      
      <MediaItemPicker
        selectedUrl={speaker.photo_url}
        setSelectedUrl={url => update(speaker.id, { photo_url: url })}
        noHint
        style={{ width: "100px", height: "100px" }}
        imageStyle={{ width: "100px", height: "100px", objectFit: "cover" }}
        />
      
      <RichTextEditor
        toolbarHidden
        placeholder="Speaker CV"
        value={speaker.description}
        update={v => update(speaker.id, { description: v })} />

    </SpeakerGrid>
    </>);
}
