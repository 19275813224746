import styled from "@emotion/styled";
import { ArrowDownward, ArrowUpward, Close, GridViewOutlined, VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { ReactNode } from "react";
import { Component } from "../../../api/types";
import { FreeSpace } from "../../../common/Forms";
import { OptionsMap } from "./Options";
import { PageComponentProps } from "./types";

export const ComponentPaper = styled(Paper)<{ is_hidden?: boolean }>`
  padding: 0.5rem;
  margin: 0.5rem 0;
  opacity: ${props => props.is_hidden ? 0.65 : 1};
`;


export const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  
  & > :nth-child(1), & > :nth-child(2) {
    padding-right: 0.5rem;
  }

  & .action {
    transform: scale(0.75);
  }
`;

interface HeaderProps extends PageComponentProps {
  icon?: ReactNode;
  title?: string;
  startPlaceComponent: (sourceComponent: Component, sourceContainerIdx: number, sourceComponentIdx: number) => void;
}

export const ComponentHeader = (props: HeaderProps) => {
  const { dataRaw: data, icon, title, containerIdx, componentIdx, component, startPlaceComponent } = props;
  const { component_type } = component;

  return (
    <HeaderWrapper>
      {icon || OptionsMap[component_type].icon || <GridViewOutlined />}
      <Typography variant="caption">
        {title || OptionsMap[component_type].label || component_type}
      </Typography>

      <Tooltip title={component.properties?.is_hidden ? "show" : "hide"}>
        <IconButton className="action" size="small" onClick={() => data.updateComponentProperties(containerIdx, componentIdx, { is_hidden: !component.properties?.is_hidden })}>
          {component.properties?.is_hidden ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
        </IconButton>
      </Tooltip>

      <FreeSpace />

      <Tooltip key="place" title="move component">
        <IconButton className="action" size="small" style={{ transform: "rotate(45deg) scale(0.75)"}} onClick={() => startPlaceComponent(component, containerIdx, componentIdx)}>
          <ArrowUpward />
        </IconButton>
      </Tooltip>
      <Tooltip key="down" title="move component down">
        <IconButton className="action" size="small" onClick={() => data.moveComponent(containerIdx, componentIdx, "down")}>
          <ArrowDownward />
        </IconButton>
      </Tooltip>
      <Tooltip key="up" title="move component up">
        <IconButton className="action" size="small" onClick={() => data.moveComponent(containerIdx, componentIdx, "up")}>
          <ArrowUpward />
        </IconButton>
      </Tooltip>
      <Tooltip key="remove" title="delete component">
        <IconButton className="action" size="small" onClick={() => props.dataRaw.removeComponent(props.containerIdx, props.componentIdx)}>
          <Close />
        </IconButton>
      </Tooltip>
    </HeaderWrapper>
  );
}