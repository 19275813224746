import React from 'react';
import { Button, IconButton, TextField, Theme, Tooltip, Typography } from '@mui/material';
import { PageComponentProps } from './types';
import { FormGrid } from '../../../common/Forms';
import { Add, DeleteOutlined } from '@mui/icons-material';
import styled from '@emotion/styled';
import { Select } from '../../../common/Select';
import { MediaItemPicker } from '../../media';


interface Sponsor {
  title: string;
  url: string;
  logo_url: string | null | undefined;
}

interface SponsorCategory {
  title: string;
  logo_max_width?: string;
  logo_max_height?: string;
  layout?: "strip" | "column";
  sponsors: Sponsor[];
}

const useEditSponsors = ({ component, data }: PageComponentProps) => {
  const sponsors = (component.properties?.sponsors || []) as SponsorCategory[];
  const updateProperties = data.updateComponentProperties;

  const addCategory = () => {
    updateProperties({ sponsors: [ ...sponsors, { title: "New", sponsors: [] }]})
  }

  const updateCategory = (idx: number, changes: Partial<SponsorCategory>) => {
    updateProperties({ sponsors: sponsors.map((category, categoryIdx) => categoryIdx === idx
      ? { ...category, ...changes }
      : category) });
  }

  const removeCategory = (idx: number) => {
    updateProperties({ sponsors: sponsors.filter((category, categoryIdx) => categoryIdx !== idx) });
  }

  const addSponsor = (catIdx: number) => {
    updateProperties({ sponsors: sponsors.map((category, categoryIdx) => categoryIdx === catIdx
      ? { ...category, sponsors: [...category.sponsors, { title: "", url: "", logo_url: "" }] }
      : category) });

  }

  const updateSponsor = (catIdx: number, sponsorIdx: number, changes: Partial<Sponsor>) => {
    updateProperties({ sponsors: sponsors.map((category, categoryIdx) => categoryIdx === catIdx
      ? { ...category, sponsors: category.sponsors.map((sponsor, sIdx) => sIdx === sponsorIdx
        ? { ...sponsor, ...changes }
        : sponsor) }
      : category) });
  }

  const removeSponsor = (catIdx: number, sponsorIdx: number) => {
    updateProperties({ sponsors: sponsors.map((category, categoryIdx) => categoryIdx === catIdx
      ? { ...category, sponsors: category.sponsors.filter((sponsor, sIdx) => sIdx !== sponsorIdx) }
      : category) });
  }

  return {
    sponsors,
    addCategory,
    updateCategory,
    removeCategory,
    addSponsor,
    updateSponsor,
    removeSponsor,
  }
}

const CategoryWrapper = styled.div`
  border-bottom: 1px solid ${props => (props.theme as Theme).palette.grey[200]};
  margin: 0.5rem 0;

  .category-actions {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
`;
const SponsorsWrapper = styled.div`
  & > .sponsor {
    margin-bottom: 1rem;

    & .idx-and-actions {
      display: flex;
      flex-flow: column;
    }

    & .delete-sponsor {
      transform: scale(0.75);
      margin-left: -10px;
    }

    & .logo {
      max-height: 100px;
      justify-self: center;
      grid-row: span 2;
    }
  }
`;

export const SponsorsComponent = (props: PageComponentProps) => {
  const sponsorsData = useEditSponsors(props);

  return (
    <>
      {sponsorsData.sponsors.map((category,categoryIdx) => (
        <CategoryWrapper key={categoryIdx}>
          <FormGrid columns="2fr 2fr 1fr 1fr max-content">
            <TextField
              label="Category title"
              placeholder="e.g. Diamond, Gold, Silver, etc"
              value={category.title || ""}
              onChange={e => sponsorsData.updateCategory(categoryIdx, { title: e.target.value })}
              />

            <Select
              label="Layout"
              values={[["strip", "Strip"], ["column", "Column"]]}
              value={category.layout || "strip"}
              onChange={e => sponsorsData.updateCategory(categoryIdx, { layout: e.target.value as SponsorCategory["layout"] })}
              />

            <TextField
              label="Logo max width"
              value={category.logo_max_width || ""}
              onChange={e => sponsorsData.updateCategory(categoryIdx, { logo_max_width: e.target.value })}
              />
            <TextField
              label="Logo max height"
              value={category.logo_max_height || ""}
              onChange={e => sponsorsData.updateCategory(categoryIdx, { logo_max_height: e.target.value })}
              />

            <div key="actions">
              <Tooltip title="Delete category">
                <IconButton size="small" onClick={() => sponsorsData.removeCategory(categoryIdx)}>
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </div>
          </FormGrid>

          <SponsorsWrapper>
            <Typography variant="h6">Sponsors</Typography>
            {category.sponsors.map((sponsor, sponsorIdx) => (
              <FormGrid key={sponsorIdx} className="sponsor" columns="max-content 1fr 1fr 1fr">
                <div key="idx-and-actions" className="idx-and-actions">
                  <Typography>{sponsorIdx+1}.</Typography>
                  <Tooltip title="Delete sponsor" className="delete-sponsor">
                    <IconButton size="small" onClick={() => sponsorsData.removeSponsor(categoryIdx, sponsorIdx)}>
                      <DeleteOutlined />
                    </IconButton>
                  </Tooltip>
                </div>
                <TextField
                  key="title"
                  label="Title"
                  value={sponsor.title || ""}
                  onChange={e => sponsorsData.updateSponsor(categoryIdx, sponsorIdx, { title: e.target.value })}
                  />
                
                <TextField
                  key="url"
                  label="Website"
                  value={sponsor.url || ""}
                  onChange={e => sponsorsData.updateSponsor(categoryIdx, sponsorIdx, { url: e.target.value })}
                  />

                <MediaItemPicker
                  key="logo_url"
                  label="Logo"
                  selectedUrl={sponsor.logo_url}
                  setSelectedUrl={v => sponsorsData.updateSponsor(categoryIdx, sponsorIdx, { logo_url: v })}
                  />                
              </FormGrid>
            ))}
          </SponsorsWrapper>

          <div className="category-actions">
            <Tooltip title="add sponsor">
              <IconButton onClick={() => sponsorsData.addSponsor(categoryIdx)}>
                <Add />
              </IconButton>
            </Tooltip>
          </div>
        </CategoryWrapper>
      ))}

      <Button onClick={sponsorsData.addCategory}>add category</Button>
    </>
  );
}
