import { ArrowDownward, ArrowUpward, Visibility, VisibilityOffOutlined } from '@mui/icons-material';
import { IconButton, TableBody, TableCell, TableContainer, Table, TableHead, TableRow, Tooltip, TextField } from '@mui/material';
import React from 'react';
import { MenuType } from '../api/types';
import { EditMenu } from './useEditEvent';

interface Props {
  data: EditMenu;
  menuType: MenuType;
}

export const MenuEditor = ({ data, menuType }: Props) => {
  const menu = data.menu[menuType];

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableCell key="title">Page title</TableCell>
          <TableCell key="menu_title">Menu title</TableCell>
          <TableCell key="actions"></TableCell>
        </TableHead>
        <TableBody>
          {menu.map(item => (
            <TableRow key={item.page_id}>
              <TableCell key="title">{item.title}</TableCell>
              <TableCell key="menu_title">
                <TextField
                  value={item.menu_title || ""}
                  placeholder={item.title}
                  onChange={e => data.updatePage(menuType, item.page_id, { menu_title: e.target.value })}
                  />
              </TableCell>
              <TableCell key="show">
                <Tooltip title={item.show ? "hide (currently showing)" : "show (currently hidden)"}>
                  <IconButton
                    size="small"
                    color={item.show ? "primary" : "inherit"}
                    onClick={() => data.updatePage(menuType, item.page_id, { show: !item.show })}
                    >
                      {item.show ? <Visibility /> : <VisibilityOffOutlined />}
                  </IconButton>
                </Tooltip>
                
                <IconButton
                  key="move-up"
                  size="small"
                  onClick={() => data.movePage(menuType, item.page_id, "up")}
                  >
                    <ArrowUpward />
                </IconButton>
                <IconButton
                  key="move-down"
                  size="small"
                  onClick={() => data.movePage(menuType, item.page_id, "down")}
                  >
                    <ArrowDownward />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
